
import { io } from "socket.io-client";
class SocketService {
    static Instance;
    socket;
    userId

    constructor() {
        this.InitializeConnection();
        // this.userId = id
    }

    InitializeConnection() {

        // console.log(process.env.REACT_APP_SOCKET_URL);
        // this.Connect()
    }

    Connect(userId) {

        this.userId = userId
        this.socket = io(process.env.REACT_APP_SOCKET_URL);
        this.socket.on("connect", () => {
            console.log("connected");

            this.SendEmit("add-user", this.userId);
        })
        this.socket.on("disconnected", () => {
            console.log("disconnected");
        })
    }


    static GetInstance() {
        if (!SocketService.Instance) {
            SocketService.Instance = new SocketService();
            return SocketService.Instance;
        } else {
            return SocketService.Instance;
        }
    }

    SendEmit(eventName, data) {
        if (this.socket?.connected) this.socket.emit(eventName, data)
    }

}

export default SocketService.GetInstance()
