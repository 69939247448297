import { email, password, textField, phone, country, bool, tradeInvite } from "./FieldsValidation";
import * as Yup from "yup";

// Login form Validation
const loginFormValidate = Yup.object({
  email,
  password: password({ reqMesg: "Password is Required" }),
});

// Trade Invite form Validation
const inviteFormValidate = Yup.object({
  trade: tradeInvite,
  selfDescription: textField({
    min: 10,
    minMesg: "Description should be 10 or more characters",
    reqMesg: "Description is required",
  }),
});
// Sign Up form Validation
const signupFormValidate = Yup.object({
  firstName: textField({
    min: 3,
    minMesg: "First Name should be 3 or more characters",
    reqMesg: "First Name is required",
  }),
  lastName: textField({
    min: 3,
    minMesg: "Last Name should be 3 or more characters",
    reqMesg: "Last Name is required",
  }),
  userName: textField({
    min: 6,
    minMesg: "User Name should be 6 or more characters",
    reqMesg: "User Name is required",
  }),
  email: email,
  password: password({
    min: 8,
    minMesg: "Password should be 8 or more characters",
    reqMesg: "Password is required",
  }),
  phoneNo: phone,
  country: country,
  state: textField({
    min: 2,
    minMesg: "Enter Valid State",
    reqMesg: "Enter your State ",
  }),
});

// Payment form Validation
const paymentFormValidate = Yup.object({
  // fullName: textField({
  //   min: 3,
  //   minMesg: "Full Name should be 3 or more characters",
  //   reqMesg: "Full Name is required",
  // }),
  // address: textField({
  //   min: 5,
  //   minMesg: "Adress should be 5 or more characters",
  //   reqMesg: "Address is required",
  // }),
  // city: textField({
  //   min: 2,
  //   minMesg: "City should be 2 or more characters",
  //   reqMesg: "City is required",
  // }),
  // country: country,
  zipCode: textField({
    min: 3,
    minMesg: "Zip Code should be 3 or more numbers",
    reqMesg: "Zip Code is required",
  }),
  cardNumber: textField({
    min: 1,
    reqMesg: "Card Number is required",
  }),
  cardHolderName: textField({
    min: 2,
    minMesg: "Card Holder Name should be 2 or more characters",
    reqMesg: "Card Holder Name is required",
  }),
  expMonth: textField({
    min: 1,
    reqMesg: "Expire Month is required",
  }),
  expYear: textField({
    min: 1,
    reqMesg: "Expire Year is required",
  }),
  cv: textField({
    min: 1,
    reqMesg: "CVV is required",
  }),
});

// Profile Setting Accounts form Validation
const AccountFormValidate = Yup.object({
  userName: textField({
    min: 6,
    minMesg: "User Name should be 6 or more characters",
    reqMesg: "User Name is required",
  }),
  firstName: textField({
    min: 3,
    minMesg: "First Name should be 3 or more characters",
    reqMesg: "First Name is required",
  }),
  lastName: textField({
    min: 3,
    minMesg: "Last Name should be 3 or more characters",
    reqMesg: "Last Name is required",
  }),
});

// Change Password form Validation
const ChangePasswordFormValidate = Yup.object({
  oldPassword: password({
    reqMesg: "Current Password is required",
  }),
  newPassword: password({
    min: 8,
    minMesg: "Password should be 8 or more characters",
    reqMesg: "Password is required",
  }),
});

// Profile Setting Details form Validation
const DetailsFormValidate = Yup.object({
  phoneNo: phone,
  state: textField({
    min: 2,
    minMesg: "Enter Valid State",
    reqMesg: "Enter your State ",
  }),
  country: country,
});

// User Profile Bio Detail form Validation
const BioDetailFormValidate = Yup.object({
  profileTitle: textField({
    min: 3,
    minMesg: "Title should be 3 or more characters",
    reqMesg: "Title is required",
  }),
  selfDescription: textField({
    min: 10,
    minMesg: "Description should be 10 or more characters",
    reqMesg: "Description is required",
  }),
});

// Create Trade Form Validation
const createTradeFormValidate = Yup.object({
  tradeName: textField({
    min: 3,
    minMesg: "Trade Name should be 3 or more characters",
    reqMesg: "Trade Name is required",
  }),
  description: textField({
    min: 50,
    minMesg: "Description too short atleast 50 or more characters",
    reqMesg: "Description is required",
  }),
  localOnly: bool,
  // lookingFor: array,
  // exchangeFor: array,
});

// Create Offer Form Validation
const createOfferFormValidate = Yup.object({
  offerTitle: textField({
    min: 3,
    minMesg: "Offer Name should be 3 or more characters",
    reqMesg: "Offer Name is required",
  }),
  description: textField({
    min: 50,
    minMesg: "Description too short atleast 50 or more characters",
    reqMesg: "Description is required",
  }),
  // offerTags: Array({
  //   min: 1,
  //   minMesg: 'Atleast one service is required'
  // })

});

export {
  loginFormValidate,
  signupFormValidate,
  paymentFormValidate,
  AccountFormValidate,
  ChangePasswordFormValidate,
  DetailsFormValidate,
  BioDetailFormValidate,
  createTradeFormValidate,
  createOfferFormValidate,
  inviteFormValidate
};
