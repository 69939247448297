import "../Trade.scss";
import { backArrow, backIcon, iconsSprite } from "../../../Assets/Images";
import React, { useEffect, useState } from "react";
import { DividerX, DividerY } from '../../../Component/Divider';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { OtherUserProfile } from "../../../actions/profile";
import styled from "styled-components";
import { GridContainer, RoundBox, Section } from "../../../Component/Containers";
import { size } from "../../../Helpers/breakpoint";
import { EmptyMyTradeList, GetMyAllTrades } from "../../../actions/trade";
import { TradeUserInfoHeader } from "../../../Component/UserInfoHeader";
import { Button } from "../../../Component/Button";
import TradeInfo from "../MyTradeInfo/TradeInfo";
import { P1 } from "../../../Component/Para";
import Loader from "../../../Component/Loader";
import routesName from "../../../Helpers/routesName";
import { GoBack } from "../../../Component/SquarePictureAnim";


// Custom Round Box
const CustomRoundBox = styled(RoundBox)``;

const MyTrades = () => {

  const dispatch = useDispatch();
  let user = useSelector((state) => state.Auth.userDetails.userDetail);
  const myTrades = useSelector((state) => state.Trade.myTrades)
  const pagination = useSelector((state) => state.Trade.pagination);
  const fetching = useSelector((state) => state.Trade.fetching);

  const limit = 10

  let filterMap = {
    "all": "",
    "active": "active",
    "pending": "pending",
    "completed": "completed",
  }

  let filterMapTitle = {
    "all": "All Trades",
    "active": "Active Trades",
    "pending": "Pending Trades",
    "completed": "Completed Trades",
  }

  const [activeFilter, setActiveFilter] = useState("all")
  const goto = useNavigate();

  useEffect(() => {
    if (user?.id) LoadMyTrades(activeFilter)
  }, [activeFilter, user])

  function LoadMyTrades() {
    dispatch(EmptyMyTradeList())
    dispatch(GetMyAllTrades({ status: filterMap[activeFilter], limit: limit, page: 1 }))
  }

  function LoadMore() {
    dispatch(GetMyAllTrades({ status: filterMap[activeFilter], limit: limit, page: pagination.currentPage + 1, docsCount: pagination.total }));

  }

  return (
    <section className="mytrade-section">
      <div className="container grid-container">
        <div>
          <div className="header">
            <h2 lh='auto' className='currentSelection'>&nbsp;</h2>
          </div>
          <div className="sidebar without-skill">
            <ul>
              {
                Object.keys(filterMap).map((val, ind) => {
                  return (
                    <li className={val === activeFilter ? 'active' : ''} id={val} onClick={(e) => { setActiveFilter(val) }}>
                      {filterMapTitle[val]}
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div>
          <div className="header">
            <h2 lh='auto' className='currentSelection'>My Trades</h2>
            <button className='go-back-btn' title="Back to feed" onClick={() => goto(routesName.dashboard)}>
              <img src={backArrow} className="backArrow" alt="Back" width="" height="" />
              <img src={backIcon} className="back" alt="Back" width="" height="" />
            </button>
          </div>
          <div>
            {(myTrades.length) ? myTrades.map((val, ind) => {
              return (
                <CustomRoundBox className="custom_round_box mb-3">
                  <div key={ind} className="each-trade">
                    <TradeInfo key={'tradeList_' + ind} data={val} showDelete={false} allowNavigate={true} showExchageOffer={true} showLookingOffer={false} />
                  </div>
                </CustomRoundBox>
              )
            })
              : fetching ? <Loader width="80px" height="80px" /> : <CustomRoundBox className="custom_round_box"> <P1 className="noData center" color="blue" fontSize="1.5em" lh="auto"> No Trades found! </P1></CustomRoundBox>


            }
            {(pagination.currentPage < pagination.lastPage) ? <div className="text-center mtb-3">
              <button className="btn btn-capsule bg-blue" onClick={(e) => LoadMore()}>Load More Trades</button>
            </div> : ""}
          </div>
        </div>
        <div>
          <h2 lh='auto'>&nbsp;</h2>
        </div>
      </div>
    </section>
  )
}

export default MyTrades

