import Logo from './Logo';
import { Button } from './Button';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Container, Content, Section } from './Containers';
import { OnlyButton } from '../Component/Button';
import { logo } from '../Assets/Images';
import { size } from '../Helpers/breakpoint';
import { Link } from 'react-scroll';
import routesName from '../Helpers/routesName';

const CustomSection = styled(Section)``;

const CustomContainer = styled(Container)``;

const CustomContent = styled(Content)``;

const SignUpButton = styled(Button)``;

const Hamburger = styled.div``;

const SideMenu = styled.div``;

const Header = () => {

  const [toggle, setToggle] = useState(false);
  const { pathname } = useLocation() //Get pathname
  const goto = useNavigate();
  const { signUp, login } = routesName;

  const [isLinksVisible, setIsLinksVisible] = useState(true)

  const toggleHamburger = () => {
    var hamburger = document.querySelector("#hamburger");
    var sidebar = document.querySelector("#mySidenav")
    hamburger.classList.toggle('close');
    sidebar.classList.toggle('active');
  }

  const executeScroll = (id) => {

    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    (pathname === "/login" || pathname === "/create-account") ? setIsLinksVisible(false) : setIsLinksVisible(true)
  }, [pathname])

  return (
    <section id='Header' className='frontend-header'>
      <div className="container">
        <div className='logo'>
          <Logo w='100%' h='auto' logoSrc={logo} />
        </div>
        {isLinksVisible && (
          <>
            <ul className='links'>

              <li className='menu-item'>
                <a className='link' to="HowThisItWork" onClick={(e) => { executeScroll("HowThisItWork") }}>
                  <span>How it Works</span>
                </a>
              </li>
              <li className='menu-item' style={{ marginRight: 'auto' }}>
                <a className='link' to="PricingPlans" onClick={(e) => { executeScroll("PricingPlansHome") }} >
                  <span>Pricing Plans</span>
                </a>
              </li>
              <li className='menu-item login-item'>
                {/* <Link className='link' to="login" spy={true} smooth={true}>
                  <span>Log In</span>
                </Link> */}
                <OnlyButton className='login-btn' onClick={() => goto(login)}>Log In</OnlyButton>
              </li>
              <li className='signup-item'>
                <button className='btn btn-capsule bg-blue signup-btn' onClick={() => goto(signUp)}>Sign Up</button>
              </li>
            </ul>
            <Hamburger id="hamburger" className={toggle ? 'close-btn' : ''} onClick={() => toggleHamburger()}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </Hamburger>
            <SideMenu id="mySidenav">
              <a onClick={() => { executeScroll("HowThisItWork"); toggleHamburger(); }} >How it Works</a>
              <a onClick={() => { executeScroll("PricingPlansHome"); toggleHamburger(); }} >Pricing Plans</a>
              <a onClick={() => { executeScroll("Testimonials"); toggleHamburger(); }} >Testimonials</a>
              <a href={login}>Log In</a>
              <a href={signUp}>Sign Up</a>
            </SideMenu>
          </>
        )
        }
      </div>
    </section >
  )
}

export default Header
