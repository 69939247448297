import device from '../Helpers/breakpoint'
import styled from 'styled-components';

// Section
const Section = styled.section`
    width:100%;
    overflow:hidden;
`

// Container 
const Container = styled.div`
    width:1380px;
    margin-inline:auto;
    padding-block:0rem;

    @media only screen and ${device.mobileS}{
        width: 95%;
        font-size:9px;
    }
    @media only screen and ${device.mobileM}{
        width: 100%;
        font-size:9px;
    }
    @media only screen and ${device.mobileL}{
        width: 90%;
        font-size:11px;
    }
    @media only screen and ${device.tablet}{
        width: 755px;
        font-size:11px;
    }
    @media only screen and ${device.laptop}{
        width: 980px;
        font-size:14px;
    }
    @media only screen and ${device.laptopL}{
        width: 1200px;
        font-size:18px;
    }
    @media only screen and ${device.desktop}{
        width: 1380px;
        font-size:20px;
    }
    
`

// Content
const Content = styled.div`
    padding-inline:1.5em;
`

const GridContainer = styled.div`
    width:1380px;
    margin-inline:auto;
    display:grid;
    grid-template-columns:auto auto auto;

    @media only screen and ${device.mobileS}{
        width: 100%;
        font-size:9px;
    }
    @media only screen and ${device.mobileL}{
        width: 100%;
        font-size:9px;
    } 
    @media only screen and ${device.mobileL}{
        width: 90%;
        font-size:11px;
    }
    @media only screen and ${device.tablet}{
        width: 755px;
        font-size:11px;
    }
    @media only screen and ${device.laptop}{
        width: 980px;
        font-size:14px;
    }
    @media only screen and ${device.laptopL}{
        width: 1200px;
        font-size:18px;
    }
    @media only screen and ${device.desktop}{
        width: 1380px;
        font-size:20px;
    }
`

const RoundBox = styled.div`
    width:98%;
    min-height: 100px;
    border-radius:15px;
    background:var(--white);
    margin-inline:auto;
`
// margin-bottom:2em;

export {
    Container,
    Section,
    Content,
    GridContainer,
    RoundBox
}