import styled from 'styled-components'
import { size } from '../Helpers/breakpoint';

const AnimationBlock = styled.div`
    display: grid;
    flex: 1;
    height: 24em;
    padding-inline:1em;
    place-items: center;
    position: relative;

    .picture {
        width: auto;
        height: 24em;
        z-index: 1;
        transition: 0.5s all ease;
    }

    .rectangle {
        width: 23em;
        height: 23em;
        background-color: var(--blue);
        border-radius: 15px 100px;
        transform: rotate(-7deg);
        position: absolute;
        transition: 0.5s all ease;
        /* right: 0; */
        z-index: 0;
    }

    &:hover .rectangle {
        transition: 0.5s all ease;
        transform: rotate(-12deg);
    }

    &:hover .picture {
        transition: 0.5s all ease;
        transform: rotate(7deg);
    }

    @media only screen and (max-width: ${size.tablet}){
        display: none;
    }

`

export const GoBack = styled.div`
`


const SquarePictureAnim = ({ picSrc, picName }) => {
	return (
		<AnimationBlock>
			<div className="rectangle"></div>
			<img src={picSrc} className="picture animated" alt={picName} width="" height="" />
		</AnimationBlock>
	)
}

export default SquarePictureAnim