import styled from 'styled-components';
import { RoundBox } from '../../../Component/Containers';
import { H1 } from '../../../Component/Heading';
import { DividerX } from '../../../Component/Divider';
import React, { useState, useEffect } from 'react';
import { Button, ButtonOutline } from '../../../Component/Button';
import { useDispatch, useSelector } from 'react-redux';
import { size } from "../../../Helpers/breakpoint";
import Loader from '../../../Component/Loader';
import { Input, Error, InputTextArea, FieldCheckBox } from '../../../Component/Inputs';
import socketService from '../../../services/socketService';
import { RateATrade } from '../../../actions/profile';
import { CustomRating } from '../../../Component/CustomRating';
import DialogComp from '../../../Dialog/DialogComp';


const CustomDiv = styled.div`
width:100%;
height:100vh;
display:grid;
place-items:center;
background:var(--transparent);
position:fixed;
top:0px;
left:0px;
    & .blur{
        position:absolute;
        width:100%;
        height:100%;
        background:var(--blackOp05);
        backdrop-filter: blur(3px);
        z-index:0;
    }
    `
const CustomRoundBox = styled(RoundBox)`
    width:43.1em;
    z-index:1;
	@media only screen and (max-width: ${size.btwMLT}) {
		width:95%;
	}
    & .heading{
        margin:1.6em 2em;
    }
    & > .cont{
        margin:2em 2.5em;
		& .spacing{
			margin-block:1em;
		}
    }

	& .formButton{
		display:flex;
		justify-content:flex-end;
		align-items:center;
		gap:1em;
		padding:1em 2.5em;
		& button{
			height:2.2em;
			width:6.5em;
		}
	}
`

const CustomOutlineButton = styled(ButtonOutline)`

	color: var(--grey);
	border: 1px solid var(--grey);
	&:hover{
		color: var(--white);
		background-color: var(--grey);
	}
`
const Div = styled.div`
        width:100%;
    `

const RateTradeModal = ({ title, loader, setLoader, setView, ratedFor, ratedBy, tradeId, userCreated }) => {
	const [ratingObj, setRatingObj] = useState({ rating: 0, review: "" })
	const dispatch = useDispatch();

	const onSubmitHandler = (e) => {

		let ratings = {
			ratedBy: ratedBy,
			ratedFor: ratedFor,
			tradeId: tradeId,
			createdDate: new Date(),
			description: ratingObj.review,
			ratingStar: parseInt(ratingObj.rating),
			userCreated: userCreated,
		}
		setLoader(true);
		dispatch(RateATrade(ratings));
		setTimeout(() => {
			setLoader(false);
			setView(false)
		}, 1000);
	}

	useEffect(() => {
	}, [ratingObj])

	function CreateInvite(data) {
		socketService.SendEmit("send-invite", data)

	}

	const setUserReview = (e) => {

	}
	return (
		// <CustomDiv>

		// 	<CustomRoundBox className="custom_round_box">
		// 		<H1 className='heading' lh='auto' fontSize='1.25em'>{title}</H1>
		// 		<DividerX />

		// 		<div className="cont">
		// 			<CustomRating readOnly={false} setValue={setRating} />
		// 			<div className="spacing">
		// 				<InputTextArea label="description" onChange={(e) => { setDescription(e.target.value) }} name="description" placeholder="Description" style={{ fontSize: '1em' }} />
		// 			</div>
		// 		</div>
		// 		<DividerX />
		// 		<div className="formButton">
		// 			<CustomOutlineButton onClick={() => setView(false)}>Cancel</CustomOutlineButton>
		// 			<Button onClick={(e) => onSubmitHandler(e)} disabled={loader}>{loader ? <Loader margin="auto" /> : "Save"}</Button>
		// 		</div>
		// 	</CustomRoundBox>
		// 	<div className="blur"></div>
		// </CustomDiv>

		<DialogComp
			userReview={ratingObj}
			readOnly={false}
			setUserReview={setRatingObj}
			open={true}
			review={true}
			onClose={(e) => console.log()}
			text={"Trade Successful"}
			onSubmitHandler={onSubmitHandler}
			loader={loader}
		/>
	)
}

export default RateTradeModal;
