import React, { useEffect } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ChangeAuthState, TokenVerification } from '../actions/auth';
import routesName from './routesName';
import styled from 'styled-components';
import { Container } from '../Component/Containers';
import Loader from '../Component/Loader';

const CustomContainer = styled(Container)`
    display: grid;
    place-items: center;
    height:90vh;
`

const PublicRoute = (props) => {
  const { path, element } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth.auth);
  const { dashboard } = routesName;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(ChangeAuthState(false));
    } else {
      dispatch(TokenVerification());
    }
  }, [auth, dispatch]);

  return (
    <div>
      {
        auth !== "false" ?
          auth ? <Navigate to={{ pathname: dashboard }} /> : <Outlet />
          : <CustomContainer>
            <Loader width="100px" height="100px" />
          </CustomContainer>
      }
    </div>
  );
};

export default PublicRoute;