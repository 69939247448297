import "../Offers.scss";
import { iconsSprite } from "../../../Assets/Images";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { AcceptOffer } from "../../../actions/offers"
import { Button } from "../../../Component/Button"
import Loader from "../../../Component/Loader"
import routesName from "../../../Helpers/routesName"


const AcceptOfferComp = ({ _id, setOpen, setText }) => {
  const [loader, setLoader] = useState(false)
  const authState = useSelector((state) => state.Auth)
  const { myOffers } = routesName
  const goto = useNavigate();
  const dispatch = useDispatch();

  const AcceptOffered = () => {
    setLoader(true)
    dispatch(AcceptOffer({ "offerId": _id }))
  }

  useEffect(() => {

    if (loader) {
      if (authState.errorCall == 'acceptOffer' && authState.errorRespMsg) {
        setLoader(false);
      }
      else {
        setText("You've accepted an Offer")
        setOpen(true)
        setLoader(false);
        setTimeout(() => {
          setOpen(false)
          setText("")
          // goto(myOffers)
        }, 2000);
      }
    }


  }, [authState])

  return (
    <div>
      <button  style={{"float" : "right"}} className="btn btn-capsule bg-blue" disabled={loader} onClick={(e) => AcceptOffered()} > {loader ? <Loader /> : "Accept Offer"}</button>
    </div>
  )


}
export default AcceptOfferComp