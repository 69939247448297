import styled from "styled-components"

const DividerX = styled.div`
	width:100%;
	height:${({height}) => height ? height : '1px'};
	background:${({color}) => color ? `var(--${color})` : 'var(--greyLight)'};
	`
	const DividerY = styled.div`
	width:${({width}) => width ? width : '1px'};
	height:100%;
	min-height:${({minHeight}) => minHeight ? minHeight : '100%'};
	background:${({color}) => color ? `var(--${color})` : 'var(--greyLight)'};
`

export {
    DividerX,
    DividerY
}