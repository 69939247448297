import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import styled from 'styled-components';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: 'var(--blue)',
    },
});

export const CustomRating = ({ rating, readOnly, setValue }) => {
  
    return (

        <StyledRating
            onChange={(e) => {if(!readOnly) setValue(e.target.value)}}
            defaultValue={rating}
            size="small"
            name="rating"
            readOnly={readOnly}
            icon={<FavoriteIcon style={{ fontSize: '12px' }} />}
            emptyIcon={<FavoriteBorderIcon style={{ color: 'var(--transparent)', fontSize: '12px' }} />}
        />
    )
}