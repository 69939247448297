import styled from "styled-components";

const ParaLarge = styled.p`
    font-family: PoppinsRegular;
    font-size: 3.5em;
    line-height: 1em;
    color: var(--greyashgreen);
`;
    
const ParaMedium = styled(ParaLarge)`
    font-size: 2.5em;
    
`;
    
const ParaSmall = styled(ParaLarge)`
    font-size: 1.5em;
`;
const P1 = styled.p`
    font-family: PoppinsRegular;
    font-size: ${({fontSize}) => fontSize ? fontSize : '1em' };
    line-height: ${({lh}) => lh ? lh : '1em' };
    color:${({color}) => color ? `var(--${color})` : 'var(--blackashgreen)'}
    
`
const P2 = styled.p`
    font-family: PoppinsRegular;
    font-size: ${({fontSize}) => fontSize ? fontSize : '.9em' };
    line-height: ${({lineHeight}) => lineHeight ? lineHeight : '1em' };
    color:${({color}) => color ? `var(--${color})` : 'var(--blackashgreen)'};
    margin:${({margin}) => margin ? margin : '0em'};
    
    
`
const P4 = styled.p`
    font-family: PoppinsRegular;
    font-size: ${({fontSize}) => fontSize ? fontSize : '.7em' };
    line-height:${({lineHeight}) => lineHeight ? lineHeight : '1em' };
    color:${({color}) => color ? `var(--${color})` : 'var(--blackashgreen)'}
`

export {
    ParaLarge,
    ParaMedium,
    ParaSmall,
    P1,
    P2,
    P4
}