import styled from 'styled-components'
import { TradeWith } from '../../../Assets/Images'
import { Container, Content, Section } from '../../../Component/Containers'
import { ParaSmall } from '../../../Component/Para'
import { HeadingLarge } from '../../../Component/Heading'
import { size } from '../../../Helpers/breakpoint'
import { categories } from './content'

// Custom Section
const CustomSection = styled(Section)`
    padding-block:1em;
`

// Categories Div
const CustomDiv = styled.div`
    background:var(--blue);
    margin-top:3em;
    margin-bottom:0px;
    border-radius: 10px 10px 10px 55px;
    padding-inline:5%;
    padding-block:1em 2em;
    cursor:default;
    transition:var(--trans_2s);
    ul{
        list-style-type:none;
        display:flex;
        align-content:flex-start;
        justify-content:space-between;
        flex-wrap: wrap;
    }
    ul li{
        font-size:1.5em;
        font-family:PoppinsMedium;
        padding-inline:.2em;
        flex: 1 1 30%;
        margin-block:.2em;
        align-self:flex-start;
    }
    &:hover{
        transition:var(--trans_2s);
        background:var(--white);
        color:var(--blue)

    }
    @media only screen and (max-width: ${size.tablet}){
        ul li{
            flex: 1 1 45%; // grow | shrink | basis
            font-size:1em;
        }
    }
    `
// Heading Large
const Title = styled(HeadingLarge)`
    margin-top: 1em;
    line-height:1.2em;
    color:var(--white);
    `

// Para
const CustomParaSmall = styled(ParaSmall)`
    color:var(--white);
    font-size:1.1em;
    line-height:1.35em;
    margin-top:20px ;
`

// Div
const CustomContent = styled(Content)`
    width: 100%;
    min-height: auto;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    background:url(${TradeWith});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px 55px;
    color:var(--white);
    padding:1.5em;
`
const TradeWithAPro = () => {
    return (
        <CustomSection>
            <Container>
                <CustomContent>
                    <Title>Trade with a Pro <br /> for Any Skill</Title>
                    <CustomParaSmall>Work with the largest network of <br /> independent professionals and get things <br /> done—from quick turnarounds to big transformations.</CustomParaSmall>
                    <CustomDiv>
                        <ul>
                            {categories && categories.map(({ catName}, ind) => {
                                return <li key={'_'+ind}>{catName}</li>
                            })}
                        </ul>
                    </CustomDiv>
                </CustomContent>
            </Container>
        </CustomSection>
    )
}

export default TradeWithAPro;