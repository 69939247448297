import React from "react";
import { Button } from "../../../Component/Button";
import { FieldInput } from "../../../Component/FieldInput";
import { P1 } from "../../../Component/Para";
import Loader from "../../../Component/Loader";
import { Formik, Form } from 'formik';
import { DetailsFormValidate } from "../../../Validations";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProfile } from "../../../actions/profile";
import styled from "styled-components";
import countries from "../../../Helpers/countries";
import SearchSelect from "../../../Component/SearchSelect";
import { FormButtonsDiv, Error } from "../../../Component/Inputs";

// Form Row
const FormRow = styled.div`
margin-block:15px;
`

// submit button
const SubmitButton = styled(Button)`
	height:2.2em;
	min-height:35px;
	// display: grid;
	// place-items: center;
`

// Field Label
const FieldLabel = styled(P1)`
	margin-bottom:10px;
`


const DetailForm = ({ profile, loader, setLoader }) => {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.Auth)

	return (
		<div>
			<Formik
				initialValues={{
					phoneNo: profile.phoneNo,
					address: profile.address,
					country: profile.country,
					state: profile.state,
				}}
				validationSchema={DetailsFormValidate}
				onSubmit={values => {
					setLoader(true);
					dispatch(UpdateProfile(values));
				}}
			>
				{formik => (
					<div>
						<Form autoComplete="off"> {/*Formik Form Import from Formik*/}
							<FormRow>
								<FieldLabel> Enter Phone Number </FieldLabel>
								<FieldInput label="phoneNo" name="phoneNo" placeholder="Phone Number" />
							</FormRow>
							<FormRow>
								<FieldLabel> Enter your Adress </FieldLabel>
								<FieldInput label="address" name="address" placeholder="Address" />
							</FormRow>
							<FormRow>
								<FieldLabel> Select Country </FieldLabel>
								<SearchSelect label="country" name="country" placeholder="Select a country" options={countries} />
							</FormRow>
							<FormRow>
								<FieldLabel> Enter State </FieldLabel>
								<FieldInput label="state" name="state" placeholder="State" />
							</FormRow>

							<FormButtonsDiv>
								{
									authState.errorCall === "updateProfile" && <Error> <p className="error">{authState.errorRespMsg}</p> </Error>
								}
								<button className="btn btn-capsule bg-blue" type="submit" disabled={loader}> {loader ? <Loader /> : "Save"} </button>
							</FormButtonsDiv>
						</Form>
					</div>
				)}
			</Formik>
		</div>
	)
}

export default DetailForm;
