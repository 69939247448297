import "./Messages.scss";
import { searchIconGrey, paperClipIcon, backIcon, docIcon, galleryIcon, ProfileImage, audioIcon } from "../../Assets/Images";
import { DividerX, DividerY } from "../../Component/Divider";
import { H1 } from "../../Component/Heading";
import { P1, P4 } from "../../Component/Para";
import { RoundImage } from "../../Component/Images";
import { useState } from "react";
import { useEffect, useRef } from "react";
import {
  CustomRoundBox,
  GridItem,
  SearchInput,
  ChatWith,
  CustomInput,
  CustomButton,
  Conversation,
  Sender,
  Reciever
} from "./StyledComponents";
import { AddMessage, GetConversations, GetMessages } from "../../actions/chat";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../Helpers/GetDimensions";
import socketService from "../../services/socketService";
import { useParams } from "react-router";
import { useSearchParams, useNavigate } from "react-router-dom";
import { UploadFile, LoadUploadedImage } from "../../services/utilService";
import AudioRecorder from "../../shared/AudioRecorder";
import FileUploader from "../../shared/fileUploader";
import { Error } from "../../Component/Inputs";
import AttachmentView from "./Attachments";
import useOutsideAlerter from "../../shared/BlurHandler";
import Loader from "../../Component/Loader";



const Messages = () => {

  const [message, setMessage] = useState('')
  const [viewChat, setViewChat] = useState(false);
  const [chatList, setChatList] = useState(true);
  const [name, setName] = useState('')
  const [chatId, setChatId] = useState(null)
  const [profileImage, setProfileImage] = useState(null)
  const [conversation, setConversation] = useState('')
  const dispatch = useDispatch();
  const convo = useSelector(state => state.Chat.conversations);
  const messages = useSelector(state => state.Chat.messages);
  const sending = useSelector((state) => state.Chat.sendingLoader);
  const userId = useSelector(state => state.Auth.userDetails.userDetail?.id);
  const { width } = useWindowDimensions()
  const [arrivalMessage, setArrivalMessage] = useState('');
  const [allMessages, setAllMessages] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAudio, setIsAudio] = useState(false)

  // Attachments
  const [menuState, setAttachmentMenuState] = useState(false)
  const [recordedAudio, setRecordedAudio] = useState({})
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachError, setAttachError] = useState("");
  const [attachmentType, setAttachType] = useState("");

  const attachMenuRef = useRef(null);
  useOutsideAlerter(attachMenuRef, setAttachmentMenuState, false);

  useEffect(() => {
    if (socketService.socket) {

      socketService.socket.on("get-message", (data) => {
        setArrivalMessage({
          sender: data.senderId,
          text: data.text,
          createdDate: new Date(data.createdDate).toISOString()
        })
      })
    }
  }, [socketService.socket]);
  useEffect(() => {
    if (convo.length) {
      let selected = id ? convo?.filter(x => x.members.filter(x => x._id === id).length)[0] : ""
      if (selected) {
        let friend = selected.members.filter(x => x._id === id)[0]
        selectChat(selected._id, friend.firstName + " " + friend.lastName, selected.image)
        navigate({ pathname: '/messages/' }, { replace: true })
      }
    }
  }, [convo]);

  useEffect(() => {
    arrivalMessage
      && conversation[0]?.members.includes(arrivalMessage.sender)
    setAllMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    dispatch(GetConversations());
  }, [dispatch])

  useEffect(() => {
    setAllMessages(messages);
  }, [messages])

  const sendMessage = async () => {
    try {
      console.log(sending)
      if (sending) return;
      let receiver = conversation[0].members?.find((v) => v._id !== userId);
      let messageDetail = {
        conversationId: conversation[0]._id,
        senderId: userId,
        recieverId: receiver._id,
        text: "",
        createdDate: new Date().toISOString()
      }

      if (selectedFile) {
        let file = await UploadFile(selectedFile)

        if (file?.attachment) {
          messageDetail.text = file.attachment
          messageDetail.attachment = {
            filename: selectedFile.name,
            type: attachmentType,
            size: selectedFile.size,
            contentType: file.mimetype
          }
        }

      }
      else messageDetail.text = message.trim()
      if (messageDetail.text) {
        socketService.SendEmit("send-message", messageDetail)
        setAllMessages([...allMessages, messageDetail])
        dispatch(AddMessage(messageDetail))

        RemoveAttachment()
        setMessage('')
      }
    } catch (err) {
      console.log(err)
      return;

    }
  }
  // For Chat Screen Size - - - - -
  useEffect(() => {
    if (width > 768) {
      chatId !== null ? setViewChat(true) : setViewChat(false)
      setChatList(true)
    }
    else {
      if (chatId !== null) {
        setViewChat(true)
        setChatList(false)
      } else {
        setViewChat(false)
        setChatList(true)
      }
    }
  }, [width])
  // - - - - - For Chat Screen Size
  const selectChat = (selectId, name, image) => {
    if (selectId) {
      let flag = chatId !== selectId
      if (flag === true) {
        RemoveAttachment()
        setAllMessages('')
        setConversation(convo.filter((v) => v._id === selectId))
        dispatch(GetMessages(selectId));
        setName(name);
        setProfileImage(image);
        setViewChat(true);
        setChatId(selectId);
        width <= 768 && setChatList(false)
      }
    }
  }

  // Get Chat Scroll:
  const getChatScroll = useRef()
  useEffect(() => {
    if (getChatScroll.current) {
      const scrollToBottom = () => {
        const scrollHeight = getChatScroll.current.scrollHeight;
        const height = getChatScroll.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        getChatScroll.current.scrollTop = maxScrollTop < height ? height : maxScrollTop;
      }
      scrollToBottom()
    }
  })

  const RemoveAudio = (e) => {

    setIsAudio(false)
    setRecordedAudio({})
  }

  const RemoveAttachment = (e) => {

    setSelectedFile(null);
    setAttachmentMenuState(false)
    setAttachError("")
  }

  const FileSelected = (file, type) => {
    setAttachError("")
    setAttachmentMenuState(false)
    setSelectedFile(file)
    setAttachType(type)
  }

  return (
    <section className="messages-section">
      <div className="container grid-container gc-center">
        <div></div>
        <div>
          <CustomRoundBox className="custom_round_box">
            {chatList && (
              <div className="chat-list-sidebar">
                <div className="search">
                  <input className='input-capsule' label="search" name="search" type="search" placeholder="Search" isIcon={searchIconGrey} />
                </div>
                <DividerX />
                <ChatWith>
                  <ul className="chat-list">
                    {
                      convo?.length > 0 && convo?.map((val) => {
                        // const { id, picture, name, chat } = val
                        const { members, _id, } = val
                        const [friendId] = members.filter((v) => v._id !== userId);
                        const fullName = friendId?.firstName + " " + friendId?.lastName;
                        return (
                          <li className={"chatItem " + ((_id == chatId) ? "active" : "")} >
                            <div className="chatContent" id={_id} onClick={(e) => { e.preventDefault(); selectChat(e.currentTarget.id, fullName, friendId?.image) }}>
                              <RoundImage src={LoadUploadedImage(friendId?.image)} onError={(e) => { e.target.src = LoadUploadedImage("") }} alt={friendId?.firstName} width="" height="" />
                              <div className="chatInfo"  >
                                <h4 lh="auto"
                                >{friendId?.firstName + " " + friendId?.lastName}</h4>
                                <P1 fontSize=".9em" lh="auto" color="grey">
                                  {val?.latestMessage}
                                </P1>
                              </div>
                            </div>
                            <DividerX />
                          </li>
                        )
                      })
                    }
                  </ul>
                </ChatWith>
              </div>
            )}
            {!viewChat && <GridItem className="blank"></GridItem>}
            {viewChat && (
              <div className="message-area">
                <div className="rowGrdid">
                  <div className="chatHeader">
                    {
                      name &&
                      <>
                        <div className="profileName">
                          <RoundImage src={LoadUploadedImage(profileImage)} onError={(e) => { e.target.src = LoadUploadedImage("") }} alt="name" width="" height="" />
                          <h3>{name}</h3>
                        </div>
                        {width <= 768 && <img src={backIcon} className="backArrow" alt="Back Icon" width="" height="" onClick={() => { setViewChat(false); setChatList(true); setChatId(null) }} />}
                      </>}
                  </div>
                  <DividerX />
                  <div className="chats" ref={getChatScroll} >
                    <Conversation>
                      {allMessages && allMessages?.map((val, ind) => {
                        // const { _id, message, type, time } = val
                        const { _id, senderId, text, createdDate, attachment } = val;
                        const time = "09.13.21"
                        return (
                          (senderId !== userId) ?
                            <Reciever key={_id} id={_id}>
                              {
                                attachment ?
                                  <AttachmentView file={attachment} url={text} /> :
                                  <P1 fontSize="0.7em" color="white" lineHeight="auto">{text}</P1>
                              }
                              <p className="small">{new Date(createdDate).toLocaleString()}</p>


                              {/* <P4 fontSiz=".8em" color="whiteF0" lineHeight="auto">{time}</P4> */}
                            </Reciever> :
                            <Sender key={_id} id={_id}>
                              {
                                attachment ?
                                  <AttachmentView file={attachment} url={text} /> :
                                  <P1 fontSize="0.7em" color="blackashgreen" lineHeight="auto">{text}</P1>
                              }
                              <p className="small">{new Date(createdDate).toLocaleString()}</p>
                            </Sender>
                        )
                      })}
                    </Conversation>
                  </div>
                  <DividerX />

                  {
                    name &&
                    <div className="chatFooter">
                      <div className="itemCenter" ref={attachMenuRef}>
                        <img src={paperClipIcon} alt="paper-clip" width='' height='' onClick={(e) => setAttachmentMenuState(!menuState)} />
                        {menuState ?
                          <ul className="attachment-options"  >
                            <li><FileUploader accept={"application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"}
                              onFileSelectSuccess={FileSelected} icon={docIcon} type={'doc'}
                              onFileSelectError={(e) => setAttachError(e)} />
                            </li>
                            <li><FileUploader accept={["audio/*"]} icon={audioIcon} type={'audio'}
                              onFileSelectSuccess={FileSelected}
                              onFileSelectError={(e) => setAttachError(e)} />
                            </li>
                            <li><FileUploader accept={["images/*"]} icon={galleryIcon} type={'gallery'}
                              onFileSelectSuccess={FileSelected}
                              onFileSelectError={(e) => setAttachError(e)} />
                            </li>
                          </ul> : ""}
                      </div>

                      <div className="itemCenter">
                        {selectedFile ?
                          <ul className="attachments">
                            <li>
                              <p tooltip={selectedFile.name}>{selectedFile.name}</p>
                            </li>
                            <button className="btn btn-round bg-blue cancel-btn" onClick={(e) => { RemoveAttachment(); }}>x</button>
                          </ul>
                          : ""}
                        <CustomInput value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={e => { if (e.key === 'Enter') sendMessage() }} />
                      </div>
                      <div className="itemCenter">
                        <button className="btn btn-capsule bg-blue" onClick={() => sendMessage()} disabled={!(selectedFile || message || !sending) || attachError}>{sending ? <Loader width="20px" height="20px" /> : 'Message'}</button>
                      </div>


                    </div>


                  }
                </div>
                {(attachError) && <Error> <p className="error">{attachError}</p> </Error>}
              </div>
            )}

          </CustomRoundBox>
        </div>
        <div></div>
      </div>
    </section>
  )
}

export default Messages