import styled from "styled-components";
import { P1 } from "../../Component/Para";
import AccountForm from "./forms/AccountForm";
import DetailForm from "./forms/DetailForm";
import PasswordForm from "./forms/PasswordForm";
import ProfilePictureForm from "./forms/ProfilePictureForm";

const CustomDiv = styled.div`
    margin-block:1em;
    padding-inline:1em;
`
const CustomP1 = styled(P1)`
    font-size: .8em;
    font-family: PoppinsLight;
    margin-block:.3em;
`

const FieldInfo = ({ profile, fieldName, fieldValue, edit, loader, setLoader }) => {
  return (
    <CustomDiv>
      {
        edit?.password ? <PasswordForm loader={loader} setLoader={setLoader} />
          : edit?.accounts ?
            <AccountForm profile={profile} loader={loader} setLoader={setLoader} />
            : edit?.details ?
              <DetailForm profile={profile} loader={loader} setLoader={setLoader} />
              : <> <P1>{fieldName}</P1>
                  <CustomP1>{fieldValue}</CustomP1></>
      }
    </CustomDiv>
  )
}

export default FieldInfo