import React, { useState } from "react";
import DialogComp from "./Dialog/DialogComp";

const TestComp = () => {
    const [open, setOpen] = useState(false);
    const [userReview, setUserReview] = useState({ rating: 2 });

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setOpen(false);
    }

    return (
        <div>
            <DialogComp
                open={open}
                onClose={() => setOpen(false)}
                review={true}
                setUserReview={setUserReview}
                userReview={userReview}
                onSubmitHandler={onSubmitHandler}
            />
            {/* <DialogComp
                open={open}
                onClose={() => setOpen(false)}
                sm={true}
                text="Your Trade has been Deleted."
            /> */}
            {/* <DialogComp
                open={open}
                onClose={() => setOpen(false)}
                text="You’ve Made an Offer!"
            />  */}
            <button onClick={() => setOpen(true)}>  Open Popup </button>
        </div>
    )
}

export default TestComp;