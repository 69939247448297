import { SubscriptionPlan, OneTimePlan, SubscriptionDetails } from "../actionTypes/ActionTypes";

const initailState = {
  paymentDetails: "",
  // subscriptionDetails: "",
  subscription:"",
};

const Payment = (state = initailState, action) => {
  switch (action.type) {
    case SubscriptionPlan:
      return { ...state, paymentDetails: action.payload };
    case OneTimePlan:
      return { ...state, paymentDetails: action.payload };
    case SubscriptionDetails:
      return { ...state, subscription: action.payload };
    default:
      return state;
  }
};

export default Payment;
