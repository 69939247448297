import { H1 } from "../../Component/Heading";
import { P1 } from "../../Component/Para";
import { RoundImage } from "../../Component/Images";
import { pinIcon, ProfileImage } from "../../Assets/Images";
import { LoadUploadedImage } from "../../services/utilService";


const UserWithInfo = ({ userDetail }) => {

  return (
    <div className='user-info'>
      <div className='proImage'>
        <RoundImage alt="" src={LoadUploadedImage(userDetail.image)} />
      </div>
      <div className="info">
        <H1 color="blue">{userDetail?.firstName} {userDetail?.lastName}</H1>
        {userDetail && (
          <span>
            <img src={pinIcon} alt="Pin" width="" height="" />
            <P1>{(userDetail.state ? userDetail.state : "") + (userDetail.country ? (", " + userDetail.country) : "")}</P1>
          </span>
        )}
      </div>
    </div>
  )
}

export default UserWithInfo