import styled from 'styled-components'
import "./what-they-are-saying.scss";
import { Container, Content, Section } from '../../../Component/Containers'
import { Heading6, HeadingLarge } from '../../../Component/Heading'
import { Arrow1Blue, JoanElliot, SamKubusho } from '../../../Assets/Images'
import { ParaLarge } from '../../../Component/Para'
import { size } from '../../../Helpers/breakpoint'

const CustomSection = styled(Section)``;

const CustomDiv = styled.div``;

// Image Div
let trans = '0.2s all ease-in-out'
const ImageDiv = styled.div`
    position:relative;
    .back{
        position:absolute;
        width:${props => props.backW};
        height:${props => props.backH};
        border-radius:${props => props.bRadius};
        z-index:-1;
        transition:${trans};
    }
    & img{
        transform-origin: ${props => props.imageOrigin};
        transition:var(--trans_2s);
    }
    &:hover img{
        transform:Rotate(${props => props.rotate}deg);
        transition:var(--trans_2s);
    }
    &:hover .back{
        background:var(--blue);
        transition:${trans};
    }
`

const Title = styled(HeadingLarge)``;

const CustomCards = styled.div``;

const CustomCard = styled.div``;

const CardTitle = styled(ParaLarge)``;

const Separator = styled.div``;

const CardName = styled(Heading6)`
    font-size:1em;
    color:var(--greyashgreen);
    margin-block:.5em 0em;

`

const CardSkill = styled(CardName)`
    color:var(--blue);
`


const WhatTheyAreSaying = () => {
  return (
    <section id="Testimonials">
      <div class='container'>
        <div class="title">
          <h2>
            What They're <br /> Saying
            <img src={Arrow1Blue} alt="Arrow" />
          </h2>
        </div>
        <Content>
          <div class="testimonial-list">
            <li>
              <ImageDiv rotate='-2' backW="100%" backH="99%" bRadius='.75em .75em .75em 5em' imageOrigin="right bottom">
                <div className="back"></div>
                <img src={SamKubusho} alt="" width="" height="" />
              </ImageDiv>
              <blockquote>
                <q>This platform has helped me grow my business over time while saving money.</q>
              </blockquote>
              <hr />
              <CardName>Joan Elliot</CardName>
              <CardSkill>Artist</CardSkill>
            </li>
            <li>
              <ImageDiv rotate='2' backW="100%" backH="99%" bRadius='.75em 5em .75em .75em' imageOrigin="left bottom">
                <div className="back"></div>
                <img src={JoanElliot} alt="" width="" height="" />
              </ImageDiv>
              <blockquote>
                <q>Barter-E has helped me keep my business afloat through the pandemic.</q>
              </blockquote>
              <hr />
              <CardName>Sam Kubusho</CardName>
              <CardSkill>Accountant</CardSkill>
            </li>
          </div>
        </Content>
      </div>
    </section>

  )
}

export default WhatTheyAreSaying;