import "../Trade.scss";
import { iconsSprite } from "../../../Assets/Images";
import { Heading5 } from "./../../../Component/Heading";
import { DividerX } from "./../../../Component/Divider";
import { StaticTags } from "./../../../Component/Tags";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { useState } from "react";
import { CheckBox } from "../../../Component/FieldInput";
import styled from "styled-components";

// FormRow
const FormRow = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
	max-width : 26.55em;
	& .description{
		margin-block:1.3em;
		& p{
			margin-block:.3em;
		}
	}
`;

const TradeInfo = ({ data, showTrades, allowNavigate, showExchageOffer, showLookingOffer }) => {


  let user = useSelector((state) => state.Auth.userDetails.userDetail);
  let isUsers = user && data && (data.userId._id == user.id)
  let statusTitleMap = {
    "user": {
      "active": "Offered",
      "pending": "Created",
      "completed": "Completed",
    },
    "other": {
      "active": "Posted",
      "pending": "Posted",
      "completed": "Completed",
    }
  }


  const { tradeName, tradeCreatedDate, tradeDescription, status, wantTradeTags, offerTradeTags, userId, localOnly } = data
  const [showOffer, setShowOffer] = useState(showTrades ? showTrades : false)
  const goto = useNavigate();
  const onTradeViewHandler = (e, trade) => {
    e.preventDefault();
    e.stopPropagation()
    goto(`/view-trade/${data?._id}`);
  }

  return (
    <div>
      <div className="trade-item" onClick={(e) => { if (allowNavigate) onTradeViewHandler(e, userId) }} onMouseEnter={(e) => { if (!showTrades) setShowOffer(true) }} onMouseLeave={(e) => { if (!showTrades) setShowOffer(false); }}>
        <div className="about-trade">
          <div>
            <h2>{tradeName}</h2>
          </div>
          <div className="trade-status">
            {isUsers ?
              <ul>
                <li className={"status-" + status}><p>{status}</p></li>
                <li><p>{statusTitleMap[isUsers ? 'user' : "other"][status]} Date:</p></li>
                <li><p>{new Date(tradeCreatedDate).toLocaleDateString()}</p></li>
              </ul>
              : <ul>
                {/* <li><p>Posted</p></li> */}
                <li><p>Posted {moment(tradeCreatedDate).fromNow()}</p></li>
              </ul>
            }

          </div>
          {!showTrades ? <div>
            <button className='btn btn-round svgOnHover hover-icon'>
              <svg className='svg icon-greyLight'>
                <use href={iconsSprite + '#hover-icon-down'} />
                <use href={iconsSprite + '#hover-icon-up'} />
              </svg>
            </button>
          </div> : ""}
        </div>
        <DividerX color="blackOp010" />
        <div className="trade-info">
          <p style={{ "white-space": "pre-wrap", "wordBreak": "break-word" }}>{tradeDescription}</p>
          {/* <FormRow width="auto"> */}
          {localOnly ? <label>
            <input onChange={((e) => { return false })} checked={localOnly} type="checkbox" style={{ fontSize: '1em', margin: '0px 5px 0 0' }} />
            Local only</label> : ""}
          {/* </FormRow> */}
          {/* {localOnly ? <p style={{ "white-space": "pre-wrap" }}>local only</p> : ""} */}
          {showLookingOffer ?
            <div className="lookingFor for" >
              <Heading5>Looking For</Heading5>
              <StaticTags list={wantTradeTags} />
            </div>
            : ""}
          {showExchageOffer && showOffer ?
            <div className="exchangeFor for">
              <Heading5>In Exchange For</Heading5>
              <StaticTags list={offerTradeTags} />
            </div> : ""
          }
        </div>
      </div>
    </div >
  )
}
export default TradeInfo