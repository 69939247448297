import styled from 'styled-components'
import "./PricingPlans.scss";
import { Container, Content, RoundBox, Section } from '../../../Component/Containers'
import { Heading3, HeadingLarge } from '../../../Component/Heading'
import { ParaLarge, ParaSmall } from '../../../Component/Para'
import { Button } from '../../../Component/Button'
import { pricingPlans } from './content'
import { useNavigate } from "react-router-dom";
import routesName from "../../../Helpers/routesName";
import { size } from '../../../Helpers/breakpoint';
import { GoBack } from '../../../Component/SquarePictureAnim';
import { backArrow, backIcon } from '../../../Assets/Images';

const CustomRoundBox = styled(RoundBox)``;

const H3 = styled(Heading3)`
        font-size: 1.75em;
        color: var(--blue);
        margin-top:41px;
        `
const PriceTag = styled(Heading3)`
        line-height: 0.8em;
        color: var(--blue);
        font-size: 7.9em;
        sup{
            font-size:0.45em;
        }
    `
const CustomPara = styled(ParaLarge)`
        font-size: 1em;
        line-height: 1.3em;
        color: var(--grey);
    `
const CustomParaSmall = styled(ParaSmall)`
        font-size: 1.25em;
        line-height: 1.3em;
        color: var(--blue);
        margin-bottom:1.1em;
    `

const SeparatorUp = styled.div`
    background:var(--grey);
    height:1px;
    width:80%;
    // margin-block:2px auto;
    `
const SeparatorDown = styled(SeparatorUp)`
    margin-block:auto 2px;
`

const CustomLi = styled.li`
    & div{
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding-block:.2em;
    }

    & div p{
        color:var(--grey);
        margin-right:.3em;
    }
`

const Image = styled.a`
	position: relative;
	cursor:default;
	display:flex;
	justify-content:center;
	align-items:center;
	& #Ellipse_51{
		fill:none;
		stroke:#33b0f2;
	}
	& #Path_368{
		fill:var(--blue);
	}
	&:hover{
		& #Ellipse_51{
			fill:var(--blue);
		}
		& #Path_368{
			fill:var(--white);
		}
	}
	&:hover::after{
		position:absolute;
		display: flex;
		justify-content: center;
		align-item:center;
		background: var(--blue);
		border-radius: 10px;
		color: #fff;
		content: attr(aria-label);
		font-size: .7em;
		padding: .75em;
		min-width: 10.7em;
		left: 36px;
	}
	&:hover::before{
		border: solid;
		border-color: transparent var(--blue);
		border-width: .5em .8em .5em 0em;
		content: "";
		left: 22px;
		position: absolute;
	}
`;



const PricingPlans = ({ page, trade }) => {

  const goto = useNavigate();
  const { login } = routesName;
  const CustomContent = styled(Content)`

		border: ${page && '1px solid var(--grey)'};
		border-radius: ${page && '15px'};
		padding:${page && '40px 10px'};

		& h1{
			text-align: ${page && 'center'};
		}
`
  return (
    <section id={page ? "PricingPlans" : "PricingPlansHome"}>
      <div className="container grid-container gc-center">
        <div><h2 lh='auto'>&nbsp;</h2></div>
        <div>
          {page ?
            <div className="header">
              <h2 lh='auto' className='currentSelection'>Pricing {'&'} Plans</h2>
              <button className='go-back-btn' title="Back to feed" onClick={() => goto(-1)}>
                <img src={backArrow} className="backArrow" alt="Back" width="" height="" />
                <img src={backIcon} className="back" alt="Back" width="" height="" />
              </button>
            </div> : ""}
          {!page &&
            <CustomRoundBox className="custom_round_box bg-transparent">
              <h2>Pricing {'&'} Plans</h2>
              <CustomPara>Select the plan that works best for you, <br /> upgrade at any time!</CustomPara>
            </CustomRoundBox>
          }
          <ul class="pricing-list">
            {pricingPlans && pricingPlans.map((val, i) => {
              const { title, price, perText, list, id } = val
              return <li key={'_' + i}>
                <div class="front">
                  <H3>{title}</H3>
                  <PriceTag><sup>$</sup>{price}</PriceTag>
                  <CustomParaSmall>{perText}</CustomParaSmall>
                  <SeparatorUp />
                  <ul class='feature-list'>
                    {list && list.map(({ info, infoText, text, src }, ind) => {
                      return <CustomLi key={'_' + ind} >
                        <div>
                          <p>{text}</p>
                          {info && <Image aria-label={infoText}>
                            {/* <img src={InfoIcon} className="icon" alt="" width="15" height="15" /> */}
                            <svg id="Component_224_3" data-name="Component 224 – 3" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Ellipse_51" data-name="Ellipse 51" stroke-width="1.5">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.25" fill="none" />
                              </g>
                              <path id="Path_368" data-name="Path 368" d="M35.382,8.831l-.138.565q-.622.246-.993.374a2.62,2.62,0,0,1-.861.129,1.719,1.719,0,0,1-1.172-.367A1.189,1.189,0,0,1,31.8,8.6a3.3,3.3,0,0,1,.031-.449q.032-.23.1-.519l.519-1.835q.069-.264.117-.5a2.172,2.172,0,0,0,.047-.43.665.665,0,0,0-.145-.491.82.82,0,0,0-.555-.139A1.453,1.453,0,0,0,31.5,4.3c-.141.042-.262.082-.363.119l.139-.566q.51-.207.975-.355a2.916,2.916,0,0,1,.882-.148,1.677,1.677,0,0,1,1.154.362,1.2,1.2,0,0,1,.405.94c0,.08-.009.22-.028.421a2.817,2.817,0,0,1-.1.554l-.516,1.828a4.856,4.856,0,0,0-.113.5,2.592,2.592,0,0,0-.05.427.616.616,0,0,0,.163.5.918.918,0,0,0,.565.132,1.631,1.631,0,0,0,.427-.066A2.388,2.388,0,0,0,35.382,8.831Zm.131-7.675a1.07,1.07,0,0,1-.361.814,1.23,1.23,0,0,1-.87.337,1.243,1.243,0,0,1-.874-.337,1.067,1.067,0,0,1-.365-.814,1.078,1.078,0,0,1,.365-.817,1.29,1.29,0,0,1,1.744,0A1.08,1.08,0,0,1,35.513,1.156Z" transform="translate(-23.663 5)" />
                            </svg>

                          </Image>}
                        </div>
                      </CustomLi>
                    })}
                  </ul>
                  <SeparatorDown />
                  <button onClick={() => page ? goto(`/payment/${id}` + (trade ? ('/true') : "")) : goto(login)} >Get Started</button>
                </div>
              </li>
            })}
          </ul>
        </div>
        <div>&nbsp;</div>
      </div>
    </section>
  )
}

export default PricingPlans;
