import styled from "styled-components"
import EditIcon from "../../Component/EditIcon"
import { H2 } from "../../Component/Heading"
import { DividerX } from "../../Component/Divider"
import React from "react"
import { ButtonOutline } from "../../Component/Button"
import { useNavigate } from "react-router"
import routesName from "../../Helpers/routesName"


const Header = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:1.5em 1.75em;
`;

const BoxHeader = ({ BoxTitle, button, divider, edit, setEdit }) => {
	const goto = useNavigate()
	return (
		<React.Fragment>
			<Header>
				<h2>{BoxTitle}</h2>
				{button ?
					<button className="btn btn-capsule btn-outline bg-blue" onClick={() => goto(routesName.pricing)}>View Plans</button>
					: <EditIcon isEdit={BoxTitle === "Account" ? edit.accounts : BoxTitle === "Details" ? edit.details : BoxTitle === 'Password' ? edit.image : BoxTitle === 'Picture' && edit.password}
						whenClick={() => {
							switch (BoxTitle) {
								case 'Account':
									setEdit({
										details: false,
										password: false,
										accounts: !edit.accounts,
										image: false
									});
									break;
								case 'Details':
									setEdit({
										details: !edit.details,
										password: false,
										accounts: false,
										image: false
									});
									break;
								case 'Password':
									setEdit({
										details: false,
										password: !edit.password,
										accounts: false,
										image: false
									});
									break;
								case 'Picture':
									setEdit({
										details: false,
										password: false,
										accounts: false,
										image: !edit.image
									});

									break;

							}

						}
						} />}
			</Header>
			{divider && <DividerX />}
		</React.Fragment>
	)
}

export default BoxHeader