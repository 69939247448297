import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserRatings } from "../../actions/profile";
import { useNavigate } from "react-router";
import UserInfoHeader from "../../Component/UserInfoHeader";
import { LoadUploadedImage } from "../../services/utilService";
import { H2 } from "../../Component/Heading";
import { DividerX } from "../../Component/Divider";


const Ratings = ({ id }) => {

    const dispatch = useDispatch();
    const ratings = useSelector((state) => state.Profile.ratings);
    const goto = useNavigate();
    const [viewMore, setViewMore] = useState(false);
    function ViewTrade(tradeId) {
        goto(`/view-trade/${tradeId}`)
    }

    useEffect(() => {

        dispatch(GetUserRatings(id))
    }, [dispatch, id])

    return (

        <ul className="user-rating">
            <H2>Reviews of Past Trades</H2>
            {(ratings.length) ? ratings.slice(0, viewMore ? 10 : 1).map((rating, ind) => {
                return (
                    <>
                        <li className="review-container">
                            <div onClick={() => ViewTrade(rating.tradeId)} className="review">
                                <UserInfoHeader key={'rating_' + ind} data={{ picture: (rating.ratedBy.image ? LoadUploadedImage(rating.ratedBy.image) : ""), name: rating.ratedBy.firstName + " " + rating.ratedBy.lastName, rating: rating.ratingStar }} />
                            </div>
                            <p>{rating.description}</p>
                        </li>
                        <DividerX />
                    </>

                )
            })
                : <p className="noData" color="blue" fontSize="1.5em" lh="auto">Not Rated Yet! </p>
            }
            {(ratings.length > 1) && <button className="btn btn-capsule btn-outline bg-blue" onClick={() => setViewMore(!viewMore)}>{viewMore ? "View Less" : "View More"}</button>}
        </ul>
    )
}

export default Ratings