import React from "react";
import { Button } from "../../../Component/Button";
import { FieldInput } from "../../../Component/FieldInput";
import { P1 } from "../../../Component/Para";
import Loader from "../../../Component/Loader";
import { Formik, Form } from 'formik';
import { ChangePasswordFormValidate } from "../../../Validations";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword } from "../../../actions/profile";
import styled from "styled-components";
import { Error, FormButtonsDiv } from "../../../Component/Inputs";

// Form Row
const FormRow = styled.div`
	margin-block:15px;
`

// submit button
const SubmitButton = styled(Button)`
	height:2.2em;
	min-height:35px;
	// display: grid;
	// place-items: center;
`

// Field Label
const FieldLabel = styled(P1)`
	margin-bottom:10px;
`

const PasswordForm = ({ loader, setLoader }) => {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.Auth);

	return (
		<div>
			<Formik
				initialValues={{
					oldPassword: "",
					newPassword: "",
				}}
				validationSchema={ChangePasswordFormValidate}
				onSubmit={values => {
					setLoader(true);
					dispatch(ChangePassword(values));
				}}
			>
				{formik => (
					<div>
						<Form autoComplete="off"> {/*Formik Form Import from Formik*/}
							<FormRow>
								<FieldLabel> Enter Current Password </FieldLabel>
								<FieldInput type="password" label="oldPassword" name="oldPassword" placeholder="Current Password" />
							</FormRow>
							<FormRow>
								<FieldLabel> Enter New Password </FieldLabel>
								<FieldInput type="password" label="newPassword" name="newPassword" placeholder="New Password" />
							</FormRow>
							<FormButtonsDiv>
								{authState.errorCall === "changePassword" && <Error> <p className="error">{authState.errorRespMsg}</p> </Error>}
								<button className="btn btn-capsule bg-blue" type="submit" disabled={loader}> {loader ? <Loader /> : "Save"} </button>
							</FormButtonsDiv>
						</Form>
					</div>
				)}
			</Formik>
		</div>
	)
}

export default PasswordForm;
