import { makeStyles } from "@material-ui/core/styles";
import { largeDialogBackground, smallDialogBackground } from "../Assets/Images";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      margin: '0px !important',
    },
    "& .MuiDialog-paperWidthSm": {
      minWidth: "60vw",
      height: '34vw',
      display: "grid",
      placeItems: "center",
      [theme.breakpoints.down(1200)]: {
        minWidth: "70vw",
        height: '38vw',
      },
      [theme.breakpoints.down(780)]: {
        minWidth: "90vw",
        height: '49vw',
      },
      [theme.breakpoints.down(600)]: {
        minWidth: "100vw",
        height: '58vw',
      },
      [theme.breakpoints.down(500)]: {
        height: '69vw',
      },
      [theme.breakpoints.down(410)]: {
        height: '80vw',
      },
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
    "& .MuiPaper-root": {
      backgroundColor: 'transparent'
    }
  },
  dialogBackground: {
    backgroundImage: `url(${largeDialogBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain", //cover
    width: "60vw",
    height: '100%',
    display: "grid",
    placeItems: "center",
    [theme.breakpoints.down(1200)]: {
      width: "66vw",
    },
    [theme.breakpoints.down(780)]: {
      minWidth: "86vw",
    },
    [theme.breakpoints.down(600)]: {
      minWidth: "95vw",
      height: "250px"
    },
    [theme.breakpoints.down(500)]: {
      minWidth: "95vw",
      height: "225px"
    },
    [theme.breakpoints.down(410)]: {
      minWidth: "95vw",
      height: "200px"
    },
  },
  smallDialogBackground: {
    backgroundImage: `url(${smallDialogBackground})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "50vw",
    height: "11vw",
    display: "grid",
    placeItems: "center",
    [theme.breakpoints.down(1250)]: {
      height: "13vw",
      width: "60vw",
    },
    [theme.breakpoints.down(800)]: {
      height: "16vw",
      width: "75vw",
    },
    [theme.breakpoints.down(650)]: {
      height: "20vw",
      width: "92vw",
    },
    [theme.breakpoints.down(500)]: {
      height: "25vw",
      width: "119vw",
    },
    [theme.breakpoints.down(420)]: {
      height: "30vw",
      width: "143vw",
    },
    [theme.breakpoints.down(320)]: {
      height: "40vw",
      width: "176vw",
    },
  },
  form: {
    display: "grid",
    placeItems: "center",
    width: '100%',
    height: '100%',
    textAlign: "center",
  },
  root: {
    display: "grid",
    placeItems: "center",
  },
  rating: {
    "& .css-1yxfe0m-MuiRating-root": {
      marginBlock: 'auto !important',
    }
  },
  congratsText: {
    color: "#FFFFFF",
    fontSize: "5vw",
    fontFamily: "PoppinsBold",
    [theme.breakpoints.down(1000)]: {
      fontSize: "4.5vw",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: "5vw",
    },
    [theme.breakpoints.down(780)]: {
      fontSize: "6vw",
    },
    [theme.breakpoints.down(500)]: {
      fontSize: "5vw",
    },
    [theme.breakpoints.down(420)]: {
      fontSize: "4vw",
    },
    [theme.breakpoints.down(320)]: {
      fontSize: "3vw",
    },
  },
  tradeText: {
    color: '#FFFFFF',
    fontSize: '1.7vw',
    fontFamily: 'PoppinsMedium',
    [theme.breakpoints.down(780)]: {
      fontSize: "3vw",
    },
    [theme.breakpoints.down(500)]: {
      fontSize: "5vw",
    },
  },
  leaveReviewContainer: {
    display: "flex",
    margin: "20px auto",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.down(1450)]: {
      margin: "10px auto",
    },
    [theme.breakpoints.down(500)]: {
      margin: "5px auto",
    },
  },
  leaveReviewText: {
    color: "#FFFFFF",
    fontSize: "0.9vw",
    fontFamily: "PoppinsRegular",
    marginRight: "15px",
    [theme.breakpoints.down(1270)]: {
      fontSize:"1.2vw",
    },
    [theme.breakpoints.down(900)]: {
      fontSize:"1.4vw",
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "2vw",
    },
    [theme.breakpoints.down(500)]: {
      fontSize: "3.5vw",
    },
  },
  subText: {
    color: "#FFFFFF",
    fontSize: "1.7vw",
    fontFamily: "PoppinsMedium",
    [theme.breakpoints.down(1250)]: {
      fontSize: "2.2vw",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: "3vw",
    },
    [theme.breakpoints.down(650)]: {
      fontSize: "3.5vw",
    },
    [theme.breakpoints.down(500)]: {
      fontSize: "5vw",
    },
    [theme.breakpoints.down(420)]: {
      fontSize: "6vw",
    },
    [theme.breakpoints.down(320)]: {
      fontSize: "7.2vw",
    },
  },
  textArea: {
    backgroundColor: "transparent",
    border: "1px solid #FFFFFF",
    borderRadius: "15px",
    // width: "20vw",
    width:'400px',
    height: "70px",
    color: "#FFFFFF",
    padding: "10px",
    "&::placeholder": {
      color: "#FFFFFF",
      fontFamily: "PoppinsRegular",
    },
    "&:focus": {
      border: "1px solid #FFFFFF",
      outline: "none",
    },
    [theme.breakpoints.down(1150)]: {
      height: "57px",
      width: "35vw",
    },
    [theme.breakpoints.down(800)]: {
      height: "45px",
    },
    [theme.breakpoints.down(500)]: {
      width: "60vw",
      height: "30px",
      padding: "4px 10px",
    },
  },
  submitBtn: {
    border: "1px solid #FFFFFF",
    backgroundColor: "transparent",
    color: "#FFFFFF",
    // width: "5.2vw",
    width: '98px',
    height: "28px",
    borderRadius: "27px",
    marginTop: "20px",
    fontFamily: "PoppinsRegular",
    cursor: "pointer",
    '&:hover': {
      backgroundColor: "#FFFFFF",
      color: "#33B0F2",
    },
    [theme.breakpoints.down(1000)]: {
      marginTop: "13px",
    },
    [theme.breakpoints.down(500)]: {
      marginTop: "8px",
    },
  },
}));

export default useStyles;
