import styled from "styled-components";
import { H1, H2, Heading5 } from "./Heading";
import { P1, P2 } from "./Para";
import { RoundImage } from "./Images";
import { CustomRating } from "./CustomRating";
import { pinIcon, ProfileImage } from '../Assets/Images';
import { DividerX } from "./Divider";
import { StaticTags } from "./Tags";
import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
// import { CurrentTrade } from "../actions/trade";
import moment from 'moment';
import { LoadUploadedImage } from "../services/utilService";
import { CheckBox } from "./FieldInput";
// import Moment from 'react-moment';
// import routesName from '../Helpers/routesName'


const PersonInfo = styled.div`
    display:flex;
    margin-bottom:.5em;
    .info{
        display:flex;
        flex-direction:column;
        justify-content:center;
        margin-left:1em;
        & H1{
            font-size:1.1em;
        }
        & span p{
            font-size:.8em;
        }
        .ratings{
            margin-top:.75em;
            display:flex;
            align-items:center;
            gap:10px;
        }

        img{
            margin-left:.5em;
            width:.75em;
            height:1.2em;
        }
    }
`
const TradeItem = styled.div`
    margin-block:.5em;
    cursor:pointer;
    & .para2{
        margin-top:1em;
    }
    & .for{
        margin-top:1.5em;
        h5{
            color:var(--blackashgreen);
            margin-bottom:.5em;
        }
    }
`
const TradeHeader = styled.div`
    display:flex;
    margin-bottom:.5em;
    .info{
        display:flex;
        flex-direction:column;
        justify-content:center;
        margin-left:1em;
        & H1{
            font-size:1.5em;
        }
        .location{
            margin-top:.75em;
            display:flex;
            align-items:center;
            gap:10px;
        }
    }
`
const AboutTrade = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:10px;
    margin-block:1.6em 1em;
`

// FormRow
const FormRow = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
	max-width : 26.55em;
	& .description{
		margin-block:1.3em;
		& p{
			margin-block:.3em;
		}
	}
`;



const UserInfoHeader = ({ data }) => {
    const { picture, name, rating } = data

    return (
        <PersonInfo>
            <RoundImage alt="" src={picture} onError={(e) => { e.target.src = LoadUploadedImage("") }} width='5em' height='5em' />
            <div className="info">
                <H1 color="blue">{name}</H1>
                <span className="ratings">
                    <P1>Rating</P1>
                    <CustomRating rating={rating} readOnly={true} />
                </span>
            </div>
        </PersonInfo>
    )
}

export const TradeUserInfoHeader = ({ data }) => {
    const { profileSrc, tradeName, tradeCreatedDate, tradeDescription, wantTradeTags, offerTradeTags, userId, localOnly } = data
    const goto = useNavigate();
    const dispatch = useDispatch();
    // const { viewProfile } = routesName
    const onTradeViewHandler = (e, trade) => {
        e.preventDefault();
        e.stopPropagation()
        goto(`/view-trade/${data?._id}`);
    }

    const onProfileViewHandler = (e, userId) => {
        e.preventDefault();
        e.stopPropagation()
        goto(`/view-profile/${userId?._id}`);
    }

    return (
        <TradeItem onClick={(e) => { onTradeViewHandler(e, userId) }}>
            <TradeHeader>
                <RoundImage onClick={(e) => { onProfileViewHandler(e, userId) }} src={LoadUploadedImage(userId.image ? userId.image : "")} onError={(e) => { e.target.src = LoadUploadedImage("") }} alt="" width='5em' height='5em' />
                <div className="info">
                    <H1 color="blackashgreen">{userId?.profileTitle || (userId?.firstName + " " + userId.lastName)}</H1>
                    {userId.state && userId.country ? <span className="location">
                        <img src={pinIcon} alt="Location" width="9px" height="" />
                        <P1 fontSize='.8em'>{(userId.state ? userId.state : "") + (userId.country ? (", " + userId.country) : "")}</P1>
                    </span> : ""}
                </div>
            </TradeHeader>
            <AboutTrade>
                <H2>{tradeName}</H2>
                <P1 fontSize=".8em">Posted {moment(tradeCreatedDate).fromNow()}</P1>
            </AboutTrade>
            <DividerX color="blackOp010" />
            <P2 margin='.8em 0em' lineHeight='auto' style={{ "white-space": "pre-wrap", "wordBreak": "break-word" }} className="wordBreak">{tradeDescription}</P2>
            {/* <FormRow width="auto"> */}
            {/* <CheckBox disabled label='Local only' checked={localOnly} type="checkbox" style={{ fontSize: '1em' }} /> */}
            {localOnly ? <label>
                <input onChange={((e) => { return false })} checked={localOnly} type="checkbox" style={{ fontSize: '1em', margin: '0px 5px 0 0' }} />
                Local only</label> : ""}
            {/* </FormRow> */}
            {/* <P2 margin='.8em 0em' lineHeight='auto' style={{ "white-space": "pre-wrap", "wordBreak": "break-word" }} className="wordBreak">{localOnly}</P2> */}
            <div className="lookingFor for">
                <Heading5>Looking For</Heading5>
                <StaticTags list={wantTradeTags} />
            </div>
            <div className="exchangeFor for">
                <Heading5>In Exchange For</Heading5>
                <StaticTags list={offerTradeTags} />
            </div>
        </TradeItem>
    )
}

export default UserInfoHeader
