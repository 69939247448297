import styled from "styled-components";
import EditIcon from "../../../Component/EditIcon";
import { H2 } from "../../../Component/Heading";
import {
  Button,
  OnlyButton
} from "../../../Component/Button";
import {
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteGallery, UpdateGallery } from "../../../actions/profile";
import Loader from "../../../Component/Loader";
import { LoadUploadedImage } from "../../../services/utilService";
import { UpdateImage } from "../../../actions/profile";
import { useEffect } from "react";

const CustomDiv = styled.div`
    width:100%;
    padding:2em 1em;
    & .viewMore{
        padding-left:8px;
        color:var(--blue);
        &:hover{
            color:var(--blue-dark);
        }
    }
`

const Galleries = styled.ul`

`

const Item = styled.li`
    flex-basis:30%;
    background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    // background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    background-size:cover;
    background-repeat:no-repeat;
    height:9.35em;
    border-radius:15px;
    margin:.5em;
`

const ProfilePictureForm = ({ loader, setLoader, galleryEdit, setGalleryEdit }) => {
  const [end, setEnd] = useState(3);
  const [viewMore, setViewMore] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const imageUpload = useRef()
  const dispatch = useDispatch();
  const profile = useSelector(state => state.Profile.userProfile);
  const authState = useSelector(state => state.Auth.authState);


  useEffect(() => {
    if (loader) {
      if (authState.errorCall && authState.errorRespMsg) {
      }
      else {
        setLoader(false);
        setSelectedImage(null)

      }
      setLoader(false);
    }
  }, [authState])

  const onSelectImageHandler = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(UpdateImage(selectedImage));
    setSelectedImage(null)
    setTimeout(() => {

      setLoader(false);
    }, 1000);

  }

  const onDeleteHandler = () => {
    setLoader(true);
    // dispatch(DeleteGallery(val));
  }

  return (
    <CustomDiv>
      <div>
        <H2>Profile Picture</H2>
        <EditIcon whenClick={() => {
          setGalleryEdit({ ...galleryEdit, image: !galleryEdit.image })
          !galleryEdit.image ? setEnd(2) : setEnd(3)
        }} isEdit={galleryEdit.image} />
      </div>
      <div className="profile-galleries">
        {galleryEdit.image &&
          <div onClick={(e) => {e.stopPropagation();imageUpload.current.click()}}>
            <Item className="item upload" key={'_'} >
              <input className='uploader' type="file" ref={imageUpload} accept="image/*" onChange={onSelectImageHandler} />
              <p>{selectedImage ? selectedImage.name : "Choose Image"}</p>
              {selectedImage && <button className="btn btn-capsule bg-blue" onClick={(e) =>{ e.stopPropagation();onSubmitHandler(e)}}
                disabled={loader}> {loader ? <Loader width="17px" height="17px" /> : "Upload"} </button>}
            </Item>
          </div>
        }
        {profile.image ?
          <Item image={LoadUploadedImage(profile.image)}></Item> : ""
        }
      </div>
    </CustomDiv >
  )
}

export default ProfilePictureForm
