import styled from 'styled-components';
import { RoundBox } from '../../Component/Containers';
import { H1, H2 } from '../../Component/Heading';
import { DividerX } from '../../Component/Divider';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { size } from "../../Helpers/breakpoint";
import Loader from './../../Component/Loader';
import { Input, Error, InputTextArea, FieldCheckBox } from './../../Component/Inputs';
import socketService from '../../services/socketService';
import { EmptyMyTradeList, GetMyAllTrades } from '../../actions/trade';
import { iconsSprite, SelectArrow } from '../../Assets/Images'
import { FunctionsOutlined } from '@mui/icons-material';
import { useRef } from 'react'
import { P1 } from '../../Component/Para';




const Div = styled.div`
        width:100%;
    `

// FormRow
const FormRow = styled.div`
width:${({ width }) => width ? width : '100%'} !important;
margin-block:.8em;
margin: 10px 0;
& .description{
  margin-block:1.3em;
  & p{
    margin-block:.3em;
  }
}
`;


const InviteModal = ({ title, loader, setLoader, setView, otherUserId }) => {

  const [show, setShowState] = useState({})
  const [description, setDescription] = useState("")
  const [selectedTrade, setSelectedTrade] = useState("")
  const [showOptions, setShowOptions] = useState(false)
  const [error, setError] = useState("")
  const dispatch = useDispatch();
  const profile = useSelector(state => state.Profile.userProfile);
  const myTrades = useSelector((state) => state.Trade.myTrades)
  const tradeRef = useRef("")

  useEffect(() => {
    dispatch(EmptyMyTradeList())
    dispatch(GetMyAllTrades({ status: "pending", limit: 50, page: 1 }))
  }, [])

  useEffect(() => {


    if (!myTrades.length) setError("No Trades to Invite")
    else {
      myTrades.map(x => setShowState((prev) => ({ ...prev, [x._id]: false })))
      setError("")
    }
  }, [myTrades])


  const onSubmitHandler = (e) => {
    // console.log(selectedTrade)

    if (selectedTrade && description.trim()) {

      const invite = {
        receiverId: otherUserId,
        invitedBy: selectedTrade.userId._id,
        tradeId: selectedTrade._id,
        createdDate: new Date(),
        description: description
      }
      setLoader(true);
      CreateInvite(invite)
      setError("");

    }
    else {
      setError("Please fill the fields above");
    }

    //if rest
    // dispatch(InviteUser(invite));
  }

  function CreateInvite(data) {


    socketService.SendEmit("send-invite", data)
    setLoader(false);
    setView(false)
  }

  return (
    <div className='modal'>

      <div className='modal-container'>
        <div className='modal-header'>
          <h2 lh='auto' fontSize='1.25em'>{title}</h2>
        </div>
        <div className='p-0'>
          <DividerX />
        </div>
        <div className="modal-content">
          <div className="addTags">
            <Div>

              <FormRow width="100%" onMouseLeave={(e) => { e.stopPropagation(); setShowOptions(false) }} onMouseOver={(e) => { e.stopPropagation(); setShowOptions(true) }}>
                <input className="invite-select" ref={tradeRef}
                  isIcon={SelectArrow} disabled={!myTrades.length} placeholder='Trades' value={selectedTrade.tradeName}
                  list="trades"
                  onClick={(e) => { tradeRef.current.value = ""; setSelectedTrade("") }}
                />
                {showOptions ?
                  <div className="invite-options">
                    {/* <datalist id="trades"> */}
                    {/* <option selected>No Trade Selected</option> */}
                    {myTrades.length ? myTrades.map((val, i) => {
                      return (
                        <div id={val._id} onClick={(e) => { setSelectedTrade(val) }} className="trade-item"
                        onMouseLeave={(e) => setShowState((prev) => ({ ...prev, [val._id]: false }))} onMouseOver={(e) => setShowState((prev) => ({ ...prev, [val._id]: true }))}
                        >
                          <div className="about-trade" >
                            <div>
                              <H2>{val.tradeName}</H2>
                            </div>
                          </div>
                          {show[val._id] ? <div className="trade-info">

                            <P1 style={{ "white-space": "pre-wrap", "wordBreak": "break-word" }}>{val.tradeDescription}</P1>

                          </div> : ""}
                          {/* <DividerX /> */}
                        </div>)
                      // return <option key={i}>{`${val.tradeName}  ${val?.tradeDescription.substr(0, 100)}`}</option>
                      // return <option key={val._id}>{`${val.tradeName} ${val?.tradeDescription.substr(0, 100)}`}</option>

                    }) : <div>No Trades</div>}
                    {/* </datalist> */}
                  </div>
                  : ""}
              </FormRow>


              {/* <select onChange={(e) => { setSelectedTrade(e.target.value) }}>
                <option selected>No Trade Selected</option>
                {myTrades ? myTrades.map(val => {
                  return <option value={val._id}>{val.tradeName}</option>
                }) : <option disabled >No Trades</option>}

              </select> */}
            </Div>
          </div>
          <div className="">
            <InputTextArea label="description" onChange={(e) => { setDescription(e.target.value) }} name="description" placeholder="Description" style={{ fontSize: '1em', height: '10em' }} />
          </div>
        </div>
        <div className="modal-footer">
          <button className='btn btn-capsule btn-outline bg-charcoal-300' onClick={() => setView(false)}>Cancel</button>
          <button className='btn btn-capsule bg-blue' onClick={(e) => onSubmitHandler(e)} disabled={loader}>{loader ? <Loader margin="auto" /> : "Invite"}</button>
        </div>
        {error ? <Error> <p className="error">{error}</p> </Error> : ""}
      </div>
      <div className="blur"></div>
    </div>
  )
}

export default InviteModal;
