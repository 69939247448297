import styled from "styled-components";
import { H2 } from "../../Component/Heading";
import { StaticTags } from "../../Component/Tags";
import React from "react";

const TopSkills = ({ userDetail }) => {

  return (
    <div className="topSkillsContent">
      <H2>Top Skills</H2>
      <StaticTags list={userDetail?.tags} />
    </div>
  )
}

export default TopSkills