import "../Trade.scss";
import React, { useEffect, useState } from "react";
import { DividerX, DividerY } from '../../../Component/Divider';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { OtherUserProfile } from "../../../actions/profile";
import styled from "styled-components";
import { GridContainer, RoundBox, Section } from "../../../Component/Containers";
import { size } from "../../../Helpers/breakpoint";
import { GetCurrentTrade } from "../../../actions/trade";
import CreateOffer from "../CreateOffer";
import RateTradeModal from "../RateTrade";
import TradeInfo from "../MyTradeInfo/TradeInfo";
import { Button } from "../../../Component/Button";
import OfferInfo from "../../Offers/OfferInfo/Info";
import { CustomButtonOutline } from "../../../Component/DashboardHeader";
import { H1 } from "../../../Component/Heading";
import ProfileHeader from '../../ViewProfile/ProfileHeader'
import TopSkills from "../../ViewProfile/TopSkills";
import AboutMe from "../../UserProfile/AboutMe";
import { AcceptOffer } from "../../../actions/offers";
import { CustomButton } from "../../Messages/StyledComponents";
import routesName from './../../../Helpers/routesName'
import Loader from '../../../Component/Loader';
import UserWithInfo from "../../UserProfile/UserWithInfo";
import DeleteTradeComp from "../DeleteTradeComp";
import socketService from "../../../services/socketService";
import { GoBack } from "../../../Component/SquarePictureAnim";
import { backArrow, backIcon } from "../../../Assets/Images";
import DialogComp from "../../../Dialog/DialogComp";

const CustomRoundBox1 = styled(RoundBox)``;

// List Item
const Li = styled.li`
cursor:pointer;
margin-block:5px;
&:hover{
    color:var(--blue);
}
`

// FormRowButton
const FormRowButton = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	gap:1em;

`

// Each Trade Container
const EachTrade = styled.div``;


const ViewTrade = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.Auth.userDetails.userDetail);
  const [loader, setLoader] = useState(false);
  const [rateModalState, setRateModalState] = useState(false);
  const { dashboard, myOffers, myTrades } = routesName
  const canMessage = useSelector((state) => state.Profile.canMessage);
  const fetching = useSelector((state) => state.Trade.fetching);



  let selectedTrade = useSelector((state) => state.Trade.currentTrade);

  let tradeBelongsToUser = (user && selectedTrade?.trade && (selectedTrade?.trade[0]?.userId._id == user.id))


  let showAccept = ((tradeBelongsToUser && selectedTrade?.trade[0]?.status == 'pending'))
  const { id } = useParams();
  const goto = useNavigate();

  const [greetings, setGreetings] = useState(true);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");


  useEffect(() => {

    if (!tradeBelongsToUser && selectedTrade) {

      dispatch(OtherUserProfile(selectedTrade?.trade[0]?.userId._id));
    }
    if (id) LoadTrade(id)
  }, [id, user, tradeBelongsToUser])


  function LoadTrade(id) {
    dispatch(GetCurrentTrade(id))
  }
  useEffect(() => {
    if (socketService.socket) {

      socketService.socket.on("got-offer", (data) => {
        if (selectedTrade) {
          // handle offer receiving here
        }
      })
    }
  }, [socketService.socket]);

  return (

    <section className="mytrade-section">
      {(user && selectedTrade?.trade?.length) ?
        <div className="container grid-container gc-center">
          <div><h2 lh='auto'>&nbsp;</h2></div>
          <div>
            <div className="header">
              <h2 lh='auto' className='currentSelection'>{(!tradeBelongsToUser && (!selectedTrade?.offers.length || (selectedTrade?.offers?.length && !selectedTrade?.offers.filter(x => x.userId._id === user.id).length))) ? "Apply For Trade" : "Trading Contract"}</h2>
              <button className='go-back-btn' title="Back to feed" onClick={() => goto(-1)}>
                <img src={backArrow} className="backArrow" alt="Back" width="" height="" />
                <img src={backIcon} className="back" alt="Back" width="" height="" />
              </button>
            </div>
            {!fetching ? <div>
              <CustomRoundBox1 className="custom_round_box mb-3">
                <div className="each-trade">
                  <TradeInfo data={selectedTrade.trade[0]} allowNavigate={false} showDelete={(tradeBelongsToUser && (selectedTrade?.trade[0].status == 'pending'))} showExchageOffer={true} showLookingOffer={true} showTrades={true} />
                  {tradeBelongsToUser ? <UserWithInfo /> :
                    <ProfileHeader friendId={selectedTrade?.trade[0].userId._id} canMessage={canMessage} userDetail={selectedTrade?.trade[0].userId} showMessage={true} />}
                  <FormRowButton>
                    {(tradeBelongsToUser && selectedTrade.trade[0].status == 'active') ? <button className="btn btn-capsule bg-blue" disabled={loader} onClick={(e) => setRateModalState(!rateModalState)} >{loader ? <Loader /> : "Mark Complete"}</button> : ""}
                    {tradeBelongsToUser ? <DeleteTradeComp _id={selectedTrade.trade[0]._id} setOpen={setOpen} setGreetings={setGreetings} setText={setText}></DeleteTradeComp> : ""}
                  </FormRowButton>
                </div>

              </CustomRoundBox1>

              {tradeBelongsToUser ? <div className="header">
                <h2 lh='auto' className='currentSelection'>{"Offer"}</h2>
              </div> : ""}


              {tradeBelongsToUser ?
                selectedTrade.trade[0].status == 'pending' ?

                  (selectedTrade?.offers.length) ? selectedTrade?.offers.map((val, ind) => {
                    return (
                      <CustomRoundBox1 className="custom_round_box mb-3">
                        <div key={ind} className="each-trade">
                          <OfferInfo title={"Offer"} showUserInfo={true} key={'OfferList_' + ind} data={val}
                            showAccept={showAccept} isUsers={false} alwaysShow={true}
                            setOpen={setOpen} setText={setText} setGreetings={setGreetings}
                          />
                        </div>
                      </CustomRoundBox1>
                    )
                  })
                    : <CustomRoundBox1 className="custom_round_box"><div className="each-trade"><p className="noData center" color="blue" fontSize="1.5em" lh="auto"> No Offers yet! </p></div></CustomRoundBox1>
                  :
                  (selectedTrade.trade[0].status == 'completed' || selectedTrade.trade[0].status == 'active') ?
                    <CustomRoundBox1 className="custom_round_box mb-3">
                      <div className="each-trade">
                        {selectedTrade?.offers.length ? <OfferInfo title={"Offer"} showAccept={showAccept} showUserInfo={true} data={selectedTrade?.offers[0]}
                          tradeBelongsToUser={tradeBelongsToUser} isUsers={false}
                          alwaysShow={true} setOpen={setOpen} setText={setText} setGreetings={setGreetings} /> : ""}
                        {(rateModalState) ? <RateTradeModal tradeId={selectedTrade.trade[0]._id} ratedBy={user.id} ratedFor={selectedTrade?.trade[0]?.contractorId._id} title='Rate a Trade' loader={loader} setLoader={setLoader} setView={setRateModalState} userCreated={tradeBelongsToUser}/> : ""}
                        {/* <button className="btn btn-outline btn-capsule bg-charcoal-300" type="button" onClick={() => goto(dashboard)}>Go Back</button> */}

                      </div>
                    </CustomRoundBox1>
                    : ""
                : ""
              }

              {!tradeBelongsToUser ?
                selectedTrade.trade[0].status == 'pending' ?

                  <CustomRoundBox1 className="custom_round_box mb-3">
                    <div className="each-trade">
                      {selectedTrade.offers.filter(x => x.userId._id == user.id).length ?
                        <OfferInfo title={"My Offer"} showUserInfo={true} data={selectedTrade?.offers[0]}
                          isUsers={true} alwaysShow={true} showAccept={showAccept}
                          setOpen={setOpen} setText={setText} setGreetings={setGreetings}
                        /> :
                        <CreateOffer
                          tradeId={selectedTrade.trade[0]._id} userOfferedId={selectedTrade.trade[0].userId._id} userId={user.id}
                          setOpen={setOpen} setText={setText}
                        ></CreateOffer>
                      }
                    </div>
                  </CustomRoundBox1>
                  :
                  (selectedTrade?.trade[0].status == 'completed' || selectedTrade.trade[0].status == 'active') ?
                    <CustomRoundBox1 className="custom_round_box mb-3">
                      <div className="each-trade">
                        {selectedTrade?.offers.length && selectedTrade?.offers[0].offerStatus == 'accepted' ? <OfferInfo title={"My Offer"} showUserInfo={true} data={selectedTrade?.offers[0]} isUsers={false} alwaysShow={true} showAccept={showAccept} setOpen={setOpen} setText={setText} setGreetings={setGreetings} /> : ""}
                        <FormRowButton>
                          {(!tradeBelongsToUser && selectedTrade.trade[0].status == 'completed' && !selectedTrade.trade[0].contractorReview) ? <button className="btn btn-capsule bg-blue" disabled={loader} onClick={(e) => setRateModalState(!rateModalState)} >{loader ? <Loader /> : "Mark Complete"}</button> : ""}
                        </FormRowButton>
                      </div>
                      {(rateModalState) ? <RateTradeModal tradeId={selectedTrade.trade[0]._id} ratedBy={user.id} ratedFor={selectedTrade?.trade[0]?.userId._id} title='Rate a Trade' loader={loader} setLoader={setLoader} setView={setRateModalState} userCreated={tradeBelongsToUser}/> : ""}

                    </CustomRoundBox1>
                    : ""
                : ""
              }
            </div> : <Loader width="80px" height="80px" />}

          </div>
          <DialogComp
            open={open}
            onClose={() => setOpen(false)}
            text={text}
            greet={greetings}
          />

          <div><h2 lh='auto'>&nbsp;</h2></div>
        </div>
        : ""}
    </section >
  )
}

export default ViewTrade

