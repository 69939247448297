import React from 'react';
import { FieldInput } from '../../Component/FieldInput';
import { Button } from '../../Component/Button';
import styled from "styled-components";
import { Formik, Form } from 'formik';
import { HeadingLarge } from '../../Component/Heading';
import { paymentFormValidate } from '../../Validations';
import SearchSelect from '../../Component/SearchSelect'
import countries from '../../Helpers/countries';
import { useDispatch, useSelector } from 'react-redux';
import { OneTimePayment, Subscription } from '../../actions/payment';
import { useParams } from "react-router-dom";
import { pricingPlans } from '../Home/PricingPlans/content';
import DialogComp from '../../Dialog/DialogComp';
import Loader from '../../Component/Loader';
import { useNavigate } from 'react-router-dom';
import routesName from '../../Helpers/routesName';
import { visaImage } from '../../Assets/Images';
import { size } from '../../Helpers/breakpoint';
import { Error } from '../../Component/Inputs';

const CustomDiv = styled.div`
 display:flex;
 justify-content:space-between;
 & .billingInfo, & .creditCardInfo{
	width:96%;
 }
`
// Form Title
let Title = styled(HeadingLarge)`
margin-block: .8em;
font-size:2.5em;
`
// Form Row
const FormRow = styled.div`
	margin-block:15px;
`

// Form Row
const FormColumn = styled.div`
margin-block:15px;
display:flex;
gap: 0.5em;
justify-content:space-between;
& .visa{
  width:100%;
  height:2.2em;
  min-height:35px;
  border:1px solid var(--grey);
  border-radius:25px;
  display:grid;
  place-content:center;
}
@media only screen and (max-width: ${size.btwMLT}) {
  & .visa img{
    width:4em;
  }
}
`

// ContinueWithEmail
const PayNow = styled(Button)`
	width:48%;
	height:2.2em;
  min-height:35px;
	display: grid;
	place-items: center;
`
// Cancel
const Cancel = styled(Button)`
	width:48%;
		height:2.2em;
  min-height:35px;
 	background:var(--grey);
	 &:hover{
		 background:var(--greyashgreen);
	 }
`

const StripeFormComp = ({ loader, setLoader, open, setOpen }) => {

  const dispatch = useDispatch();
  const goto = useNavigate();
  const { id } = useParams();
  const [getPlan] = pricingPlans.filter((v) => v.id === id);
  const { pricing } = routesName;
  const authState = useSelector((state) => state.Auth)
  return (
    <>
      <Title>Make a Payment</Title>
      <div>
        <Formik
          initialValues={{
            // fullName: '',
            // address: '',
            // city: '',
            zipCode: '',
            // country: '',
            cardHolderName: '',
            expMonth: "",
            expYear: "",
            cardNumber: "",
            cv: "",

          }}
          validationSchema={paymentFormValidate}
          onSubmit={async (values) => {
            setLoader(true);
            if (getPlan.id === "1") {
              dispatch(OneTimePayment(values, getPlan.price));
            } else {
              dispatch(Subscription(values));
            }
          }}
        >
          {formik => (
            <div>
              <Form autoComplete="off">
                <CustomDiv className='fields'>
                  {/* <div className='billingInfo'>
                    <FormRow>
                      <FieldInput label="fullName" name="fullName" type="text" placeholder="Full Name" />
                    </FormRow>
                    <FormRow>
                      <FieldInput label="address" name="address" type="text" placeholder="Address" />
                    </FormRow>
                    <FormColumn>
                      <FieldInput label="city" name="city" type="text" placeholder="City" />
                      <FieldInput label="zipCode" name="zipCode" type="text" placeholder="Zip Code" />
                    </FormColumn>
                    <FormRow>
                      <SearchSelect label="country" name="country" placeholder="Country" options={countries} />
                    </FormRow>
                  </div> */}

                  <div className='creditCardInfo'>
                    <FormRow>
                      <FieldInput label="cardNumber" name="cardNumber" type="text" placeholder="Card Number" />
                    </FormRow>
                    <FormRow>
                      <FieldInput label="cardHolderName" name="cardHolderName" type="text" placeholder="Card Holder Name" />
                    </FormRow>
                    <FormRow>
                      <FieldInput label="zipCode" name="zipCode" type="text" placeholder="Zip Code" />
                    </FormRow>
                    <FormColumn>
                      <FieldInput label="expMonth" name="expMonth" type="text" placeholder="Expiry Month" />
                      <FieldInput label="expYear" name="expYear" type="text" placeholder="Expire Year" />
                    </FormColumn>
                    <FormColumn>
                      <FieldInput label="cv" name="cv" type="password" placeholder="CVV" />
                      {/* <FieldInput label="a" name="a" value="VISA" readonly type="text" placeholder="VISA" /> */}
                      <div className="visa">
                        <img src={visaImage} alt="" width="" height="" />
                      </div>
                    </FormColumn>
                  </div>
                </CustomDiv>
                <FormRow>
                  {
                    authState.errorCall === "payment" && <Error> <p className="error">{authState.errorRespMsg} </p> </Error>

                  }
                </FormRow>
                <FormColumn>
                  <button className='btn btn-capsule bg-blue w100' type='submit'> {loader ? <Loader /> : "Pay Now"} </button>
                  <button className='btn btn-capsule bg-charcoal-300  w100' onClick={() => goto(pricing)} >Cancel</button>
                </FormColumn>
              </Form>
            </div>
          )}
        </Formik>

      </div>
      <DialogComp
        open={open}
        onClose={() => setOpen(false)}
        text="You've Made a Purchase"
      />
    </>
  )
}

export default StripeFormComp;
