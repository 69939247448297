import styled from "styled-components";
import { size } from "../Helpers/breakpoint";

const Input = styled.input`
    width: 100%;
    height:2.2em;
    min-height:35px;
    background:url(${({ isIcon }) => isIcon});
    background-repeat:no-repeat;
    background-size:1em;
    background-position-x: .5em;
    background-position-y: center;
    color: var(--grey);
    border-radius: 22px;
    outline: none;
    font-size: .8em;
    padding-left: ${props => props.isIcon ? "2em" : "1em"};
    padding-right: .3em;
    font-family: PoppinsRegular;
    border: ${props => props.err ? '1px solid var(--red)' : '1px solid var(--grey)'};
    &:hover{
        border-color:${props => props.err ? 'var(--red)' : 'var(--blue)'};
    }
    @media only screen and (max-width: ${size.tablet}) {
		border-radius:16px;
	}
    
`

const FieldCheckBox = styled.input`
    width: 13px;
    height:13px;
    font-family: PoppinsRegular;
    font-size:.8em;
    appearance:none;
    position:relative;
    margin-right:10px;
    &::after{
        content:"";
        background:var(--whiteF0);
        width:7px;
        height:7px;
        position:absolute;
        border-radius:50px;
        top:-3px;
        left:0px;
        border:6px solid var(--whiteF0);
    }
    &:checked::after{
        background:var(--blue);
        border:6px solid var(--whiteF0);
    }
    
   
`
const Error = styled.div`
    width: inherit;
    color: var(--red);
    outline: none;
    font-size: .8em;
    text-align:left;
    padding-right: 2px;
    font-family: PoppinsRegular;
    
    & .error{
        font-size: 1.1em;
        text-align:center;
    }
`

const InputTextArea = styled.textarea`
    width: 100%;
    height:20em;
    min-height:35px;
    background:url(${({ isIcon }) => isIcon});
    background-repeat:no-repeat;
    background-size:1em;
    background-position-x: .5em;
    background-position-y: center;
    color: var(--grey);
    border-radius: 22px;
    outline: none;
    font-size: .8em;
    padding-left: ${props => props.isIcon ? "2em" : "1em"};
    padding-right: .3em;
    padding-top: 1em;
    resize:none;
    font-family: PoppinsRegular;
    border: ${props => props.err ? '1px solid var(--red)' : '1px solid var(--grey)'};
    &:hover{
        border-color:${props => props.err ? 'var(--red)' : 'var(--blue)'};
    }
    @media only screen and (max-width: ${size.tablet}) {
		border-radius:16px;
	}
`;

const FormButtonsDiv = styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    gap:.5em;
`
export {
    Input,
    Error,
    FieldCheckBox,
    InputTextArea,
    FormButtonsDiv
}