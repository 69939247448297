import { useNavigate } from 'react-router'

const Logo = ({ w, h, logoSrc }) => {
    const goto = useNavigate()
    const refHome = '/'

    return (
        <img src={logoSrc} onClick={() => goto(refHome)} alt="Barter-E" width={w} height={h} />
    )
}

export default Logo