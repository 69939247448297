import axios from "axios";
import {
  GetConversation,
  GetMessage,
  ErrorMessage,
  AddConversation,
  EmptyConversation,
  UpdateConversation,
  SendingMessage
  // AddNewMessage,
} from "../../actionTypes/ActionTypes";
import { InitialApiRoute } from "../../config/ApiConfig";
import { EmptyErrorMsg } from "../auth";

const GetConversations = () => {
  return (dispatch) => {

    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(
        `${InitialApiRoute}/user/conversations/get_conversations`,
        ProtectedApiConfig
      )
      .then((resp) => {

        dispatch(EmptyErrorMsg());
        dispatch({
          type: GetConversation,
          payload: resp.data.conversations,
        });

      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "conversation",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};


const CreateConversation = (body) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${InitialApiRoute}/user/conversations/create_conversation`,
        body,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: AddConversation,
          payload: resp.data.conversation,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "conversation",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const GetMessages = (id) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(
        `${InitialApiRoute}/user/message/${id}`,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: GetMessage,
          payload: resp.data.messages,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "message",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const AddMessage = (body) => {
  return (dispatch) => {


    dispatch({
      type: SendingMessage,
      payload: true,
    });

    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${InitialApiRoute}/user/message/add_message`,
        body,
        ProtectedApiConfig
      )
      .then((_resp) => {
        dispatch({
          type: UpdateConversation,
          payload: {
            key: _resp.data.conversationId,
            message: _resp.data.text
          },
        });

        dispatch({
          type: SendingMessage,
          payload: false,
        });

        // dispatch(EmptyErrorMsg());
        // dispatch({
        //   type: AddNewMessage,
        //   payload: resp.data.messages,
        // });
      })
      .catch((err) => {
        console.log(err)
        const error = {
          message: err.response.data.message,
          call: "message",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });

        dispatch({
          type: SendingMessage,
          payload: false,
        });

      });
  };
};


const UploadAttachment = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": 'multipart/form-data'
      },
    };

    let formData = new FormData()
    formData.append('attachment', data);

    axios
      .post(
        `${InitialApiRoute}/user/chat/upload_attachment`,
        formData,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch({
          type: "Upload_Attachment",
          payload: resp.data,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "Upload_Attachment",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const EmptyCurrentConversation = () => ({ type: EmptyConversation });

export { GetConversations, GetMessages, AddMessage, CreateConversation, EmptyCurrentConversation };
