import axios from "axios";
import { ErrorMessage, OneTimePlan, SubscriptionPlan, SubscriptionDetails } from "../../actionTypes/ActionTypes";
import { InitialApiRoute } from "../../config/ApiConfig";
import { EmptyErrorMsg } from "../auth";

const OneTimePayment = (data, price) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const paymentDetails = {
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cv: data.cv,
      ammount: price,
      planId: "6189d29d26c2a53d78c818c8",
    };

    axios
      .post(
        `${InitialApiRoute}/user/plan/do_one_time_payment`,
        paymentDetails,
        ProtectedApiConfig
      )
      .then((resp) => {

        dispatch(EmptyErrorMsg());
        dispatch({
          type: OneTimePlan,
          payload: resp.data,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "payment",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const Subscription = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const paymentDetails = {
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cv: data.cv,
      planId: "61863904f47ee200118d8d6d",
    };

    axios
      .post(
        `${InitialApiRoute}/user/subscription/subscription_plan`,
        paymentDetails,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: SubscriptionPlan,
          payload: resp.data,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "payment",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const ViewSubscription = () => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(
        `${InitialApiRoute}/user/subscription/view_subscription`,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: SubscriptionDetails,
          payload: resp.data,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "payment",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

export { OneTimePayment, Subscription, ViewSubscription };
