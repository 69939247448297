
import { LoadUploadedImage } from "../../services/utilService";

const AttachmentView = ({ file, url }) => {
    switch (file.type) {
        case 'audio':
            return <audio src={LoadUploadedImage(url)} controls="true" download="true" />
        case 'gallery':
            return <a target="_blank" href={LoadUploadedImage(url)} download><img src={LoadUploadedImage(url)} width={"100px"}></img></a>
        default:
            return <a target="_blank" href={LoadUploadedImage(url)} download> {file.filename} </a>

    }
}

export default AttachmentView