import axios from "axios";
import { AcceptOffered, RemoveOffer, CreateOffer, ErrorMessage, SetMyOffers, EmptyMyOffers,UpdateAccepetdTrade,MarkComplete, DeleteMyOffer, AddOffer, FetchingOffers } from "../../actionTypes/ActionTypes";
import { InitialApiRoute } from "../../config/ApiConfig";
import socketService from "../../services/socketService";
import { EmptyErrorMsg } from "../auth";

const GetMyOffers = (filter = {}) => {
    return (dispatch) => {
        dispatch({

            type: FetchingOffers,
            payload: true,
        });

        let queryParams = ""
        Object.keys(filter).map((key, i) => {
            queryParams += (i == 0) ? "?" : "&";
            queryParams += filter[key] ? (key + "=" + filter[key]) : ""
        })

        const ProtectedApiConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        };


        axios
            .get(`${InitialApiRoute}/user/offer/get_all_offers` + queryParams,
                ProtectedApiConfig)
            .then((resp) => {

                dispatch(EmptyErrorMsg())
                dispatch({
                    type: SetMyOffers,
                    payload: resp.data.offers,
                });

                dispatch({
                    type: FetchingOffers,
                    payload: false,
                });
            })
            .catch((err) => {
                dispatch({
                    type: FetchingOffers,
                    payload: false,
                });
                const error = {
                    message: err.response.data.message,
                    call: "globalSearch",
                };
                dispatch({
                    type: ErrorMessage,
                    payload: error,
                });
            });

    };
};

const CreateAnOffer = (data) => {
    return (dispatch) => {

        const ProtectedApiConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        };
        axios
            .post(`${InitialApiRoute}/user/offer/create_offer`, data, ProtectedApiConfig)
            .then((resp) => {
                socketService.SendEmit("offer-notification", resp.data.offer._id)

                dispatch(EmptyErrorMsg())
                dispatch({
                    type: CreateOffer,
                    payload: resp.data.offer,
                });
                dispatch({
                    type: AddOffer,
                    payload: resp.data.offer,
                });

            })
            .catch((err) => {

                const error = {
                    message: err.response.data.message,
                    call: "createOffer",
                };
                dispatch({
                    type: ErrorMessage,
                    payload: error,
                });
            });
    };
};

const AcceptOffer = (data) => {
    return (dispatch) => {
        const ProtectedApiConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        };
        axios
            .post(`${InitialApiRoute}/user/offer/accept_offer`, data, ProtectedApiConfig)
            .then((resp) => {
                dispatch(EmptyErrorMsg())
                dispatch({
                    type: UpdateAccepetdTrade,
                    payload: resp.data.trade,
                });
            })
            .catch((err) => {

                console.log(err)
                const error = {
                    message: err.response.data.message,
                    call: "acceptOffer",
                };
                dispatch({
                    type: ErrorMessage,
                    payload: error,
                });
            });
    };
};


const Delete = (_id) => {
    return (dispatch) => {
        const ProtectedApiConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        };
        axios
            .delete(`${InitialApiRoute}/user/offer/` + _id, ProtectedApiConfig)
            .then((resp) => {
                dispatch(EmptyErrorMsg())
                // dispatch({
                //     type: DeleteMyOffer,
                //     payload: _id,
                // });
                dispatch({
                    type: RemoveOffer,
                    payload: _id,
                });
            })
            .catch((err) => {

                console.log(err)
                const error = {
                    message: err.response.data.message,
                    call: "deleteOffer",
                };
                dispatch({
                    type: ErrorMessage,
                    payload: error,
                });
            });
    };
};
const EmptyMyOfferList = (data) => ({ type: EmptyMyOffers, payload: data });

export { CreateAnOffer, GetMyOffers, AcceptOffer, Delete ,EmptyMyOfferList};