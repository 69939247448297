import { SendingMessage, AddConversation, GetConversation, GetMessage, EmptyConversation, UpdateConversation } from "../actionTypes/ActionTypes";

const initailState = {
  conversations: [],
  messages: [],
  newConversation: [],
  sendingLoader: false,
};

const Chat = (state = initailState, action) => {
  switch (action.type) {
    case GetConversation:
      return { ...state, conversations: action.payload };

    case GetMessage:
      return { ...state, messages: action.payload };

    case AddConversation:
      return { ...state, newConversation: action.payload };

    case EmptyConversation:
      return { ...state, newConversation: [] };

    case UpdateConversation:

      let index = state.conversations.findIndex(x => x["_id"] === action.payload.key);
      let newArray = [...state.conversations];
      newArray[index].latestMessage = action.payload.message;
      var element = newArray[index];
      newArray.splice(index, 1);
      newArray.splice(0, 0, element);

      return {
        ...state,
        conversations: newArray,
      }

    case SendingMessage:
      return {
        ...state,
        sendingLoader: action.payload
      }

    default:
      return state;
  }
};

export default Chat;
