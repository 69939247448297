import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Section, GridContainer, RoundBox } from "../../Component/Containers";
import ProfileHeader from './ProfileHeader'
import AboutMe from "./AboutMe";
import TopSkills from "./TopSkills";
import Ratings from "./../UserRatings";
import Gallery from "./Gallery";
import { DividerX, DividerY } from '../../Component/Divider';
import { useSelector } from "react-redux";
import { size } from "../../Helpers/breakpoint";

const CustomRoundBox1 = styled(RoundBox)``;

const CustomGridContainer = styled(GridContainer)``;

const CustomDiv = styled.div`
	display:grid;
	grid-template-columns:30% 1px auto;


	@media only screen and (max-width: ${size.btwMLT}) {
		& .dividerY{
			width:100%;
			height:1px;
			background:var(--greyLight);
		}
		width:100%;
		margin-inline:auto;
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;

	}
`;

const UserProfile = () => {
  const authState = useSelector((state) => state.Auth);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [galleryEdit, setGalleryEdit] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  useEffect(() => {
    if (authState.errorCall === "updateProfile" || authState.errorCall === "Image") {
      setLoader(false);
      setDeleteLoader(false);
    } else {
      setLoader(false);
      setEdit(false);
      setView(false);
      setGalleryEdit(false);
      setSelectedImage('');
      setDeleteLoader('');
    }
  }, [authState])

  return (
    <section className="profile-section profile-view-mg">
      <div className="container grid-container gc-center">
        <div></div>
        <div>
          <CustomRoundBox1 className="custom_round_box">
            <ProfileHeader />
            <DividerX />
            <CustomDiv>
              <TopSkills loader={loader} setLoader={setLoader} view={view} setView={setView} />
              <DividerY className="dividerY" />
              <div className="aboutGallery">
                <AboutMe loader={loader} setLoader={setLoader} edit={edit} setEdit={setEdit} />
                <Gallery loader={loader} setLoader={setLoader} galleryEdit={galleryEdit}
                  setGalleryEdit={setGalleryEdit} selectedImage={selectedImage} setSelectedImage={setSelectedImage}
                  deleteLoader={deleteLoader} setDeleteLoader={setDeleteLoader} />

              </div>
            </CustomDiv>

          </CustomRoundBox1>
          <CustomRoundBox1 className="custom_round_box">
            <Ratings id={""}></Ratings>
          </CustomRoundBox1>
        </div>
        <div></div>
      </div>
    </section>
  )
}

export default UserProfile;
