import React from 'react';
import styled from "styled-components";

const LoaderContainer = styled.div`
  width: 1.6em;
  height: 1.6em;
  margin:0 auto;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  border: 3px solid #FFFFFF;
  border-top-color: var(--blue-dark);
`;

const Loader = ({ width, height, margin }) => {
  return (
    <LoaderContainer style={{ width: width, height: height, margin: margin }} />
  )
}

export default Loader
