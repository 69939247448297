import "./JoinTheWorld.scss";
import SquarePictureAnim from '../../../Component/SquarePictureAnim'
import tutor from '../../../Assets/Images/Home/tutorManHavingClass.png'
import routesName from '../../../Helpers/routesName'

const JoinTheWorlds = () => {
  const { login } = routesName;

  return (
    <section className='slider-section'>
      <div className="container">
        <div className="slider-content">
          <div>
            <h1 className='title'>Join the World's Leading Bartering System  for Entrepreneurs</h1>
            <div className='sub-title'>Connect with great talent. Build your business. Take your company to the next level.</div>
            <a className='btn btn-capsule bg-blue slider-btn' href={login}>
              <span>Create a Trade</span>
            </a>
          </div>
          <div>
            <SquarePictureAnim picName="tutor" picSrc={tutor} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default JoinTheWorlds;