import React from "react";
import { Button } from "../../../Component/Button";
import { FieldInput } from "../../../Component/FieldInput";
import { P1 } from "../../../Component/Para";
import Loader from "../../../Component/Loader";
import { Formik, Form } from 'formik';
import { AccountFormValidate } from "../../../Validations";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProfile } from "../../../actions/profile";
import styled from "styled-components";
import { Error, FormButtonsDiv } from "../../../Component/Inputs";

// Form Row
const FormRow = styled.div`
margin-block:15px;
`

// submit button
const SubmitButton = styled(Button)`
height:2.2em;
min-height:35px;
display: grid;
place-items: center;
`

// Field Label
const FieldLabel = styled(P1)`
margin-bottom:10px;
`

const AccountForm = ({ profile, loader, setLoader }) => {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.Auth);

	return (
		<div>
			<Formik
				initialValues={{
					userName: profile.userName,
					firstName: profile.firstName,
					lastName: profile.lastName,
				}}
				validationSchema={AccountFormValidate}
				onSubmit={values => {
					setLoader(true);
					dispatch(UpdateProfile(values));
				}}
			>
				{formik => (
					<div>
						<Form autoComplete="off"> {/*Formik Form Import from Formik*/}
							<FormRow>
								<FieldLabel> Enter Username </FieldLabel>
								<FieldInput label="username" name="userName" placeholder="UserName" />
							</FormRow>
							<FormRow>
								<FieldLabel> Enter First Name </FieldLabel>
								<FieldInput label="firstName" name="firstName" placeholder="FirstName" />
							</FormRow>
							<FormRow>
								<FieldLabel> Enter Last Name </FieldLabel>
								<FieldInput label="lastName" name="lastName" placeholder="Last Name" />
							</FormRow>
							{/* <P1> Enter Email </P1>
						<FieldInput defaultValue={profile.email} label="email" name="email" type="email" placeholder="Email" onChange={onChangeHandler} />*/}
							<FormButtonsDiv>
								{
									authState.errorCall === "changePassword" && <Error> <p className="error">{authState.errorRespMsg}</p> </Error>
								}
								<button className="btn btn-capsule bg-blue" type="submit" disabled={loader}> {loader ? <Loader /> : "Save"} </button>
							</FormButtonsDiv>
						</Form>
					</div>
				)}
			</Formik>
		</div>
	)
}

export default AccountForm;
