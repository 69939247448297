import styled from 'styled-components'
import "./Tags.scss";
import { crossSvg_grey } from '../Assets/Images';
import { P4 } from './Para';
import React from 'react';

const Tags = styled.ul``;

const DynTags = styled.ul``;

const StaticTags = ({ list }) => {
  return (
    <Tags className='tags-list'>
      {list && list.map((tag) => {
        return (
          <li className="tag" key={tag} id={tag.toLowerCase()}><P4>{tag}</P4></li>
        )
      })}
    </Tags>
  )
}

const DynamicTags = ({ list, removeItem, removeAll, isClearTags, forInput, tagColor }) => {
  return (
    <React.Fragment>
      {
        list.length > 0 && (
          <DynTags className='tags-list' tagColor={tagColor}>
            {list.map((tag) => {
              return (
                <li className="tag" key={tag}  >
                  <P4>{tag}</P4>
                  <img src={crossSvg_grey} alt="Cross" width='10px' height='10px' id={forInput ? forInput + tag : tag} onClick={(e) => removeItem(e.target.id)} />
                </li>
              )
            })}
            {isClearTags && (
              <li className="tag clearFilter" key={'clearFilter'} id={'clearFilter'.toLowerCase()} onClick={() => removeAll()}>
                <P4>Clear Filters</P4>
              </li>
            )}
          </DynTags>
        )
      }
    </React.Fragment>
  )
}

export {
  StaticTags,
  DynamicTags
}
