import styled from 'styled-components'
import { size } from '../Helpers/breakpoint'

const Button = styled.button`
    font-family: PoppinsRegular;
    font-size: ${({fontSize}) => fontSize ? fontSize : '1em'};
    width: ${({width}) => width ? width : '6.5em'};
    height: ${({height}) => height ? height : '2.6em'};
    border-radius: 22px;
    background-color: var(--blue);
    color: var(--white);
    border: none;
    outline: none;
    cursor: pointer;
    &:hover{
        background-color: var(--blue-dark);
    }
    @media only screen and (max-width: ${size.tablet}) {
		border-radius:18px;
	}

`

const OnlyButton = styled.button`
    font-family: PoppinsRegular;
    font-size: 1em;
    min-width: 5em;
    height:2.2em;
    padding:.5em;
    background-color: var(--white);
    color: var(--blackashgreen);
    border: none;
    outline: none;
    cursor: pointer;
    &:hover{
        background-color:var(--white);
        color: var(--blue);
    }
    @media only screen and (max-width: ${size.tablet}) {
		border-radius:18px;
	}

`



const ButtonOutline = styled.button`
    font-family: PoppinsMedium;
    font-size: 1em;
    border-radius: 22px;
    height:2.2em;
    background-color: var(--transparent);
    color: var(--white);
    border: 1px solid var(--white);
    outline: none;
    cursor: pointer;
    transition:var(--trans_3s);
    &:hover{
        color: var(--blue);
        background-color: var(--white);
        transition:var(--trans_3s);
    }
    @media only screen and (max-width: ${size.tablet}) {
		border-radius:18px;
	}

`

const IconButton = styled.button`
    font-family: PoppinsRegular;
    width: 100%;
    height: 133px;
    font-size: 2em;
    background-color: var(--blue);
    color: var(--white);
    border: none;
    outline: none;
    cursor: pointer;
`

export {Button, IconButton, ButtonOutline, OnlyButton}