import Auth from "./Auth";
import Payment from "./Payment";
import Profile from "./Profile";
import Trade from "./Trade";
import Chat from "./Chat";
import Common from "./Common";
import Offer from "./Offer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  Auth,
  Payment,
  Profile,
  Trade,
  Chat,
  Common,
  Offer,
});

export default rootReducer;
