import { Container, Content, Section } from '../../../Component/Containers'
import { ButtonOutline } from '../../../Component/Button'
import { HeadingLarge } from '../../../Component/Heading'
import { Arrow1, Arrow2, Arrow3, Arrow4 } from '../../../Assets/Images'
import styled from 'styled-components'
import {size} from '../../../Helpers/breakpoint'
import { useNavigate } from "react-router-dom";
import routesName from '../../../Helpers/routesName' 

// Custom Section
const CustomSection = styled(Section)`
    background:var(--blue);
    margin-block:1em;
`
// Custom Container
const CustomContainer = styled(Container)`
    padding-block:0rem;

`

// Custom Content
const CustomContent = styled(Content)`
    height:10em;
    width:100%;
    display:flex;
    justify-content:space-betwen;
    align-items:center;

    &:hover .a1{
        left:4.5em;
        top:-1.2em;
        transform:Rotate(10deg);
        transition:.3s all ease;
    }
    &:hover .a2{
        left:0em;
        bottom:-1.8em;
        transform:Rotate(-8deg);
        transition:.3s all ease;
    }
    &:hover .a3{
        right:1.5em;
        top:-1.2em;
        transform:Rotate(26deg);
        transition:.3s all ease;
    }
    &:hover .a4{
        right:8.25em;
        bottom:-1.3em;
        transform:Rotate(-28deg);
        transition:.3s all ease;
    }

    @media only screen and (max-width: ${size.tablet}){
        & div{
            display:none;
        }
        & CustomButtonOutline{
            font-size:1em;
            padding:.5em 0em;

        }

    }
`

// Title
const Title = styled(HeadingLarge)`
        font-size:3.1em;
        flex:1;
        line-height:1.2em;
        color:var(--white);
        @media only screen and (max-width: ${size.tablet}){
            font-size:2.5em;
            flex:auto;
        }
    `

// CustomDiv (Arrows Div)
const CustomDiv = styled.div`
        flex:1;
        height:100%;
        position:relative;
        display:grid;
        place-items:center;
        
        & img{
            position:absolute;
            width:9em;
            transition:.3s all ease;
        }
        & .a1{
            left:2.5em;
            top:-1.9em;
        }
        & .a2{
            bottom:-2.4em;
            left:-2em;
        }
        
        & .a3{
            top:-1.8em;
            right:3.5em;
        }
        
        & .a4{
            bottom:-1.8em;
            right:10.25em;
        }

        

        
        `

// Outlined Button
const CustomButtonOutline = styled(ButtonOutline)`
    height:2.6em;
    width:auto;
    padding:0em 2em;

    @media only screen and (max-width: ${size.tablet}){
        font-size:1.2em;
    }
`


const GetStarted = () => {
    //Navigations 
	const goto = useNavigate();
    const { login } = routesName;

    return (
        <CustomSection>
            <CustomContainer>
                <CustomContent>
                    <Title>Get Started by <br /> Finding a Trade</Title>
                    <CustomDiv>
                            <img src={Arrow1} className='a1' alt="arrow" width="" height="" />
                            <img src={Arrow2} className='a2' alt="arrow" width="" height="" />
                            <img src={Arrow3} className='a3' alt="arrow" width="" height="" />
                            <img src={Arrow4} className='a4' alt="arrow" width="" height="" />
                    </CustomDiv>
                    <CustomButtonOutline onClick={() => goto(login) }>Find Trades</CustomButtonOutline>

                </CustomContent>
            </CustomContainer>
        </CustomSection>

    )
}

export default GetStarted;