
import { iconsSprite } from "../Assets/Images";

const EditIcon = ({ whenClick, isEdit }) => {
  return (
    <button className={'btn btn-outline bg-blue btn-round svgOnHover edit-icon' + (isEdit ? ' active' : '')} onClick={whenClick} isEdit={isEdit}>
      <svg className={'svg icon-blue '}>
        <use href={iconsSprite + (isEdit ? '#cancel-icon' : '#edit-icon')} />
      </svg>
    </button>
  )
}

export default EditIcon