const InitialApiRoute = process.env.REACT_APP_URL;

const ApiConfig = {
  "Content-Type": "application/json",
};

const ProtectedApiConfig = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export { ApiConfig, InitialApiRoute, ProtectedApiConfig };
