import axios from "axios";
import {
  UserProfile,
  ErrorMessage,
  SkillsList,
  ViewProfileDetails,
  ProfilePicture,
  SetUserRating, Notifications, GotNotification, UpdateNotifcation, SetCanMessage, MarkComplete
} from "../../actionTypes/ActionTypes";
import { InitialApiRoute } from "../../config/ApiConfig";
import { EmptyErrorMsg } from "../auth";

const GetProfile = (user) => {
  return (dispatch) => {

    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(
        `${InitialApiRoute}/user/profile/get_profile/${user}`,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch({
          type: UserProfile,
          payload: resp.data.user,
        });
      });
  };
};

const UpdateProfile = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .put(
        `${InitialApiRoute}/user/profile/profile_update`,
        data,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: UserProfile,
          payload: resp.data.saved,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "updateProfile",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};


const UpdateImage = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": 'multipart/form-data'
      },
    };

    let formData = new FormData()
    formData.append('uploadimage', data);

    axios
      .post(
        `${InitialApiRoute}/user/profile/upload_image`,
        formData,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({

          type: ProfilePicture,
          payload: resp.data.image,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "updateProfilePicture",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const ChangePassword = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .put(
        `${InitialApiRoute}/user/profile/change_password`,
        data,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        // dispatch({
        //   type: UserProfile,
        //   payload: resp.data.saved,
        // });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "changePassword",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const UpdateGallery = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        `${InitialApiRoute}/user/profile/upload_multipleImages`,
        data,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: UserProfile,
          payload: resp.data,
        });
      })
      .catch((_err) => {
        const error = {
          message: "",
          call: "Image",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};



const GetAllSkills = () => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(`${InitialApiRoute}/skill/user/all_skills`, ProtectedApiConfig)
      .then((resp) => {
        dispatch({
          type: SkillsList,
          payload: resp.data,
        });
      });
  };
}

const GetNotifications = () => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(`${InitialApiRoute}/user/profile/notifications`, ProtectedApiConfig)
      .then((resp) => {

        dispatch({
          type: Notifications,
          payload: resp.data,
        });
      });
  };
};


const GetUserRatings = (id) => {
  return (dispatch) => {

    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(`${InitialApiRoute}/user/rate/get_ratings_user` + (id ? ("/" + id) : ""), ProtectedApiConfig)
      .then((resp) => {

        dispatch({
          type: SetUserRating,
          payload: resp.data.rating,
        });
      });
  };
};

const DeleteGallery = (imgUrl) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const body = {
      imageUrl: imgUrl
    }
    axios
      .post(
        `${InitialApiRoute}/user/profile/delete_image`,
        body,
        ProtectedApiConfig,
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: UserProfile,
          payload: resp.data.updatedProfile
          ,
        });
      })
      .catch((_err) => {
        const error = {
          message: "",
          call: "Image",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const OtherUserProfile = (id) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(
        `${InitialApiRoute}/user/profile/get_profile/${id}`,
        ProtectedApiConfig
      )
      .then((resp) => {

        dispatch({
          type: ViewProfileDetails,
          payload: resp.data.user,
        });
        dispatch({
          type: SetCanMessage,
          payload: resp.data.canMessage
        });
      });
  };
};

const ReceivedNotification = (data) => {
  return (dispatch) => {

    dispatch({
      type: GotNotification,
      payload: data,
    });

  };
};
const UpdateNotificationStatus = (_id, data) => {
  return (dispatch) => {
    dispatch({
      type: UpdateNotifcation,
      payload: { _id, data }
    });

  };
};

const InviteUser = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        `${InitialApiRoute}/user/invite/create`,
        data,
        ProtectedApiConfig
      )
      .then((resp) => {
        dispatch(EmptyErrorMsg());
        dispatch({
          type: "Invite_User",
          payload: resp.data,
        });
      })
      .catch((_err) => {
        const error = {
          message: "",
          call: "Invite_User",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const RateATrade = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${InitialApiRoute}/user/rate/rate_trade`, data, ProtectedApiConfig)
      .then((resp) => {

        dispatch({
          type: MarkComplete,
          payload: resp.data.trade,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "rate_trade",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

export {
  GetProfile,
  UpdateProfile,
  ChangePassword,
  UpdateGallery,
  GetAllSkills,
  DeleteGallery,
  OtherUserProfile,
  UpdateImage,
  GetUserRatings,
  GetNotifications,
  ReceivedNotification,
  UpdateNotificationStatus,
  RateATrade
};
