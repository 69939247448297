import {
    GlobalSearch,
    SetSearchedUsers,
    SetSearchedTrades,
    EmptyErrorResponse,
} from "../actionTypes/ActionTypes";

const initailState = {
    searchUsers: [],
    searchTrades: []
};

const Auth = (state = initailState, action) => {

    switch (action.type) {

        case SetSearchedUsers:
            return { ...state, searchUsers: action.payload };
        case SetSearchedTrades:
            return { ...state, searchTrades: action.payload };

        case EmptyErrorResponse:
            return { ...state, errorCall: "", errorRespMsg: "" };
        default:
            return state;
    }
};

export default Auth;
