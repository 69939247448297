import React, { useEffect } from 'react';
import { Container } from '../../Component/Containers';
import Loader from '../../Component/Loader';
import styled from 'styled-components';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { PostGoogleAuth } from '../../actions/auth';

const CustomContainer = styled(Container)`
    display: grid;
    place-items: center;
`

const LoadingPage = () => {
  const authState = useSelector((state) => state.Auth)
  const goto = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let getURL = window.location.href;
    const callBackURL = getURL.split(process.env.REACT_APP_FRONEND_URL + "/callback?");
    dispatch(PostGoogleAuth(callBackURL[1]))
  }, [dispatch])

  useEffect(() => {
    authState.auth && goto("/dashboard");
  }, [authState.auth, goto])

  return (
    <CustomContainer>
      <Loader width="100px" height="100px" />
    </CustomContainer>
  )
}

export default LoadingPage
