import { AddTags } from './SearchSelect';
// import countries from '../Helpers/countries';
// import { Input } from './Inputs';
import { DividerX } from './Divider';
import { P1 } from './Para';
import { DynamicTags } from './Tags';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateProfile } from '../actions/profile';
import { size } from "../Helpers/breakpoint";
import Loader from './Loader';


const Modal = ({ title, setView, skills, loader, setLoader }) => {
  const [selectedSkills, setSelectedSkills] = useState([])
  const [skillTag, setSkilTag] = useState(null);
  const dispatch = useDispatch();
  const profile = useSelector(state => state.Profile.userProfile);

  useEffect(() => {
    setSelectedSkills(profile.tags)
  }, [profile])
  // Remove Item
  const removeItem = (id) => {
    const filter = selectedSkills.filter((item) => item !== id)
    setSelectedSkills(filter)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (selectedSkills?.length !== 0) {
      setLoader(true);
      const selectedTags = {
        tags: [...selectedSkills]
      };
      dispatch(UpdateProfile(selectedTags));
    }
  }

  return (
    <div className='modal'>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 lh='auto' fontSize='1.25em'>{title}</h2>
        </div>
        <div className='p-0'>
          <DividerX />
        </div>
        <div className="modal-content">
          <div className="addTags spacing">
            <AddTags label="skills" name="skills" placeholder="Search for Tags" value={skillTag} onChange={(e) => setSkilTag(e.target.value)} options={skills} />
            <button className='btn btn-capsule bg-blue' onClick={() => {
              let isExist = selectedSkills.includes(skillTag);
              let isValid = skills.filter((v) => v.skillName === skillTag);
              !isExist && isValid[0] && (selectedSkills.length < 5) &&
                setSelectedSkills([...selectedSkills, skillTag])
              setSkilTag('')
            }}>Add</button>
          </div>
          <div className="spacing">
            <P1 fontSize='.9em' >List your top 5 skills and services that you would like to offer.</P1>
          </div>
          <div className="spacing">
            {selectedSkills?.length > 0 && (
              <DynamicTags list={selectedSkills} removeItem={removeItem} tagColor="whiteF0" />
            )}
          </div>
        </div>
        <div className='p-0'>
          <DividerX />
        </div>
        <div className="modal-footer">
          <button className='btn btn-outline btn-capsule bg-charcoal-300' onClick={() => setView(false)}>Cancel</button>
          <button className='btn btn-capsule bg-blue' onClick={(e) => onSubmitHandler(e)} disabled={loader}>{loader ? <Loader margin="auto" /> : "Save"}</button>
        </div>
      </div>
      <div className="blur"></div>
    </div>
  )
}

export default Modal;
