import JoinTheWorlds from './JoinTheWorlds'
import FindYourBartering from './FindYourBartering'
import HowDoesItWork from './HowDoesItWork'
import GetStarted from './GetStarted'
import MeetTheTraders from './MeetTheTraders'
import WhatTheyAreSaying from './WhatTheyAreSaying'
import TradeWithAPro from './TradeWithAPro'
import PricingPlans from './PricingPlans'

const Home = () => {
    return (
        <div>
            <JoinTheWorlds />
            <FindYourBartering />
            <HowDoesItWork />
            <GetStarted />
            <MeetTheTraders />
            <WhatTheyAreSaying />
            <TradeWithAPro />
            <PricingPlans />
        </div>
    )
}

export default Home;