import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import SignUp from "../Pages/SignUp";
import Login from "../Pages/Login";
import TestComp from "../TestComp";
import ErrorPage from "../Pages/Error/ErrorPage";
import Dashboard from "../Pages/Dashbaord";
import UserProfile from "../Pages/UserProfile";
import ProfileSetting from "../Pages/ProfileSetting";
import routesName from "./routesName";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import LoadingPage from "../Pages/GoogleRedirectLoading";
import PricingAndPlan from "../Pages/Pricing";
import StripeForm from "../Pages/StripeForm";
import CreateTrade from "../Pages/Trade/CreateTrade";
import Messages from "../Pages/Messages";
import ViewProfile from "../Pages/ViewProfile";
import ViewTrade from "../Pages/Trade/ViewTrade";
import MyTrades from "../Pages/Trade/MyTrades";
import MyOffers from "../Pages/Offers/MyOffers";

const Routers = () => {
  // Routes name coming from "routesName.js"
  const {
    root,
    login,
    signUp,
    dashboard,
    profile,
    notFound,
    googleCallback,
    pricing,
    payment,
    createTrade,
    messages,
    viewProfile,
    viewTrade,
    myTrades,
    myOffers
  } = routesName;
  return (
    <Routes>
      {/* <Route path="/*" element={<Home />} /> */}
      {/* Public Routes */}
      {/* <PublicRoute path={root} element={<Home />} />
      <PublicRoute path={login} element={<Login />} />
      <PublicRoute path={signUp} element={<SignUp />} />
      <PublicRoute path={googleCallback} element={<LoadingPage />} /> */}
      <Route path={root} element={<PublicRoute />}>
        <Route path={root} element={<Home />} />
      </Route>
      <Route path={login} element={<PublicRoute />}>
        <Route path={login} element={<Login />} />
      </Route>
      <Route path={signUp} element={<PublicRoute />}>
        <Route path={signUp} element={<SignUp />} />
      </Route>
      <Route path={googleCallback} element={<PublicRoute />}>
        <Route path={googleCallback} element={<LoadingPage />} />
      </Route>

      {/* Protected Routes */}
      <Route path={dashboard} element={<ProtectedRoute />}>
        <Route path={dashboard} element={<Dashboard />} />
      </Route>
      <Route path={createTrade} element={<ProtectedRoute />}>
        <Route path={createTrade} element={<CreateTrade />} />
      </Route>
      <Route path={profile.root} element={<ProtectedRoute />}>
        <Route path={profile.root} element={<UserProfile />} />
      </Route>
      <Route path={profile.setting} element={<ProtectedRoute />}>
        <Route path={profile.setting} element={<ProfileSetting />} />
      </Route>
      <Route path={pricing} element={<ProtectedRoute />}>
        <Route path={pricing} element={<PricingAndPlan />} />
      </Route>
      <Route path={payment} element={<ProtectedRoute />}>
        <Route path={":trade"} element={<StripeForm />} />
        <Route path="" element={<StripeForm />} />
        {/* <Route path={payment} element={<StripeForm />} /> */}
      </Route>

      <Route path={messages} element={<ProtectedRoute />}>
        <Route path={":id"} element={<Messages />} />
        <Route path="" element={<Messages />} />
      </Route>
      <Route path={viewProfile} element={<ProtectedRoute />}>
        <Route path={viewProfile} element={<ViewProfile />} />
      </Route>

      <Route path={viewTrade} element={<ProtectedRoute />}>
        <Route path={viewTrade} element={<ViewTrade />} />
      </Route>
      <Route path={myOffers} element={<ProtectedRoute />}>
        <Route path={myOffers} element={<MyOffers />} />
      </Route>
      <Route path={myTrades} element={<ProtectedRoute />}>
        <Route path={myTrades} element={<MyTrades />} />
      </Route>


      {/* <ProtectedRoute path={dashboard} element={<Dashboard />} />
      <ProtectedRoute path={createTrade} element={<CreateTrade />} />
      <ProtectedRoute path={profile.root} element={<UserProfile />} />
      <ProtectedRoute path={profile.setting} element={<ProfileSetting />} />
      <ProtectedRoute path={pricing} element={<PricingAndPlan />} />
      <ProtectedRoute path={payment} element={<StripeForm />} />
      <ProtectedRoute path={messages} element={<Messages />} />
      <ProtectedRoute path={viewProfile} element={<ViewProfile />} /> */}

      {/* Dialog testing */}
      <Route exact path="test" element={<TestComp />} />

      {/* Error Page */}
      <Route path={notFound} element={<ErrorPage />} />
    </Routes>
  );
};

export default Routers;
