import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { LogOut } from '../actions/auth'
import routesName from '../Helpers/routesName'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ProfileImage, notificationIcon, notificationIconBlue } from '../Assets/Images';
import { LoadUploadedImage } from '../services/utilService';
import { useSelector } from 'react-redux';
import GlobalSearchView from './GlobalSearchView';
import useOutsideAlerter from '../shared/BlurHandler';
import { UpdateNotificationStatus } from "../actions/profile";
import { DividerX } from './Divider';


const Hamburger = styled.div`

`;

const SideMenu = styled.div`
		height: 100%;
		width: 0;
		position: fixed;
		z-index: 1;
		top: 77px;
		right: 0;
		background-color: var(--blue);
		overflow-x: hidden;
		transition: 0.5s;
		display: grid;
        place-items: center;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;

            & .imageBtn{
              height:auto;
            }
        }
        input-container {
            width: 50%;
        }
`;

const HamurgerComp = () => {

  const [toggle, setToggle] = useState(false);
  let userProfile = useSelector(state => state.Profile.userProfile);

  const goto = useNavigate()
  const { profile, root } = routesName
  const dispatch = useDispatch();
  let [tradeToggleMenu, setTradeToggle] = useState(false);
  let notifications = useSelector(state => state.Profile.notifications);

  let [notificationToggle, setToggleNotification] = useState(false);
  const notifRef = useRef(null);
  useOutsideAlerter(notifRef, setToggleNotification, false);

  const toggleHamburger = () => {
    var hamburger = document.querySelector("#hamburger");
    var sidebar = document.querySelector("#mySidenav")
    hamburger.classList.toggle('close');
    sidebar.classList.toggle('active');
  }
  function UpdateNotificationsSeenStatus(notif) {
    notif.unseen = false
    setToggleNotification(false)
    dispatch(UpdateNotificationStatus(notif._id, notif))
    ViewTrade(notif.tradeId._id)

  }
  function ViewTrade(_id) {
    goto(`/view-trade/${_id}`)
  }

  return (
    <>
      <Hamburger id="hamburger" className={toggle ? 'close-btn' : ''} onClick={() => toggleHamburger()}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </Hamburger>
      <SideMenu id="mySidenav">
        <div className="container">
          <button className='btn btn-round profile-btn' onClick={() => {
            toggleHamburger()
            goto(profile.root);
          }}><img src={LoadUploadedImage(userProfile.image)} onError={(e) => { e.target.src = LoadUploadedImage("") }} />
          </button>
          <div className="input-container" style={{ "position": 'relative' }}>
            <div className='hamburger-dropdown'>
              <GlobalSearchView toggle={toggleHamburger}></GlobalSearchView>
            </div>
          </div>
          <a className="bell-item notif" ref={notifRef}>
            {notifications.filter(x => x.unseen).length ? <span className="notif-count no-count"></span> : ''}
            <button className="bell-icon btn-round" onClick={() => setToggleNotification(!notificationToggle)} > <img src={notificationIconBlue} alt="" width="100%" height="auto" /></button>
            {

              notificationToggle ? <div className='dropdown center'>
                <h3>Notifications</h3>

                {notifications.length ? notifications.map((notif, ind) => {
                  return (
                    <div>
                      <a className='' onClick={(e) => { UpdateNotificationsSeenStatus(notif) }}>
                        <div key={'_' + ind} >{notif.type == 'invite' ? ("You have been invited for a trade by " + (notif.invitedBy ? notif.invitedBy.userName : 'user')) : "You have a new offer for your Trade " + notif.tradeId.tradeName}
                          {/* <p>{notif.tradeId.tradeDescription.substring(0, 50)}</p> */}
                          {/* <button className='btn btn-link text-blue' }>See now</button> */}
                        </div>

                      </a>
                      <DividerX />
                    </div>
                  )

                }) : "Not offered or invited yet"}

              </div>
                : ""}
          </a>
          <a onClick={() => setTradeToggle(!tradeToggleMenu)}>My Trades</a>
          {tradeToggleMenu ?
            <div>
              <a onClick={() => { setTradeToggle(false); toggleHamburger(); goto(routesName.myTrades) }}>
                <span>My Trades</span>
              </a>
              <a onClick={() => { setTradeToggle(false); toggleHamburger(); goto(routesName.myOffers) }}>
                <span>My Offers</span>
              </a>
            </div> : ""
          }
          <a onClick={() => {
            toggleHamburger()
            goto(routesName.messages)
          }}>Messages</a>
          <button className='btn btn-capsule bg-blue' onClick={() => {
            dispatch(LogOut());
            goto(root);
          }}>Logout</button>
        </div>
      </SideMenu>
    </>
  )
}

export default HamurgerComp
