// Auth
const UserDetail = "User_Detail";
const ErrorMessage = "Error_Message";
const Logout = "Log_Out";
const ChangeAuth = "Change_Auth";

// Payment
const SubscriptionPlan = "Subscription_Plan";
const SubscriptionDetails = "Subscription_Details";
const OneTimePlan = "One_Time_Plan";

// User Profile
const UserProfile = "User_Profile";
const ProfilePicture = "User_Image";
const EmptyErrorResponse = "Empty_Error_Response";
const SetUserRating = "Set_User_Rating";
const ViewProfileDetails = "View_Profile_Details"
const SetCanMessage = "Can_Message_Privilege"

//Notifications
const Notifications = "Invite_Notifications"
const GotNotification = "Received_Invite_Notifications"
const UpdateNotifcation = "Update_Invite_Notifications"

// Trade
const TradeList = "Trade_List";
const UpdateAccepetdTrade = "Update_Trade_After_Offer_Accept";
const MyTrades = "My_Trades";
const OpenTrade = "Open_Trade";
const AddTrade = "add_trade";
const RateTrade = "rate_trade";
const EmptyMyTrades = "Clear_Mytrade_list";
const EmptyTradeList = "Clear_trade_list";
const DeleteTrade = "Delete_Trade"
const RemoveOffer = "Remove_Trade_Offer"
const AcceptOffered = "Accept_Other_Offer"
const FetchingTrades = "Fetching_Trades"
const MarkComplete = "Complete_Trade"
const TradeUnsaved = "Trade_Uncreated_State"


// SKill
const SkillsList = "Skills_List";

// Chat
const GetConversation = "Get_Conversation";
const AddConversation = "Add_Conversation";
const GetMessage = "Get_Message";
const AddNewMessage = "Add_New_Message";
const EmptyConversation = "Empty_Conversation";
const UpdateConversation = "Update_Conversation";
const SendingMessage = "Sending_Loader";

// Offers 

const SetMyOffers = "Set_My_Offers"
const CreateOffer = "Create_Offer"
const DeleteMyOffer = "Delete_Offer"
const AddOffer = "Add_Offer"
const FetchingOffers = "Fetching_Offers"
const EmptyMyOffers = "Clear_offer_list";


//common
const SetSearchedTrades = "Set_Searched_Trades"
const SetSearchedUsers = "Set_Searched_Users"
const EmptySearch = "Set_Empty"

export {
  UserDetail,
  ErrorMessage,
  Logout,
  ChangeAuth,
  SubscriptionPlan,
  UserProfile,
  EmptyErrorResponse,
  TradeList,
  SkillsList,
  MyTrades,
  AddTrade,
  GetConversation,
  UpdateConversation,
  GetMessage,
  AddNewMessage,
  AddConversation,
  OpenTrade,
  OneTimePlan,
  ViewProfileDetails,
  SubscriptionDetails,
  EmptyConversation,
  ProfilePicture,
  SetSearchedTrades,
  SetSearchedUsers,
  EmptySearch,
  SetMyOffers,
  CreateOffer,
  SetUserRating,
  Notifications,
  GotNotification,
  UpdateNotifcation,
  RateTrade,
  UpdateAccepetdTrade,
  MarkComplete,
  SetCanMessage,
  EmptyMyTrades,
  EmptyTradeList,
  DeleteMyOffer,
  DeleteTrade,
  AddOffer,
  RemoveOffer,
  AcceptOffered,
  FetchingTrades,
  FetchingOffers,
  EmptyMyOffers,
  SendingMessage,
  TradeUnsaved
};
