import {
  UserDetail,
  ErrorMessage,
  Logout,
  ChangeAuth,
  EmptyErrorResponse,
} from "../actionTypes/ActionTypes";

const initailState = {
  auth: "false",
  userDetails: "",
  errorCall: "",
  errorRespMsg: "",
};

const Auth = (state = initailState, action) => {
  switch (action.type) {
    case UserDetail:
      return { ...state, auth: true, userDetails: action.payload };
    case ErrorMessage:
      return {
        ...state,
        errorCall: action.payload.call,
        errorRespMsg: action.payload.message,
      };
    case Logout:
      localStorage.clear();
      return { ...state, auth: false };
    case ChangeAuth:
      return { ...state, auth: action.payload };
    case EmptyErrorResponse:
      return { ...state, errorCall: "", errorRespMsg: "" };
    default:
      return state;
  }
};

export default Auth;
