import styled from "styled-components";
import EditIcon from "../../Component/EditIcon";
import { H2 } from "../../Component/Heading";
import {
  Button,
  OnlyButton
} from "../../Component/Button";
import {
  useEffect,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteGallery, UpdateGallery } from "../../actions/profile";
import Loader from "../../Component/Loader";
import { LoadUploadedImage } from "../../services/utilService";
import ImagePreview from "../../shared/ImagePreview";
// import { deleteIcon } from "../../Assets/Images";

const CustomDiv = styled.div`
    width:100%;
    padding:2em 1em;
    & .viewMore{
        padding-left:8px;
        color:var(--blue);
        &:hover{
            color:var(--blue-dark);
        }
    }
`

const Galleries = styled.ul`
`

const Item = styled.li`
    flex-basis:30%;
    background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    // background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    background-size:cover;
    background-repeat:no-repeat;
    height:9.35em;
    border-radius:15px;
    margin:.5em;
`

const Gallery = ({ loader, setLoader, galleryEdit, setGalleryEdit, selectedImage, setSelectedImage, deleteLoader, setDeleteLoader }) => {

  const [end, setEnd] = useState(3);
  const [viewMore, setViewMore] = useState(false);

  const imageUpload = useRef()
  const dispatch = useDispatch();
  const profile = useSelector(state => state.Profile.userProfile);
  const [previewImage, setPreviewImage] = useState("");
  const onSelectImageHandler = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append('uploadimage', selectedImage);
    dispatch(UpdateGallery(formData));

  }

  useEffect(() => {
    HandleImageView()
  }, [
    profile.portfolioImages
  ])

  const onDeleteHandler = (val, index) => {

    setDeleteLoader(index);
    dispatch(DeleteGallery(val));
  }

  const editClickHandle = () => {
    setGalleryEdit(!galleryEdit);
    HandleImageView(viewMore, !galleryEdit)
  }

  const HandleImageView = (viewMore, galleryEdit) => {

    if (!viewMore) {
      galleryEdit ? setEnd(2) : setEnd(3)
    }
    else setEnd(profile.portfolioImages?.length)
  }

  return (
    <CustomDiv>
      <div>
        <H2>Portfolio</H2>
        <EditIcon whenClick={() => editClickHandle()}
          isEdit={galleryEdit} />
      </div>
      <div className="profile-galleries">
        {galleryEdit &&
          <div onClick={(e) => { e.stopPropagation(); imageUpload.current.click() }}>
            <Item className="item upload" key={'_'} >
              <input className='uploader' type="file" ref={imageUpload} accept="image/*" onChange={onSelectImageHandler} />
              <p>{selectedImage ? selectedImage.name : "Choose Image"}</p>
              {selectedImage && <button className="btn btn-capsule bg-blue" onClick={(e) => {e.stopPropagation(); onSubmitHandler(e)}}
                disabled={loader}> {loader ? <Loader width="17px" height="17px" /> : "Upload"} </button>}
            </Item>
          </div>}


        {
          profile.portfolioImages?.reverse().slice(0, end).map((val, ind) => {
            // let trimSrc = val.replace(" ", "%20");
            return (
              val ? < Item key={'_' + ind} image={LoadUploadedImage(val)} onClick={(e) => { setPreviewImage(val) }}>

                {galleryEdit && <div className="btn">
                  {
                    deleteLoader === ind ? <Loader width="17px" height="17px" margin="0px" /> :
                      <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); onDeleteHandler(val, ind) }}>
                        <svg id="Group_485" data-name="Group 485" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <g id="Ellipse_41" data-name="Ellipse 41" fill="none" stroke="#33b0f2" stroke-width="1">
                            <circle cx="13" cy="13" r="13" stroke="none" />
                            <circle cx="13" cy="13" r="12.5" fill="none" />
                          </g>
                          <g id="_x36_" transform="translate(4.527 6.6)">
                            <path id="Path_370" data-name="Path 370" d="M13.351,1.585h-2.38V1.053A1.027,1.027,0,0,0,9.974,0h-3a1.028,1.028,0,0,0-1,1.053v.532H3.595a.641.641,0,0,0-.622.657V3.429a.641.641,0,0,0,.622.657h.129v7.663a1.026,1.026,0,0,0,1,1.052h7.505a1.028,1.028,0,0,0,1-1.053V4.086h.129a.641.641,0,0,0,.621-.657V2.241A.641.641,0,0,0,13.351,1.585ZM6.972.521h3a.519.519,0,0,1,.5.532v.532H6.468V1.053A.519.519,0,0,1,6.972.521Zm5.253,11.758H4.72a.518.518,0,0,1-.5-.531V4.086h8.512v7.662A.519.519,0,0,1,12.225,12.279Zm1.254-8.85a.132.132,0,0,1-.128.136H3.595a.132.132,0,0,1-.128-.136V2.241a.132.132,0,0,1,.128-.136h9.756a.132.132,0,0,1,.128.136V3.429ZM8.226,5.061H8.72v6.028H8.226V5.061Zm-2.115,0H6.6v6.028H6.111Zm4.23,0h.494v6.028h-.494Z" fill="#33b0f2" />
                          </g>
                        </svg>


                        {/* <img src={LoadUploadedImage(val)} width="100" height="50" /> */}
                      </div>
                    // <img src={deleteIcon} width='40' height='40'  onClick={() => onDeleteHandler(val, ind)} />
                  }
                </div>
                }
              </Item> : ""
            )
          })
        }
        {previewImage ? <ImagePreview setOpen={setPreviewImage} gallery={[previewImage]}></ImagePreview> : ""}
      </div>
      {(profile.portfolioImages?.length > 2) ? (!viewMore ? <button className="btn btn-capsule btn-outline bg-blue" onClick={() => { setViewMore(true); HandleImageView(true, galleryEdit) }}>View More</button> : <button className="btn btn-capsule btn-outline bg-blue" onClick={() => { setViewMore(false); HandleImageView(false, galleryEdit) }}>View Less</button>) : ""}
    </CustomDiv >
  )
}

export default Gallery