import "../Offers.scss";
import { iconsSprite } from "../../../Assets/Images";
import styled from "styled-components";
import { H1, H2, Heading5 } from "./../../../Component/Heading";
import { P1, P2 } from "./../../../Component/Para";

import { DividerX } from "./../../../Component/Divider";
import { StaticTags } from "./../../../Component/Tags";
import { ProfileImage } from "../../../Assets/Images";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";

import moment from 'moment';
import { useEffect, useState } from "react";
import AcceptOfferComp from "../AcceptOffer";
import DeleteOfferComp from "../DeleteOfferComp";
import { LoadUploadedImage } from "../../../services/utilService";
import { RoundImage } from "../../../Component/Images";
import { pinIcon } from "../../../Assets/Images";

const OfferItem = styled.div`
    margin-block:.5em;
    cursor:pointer;
    & .para2{
        margin-top:1em;
    }
    & .for{
        margin-top:1.5em;
        h5{
            color:var(--blackashgreen);
            margin-bottom:.5em;
        }
    }
`
// FormRowButton
const FormRowButton = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	gap:1em;

`

const AboutTrade = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:10px;
    margin-block:1.6em 1em;
`


const OfferInfo = ({ data, showAccept, isUsers, alwaysShow, showUserInfo, setOpen, setText, setGreetings }) => {
  const { offerTitle, offerDescription, offerTradeTags, offerCreatedDate, tradeId, userId, offerStatus } = data
  const [showOffer, setShowOffer] = useState(false)
  const user = data.userId
  const goto = useNavigate();
  const onTradeViewHandler = (e, trade) => {
    goto(`/view-trade/${tradeId}`);
  }


  const navigateUser = (e) => {
    goto(isUsers ? ("/user-profile") : (`/view-profile/${user._id}`))
  }

  return (
    <div>
      <div className="offers-item" onClick={(e) => { if (!showUserInfo) onTradeViewHandler(e, tradeId) }} onMouseEnter={(e) => { setShowOffer(true) }} onMouseLeave={(e) => { setShowOffer(false) }}>
        <div className="about-offers">
          <div>
            <h2>{offerTitle}</h2>
          </div>
          <div className="offers-status">
            <ul>
              <li className={"status-" + offerStatus}><p>{offerStatus}</p></li>
              <li><p>Posted:</p></li>
              <li><p>{moment(offerCreatedDate).fromNow()}</p></li>
            </ul>
          </div>
        </div>
        <DividerX color="blackOp010" />
        <div className="offers-info">
          <p style={{ "white-space": "pre-wrap", "wordBreak": "break-word" }}>{offerDescription}</p>
          {showOffer || alwaysShow ?
            <div className="exchangeFor for">
              <Heading5>In Exchange For</Heading5>
              <StaticTags list={offerTradeTags} />
            </div> : ""
          }
          {showUserInfo ?
            <div className='user-info' onClick={(e) => { e.stopPropagation(); navigateUser(e) }}>
              <div className='proImage'>
                <RoundImage src={LoadUploadedImage(user.image)} onError={(e) => { e.target.src = LoadUploadedImage("") }} width="150px" />
              </div>
              <div className="info">
                <H1 color="blue">{user?.firstName} {user?.lastName}</H1>
                {user ? (
                  <span>
                    <img src={pinIcon} alt="Pin" width="" height="" />
                    <P1>{(user.state ? user.state : "") + (user.country ? (", " + user.country) : "")}</P1>
                  </span>
                ) : ""}
              </div>
            </div>
            : ""}
        </div>
      </div>
      <FormRowButton>
        {showUserInfo ? <button className="btn btn-outline btn-capsule bg-charcoal-300" type="button" onClick={() => goto(-1)}>Go Back</button> : ""}
        {showAccept ? <AcceptOfferComp _id={data._id} setOpen={setOpen} setText={setText} setGreetings={setGreetings} /> : ""}
        {isUsers ? <DeleteOfferComp _id={data._id} setOpen={setOpen} setText={setText} setGreetings={setGreetings} /> : ""}
      </FormRowButton>

    </div>
  )
}
export default OfferInfo