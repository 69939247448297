import styled from "styled-components";
import { Button } from "../../Component/Button";
import { useNavigate } from "react-router-dom";
import UserWithInfo from "./UserWithInfo";
import { useDispatch } from "react-redux";
import InviteModal from "./InviteUser";
import { useState } from "react";
import routesName from "../../Helpers/routesName";

const CustomButton = styled(Button)`
    width:auto;
    padding-inline:1.6em;
    font-size:.8em;
    align-self:flex-start;
    &:hover{
        background-color: var(--blue-dark);
    }
`
const CustomDiv = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:1em 2em;

    & .button{
        display:flex;
        flex-direction:column;
        gap:.5em;

        & button{
            width:9.5em;
        }
    }

`


const ProfileHeader = ({ friendId, userDetail, canMessage, showInvite, showMessage }) => {

  const { messages, viewProfile } = routesName;
  const goto = useNavigate();
  const dispatch = useDispatch();
  const [inviteModalState, setInviteModalState] = useState(false);
  const [loader, setLoader] = useState(false);

  const onMessageHandler = () => {
    goto(messages + '/' + friendId)
  }

  return (
    <CustomDiv id="view-profile-header">
      <div onClick={(e) => { goto(`/view-profile/${userDetail._id}`) }}>
        <UserWithInfo userDetail={userDetail} />
      </div>
      <div className="button" id="profile-info-btn-container">
        {(canMessage && showMessage) ? <button className="btn btn-capsule bg-blue" onClick={() => onMessageHandler()}>Message</button> : ""}
        {showInvite ? <button className="btn btn-capsule bg-blue" onClick={() => setInviteModalState(!inviteModalState)}>Invite</button> : ""}
        {inviteModalState && userDetail && <InviteModal otherUserId={userDetail._id} title='Select Trade' loader={loader} setLoader={setLoader} setView={setInviteModalState} />}

      </div>
    </CustomDiv>
  )
}

export default ProfileHeader