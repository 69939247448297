import axios from "axios";
import {
  UserDetail,
  Logout,
  ChangeAuth,
  ErrorMessage,
  EmptyErrorResponse,
} from "../../actionTypes/ActionTypes";
import { ApiConfig, InitialApiRoute } from "../../config/ApiConfig";
import decodeJwt from "../../Helpers/DecodeJWT";

const GetUserDetailByToken = () => {
  return (dispatch) => {

    const userInfo = {};
    userInfo.token = localStorage.getItem("token");
    userInfo.userDetail = decodeJwt(userInfo.token);
    userInfo.userDetail.id &&
      dispatch({
        type: UserDetail,
        payload: userInfo,
      });
  };
};

const PostSignUp = (data) => {
  return (dispatch) => {
    axios
      .post(`${InitialApiRoute}/auth/signup`, data, ApiConfig)
      .then((resp) => {
        const userInfo = {};
        userInfo.token = resp.data;
        userInfo.userDetail = decodeJwt(userInfo.token);
        localStorage.setItem("token", userInfo.token);
        dispatch({
          type: UserDetail,
          payload: userInfo,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "signUp",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const PostLogin = (data) => {
  return async (dispatch) => {
    axios
      .post(`${InitialApiRoute}/auth/login`, data, ApiConfig)
      .then((resp) => {
        const userInfo = {};
        userInfo.token = resp.data;
        userInfo.userDetail = decodeJwt(userInfo.token);
        localStorage.setItem("token", userInfo.token);
        dispatch({
          type: UserDetail,
          payload: userInfo,
        });
      })
      .catch((err) => {
        const error = {
          message: err?.response.data.message,
          call: "login",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const LogOut = () => ({ type: Logout });

const ChangeAuthState = (data) => ({ type: ChangeAuth, payload: data });

const TokenVerification = () => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(
        `${InitialApiRoute}/user/tokencheck/check_tokenexpiry`,
        "",
        ProtectedApiConfig
      )
      .then((_resp) => {
        dispatch({
          type: ChangeAuth,
          payload: true,
        });
      })
      .catch((_err) => {
        dispatch({
          type: ChangeAuth,
          payload: false,
        });
      });
  };
};

const PostGoogleAuth = (data) => {
  return (dispatch) => {
    axios
      .get(`${InitialApiRoute}/auth/google/callback?${data}`, ApiConfig)
      .then((resp) => {
        const userInfo = {};
        userInfo.token = resp.data;
        userInfo.userDetail = decodeJwt(userInfo.token);
        localStorage.setItem("token", userInfo.token);
        dispatch({
          type: UserDetail,
          payload: userInfo,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "googleAuth",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const EmptyErrorMsg = () => ({ type: EmptyErrorResponse });

export {
  GetUserDetailByToken,
  PostSignUp,
  PostLogin,
  LogOut,
  ChangeAuthState,
  PostGoogleAuth,
  EmptyErrorMsg,
  TokenVerification,
};
