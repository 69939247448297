import React from 'react';
import {
  Dialog,
} from '@material-ui/core';
import useStyles from './DialogStyle';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Loader from '../Component/Loader';

const DialogComp = ({
  open,
  onClose,
  review,
  text,
  setUserReview,
  onSubmitHandler,
  userReview,
  sm,
  loader,
  greet = true
}) => {

  const classes = useStyles();

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FFFFFF',
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      {
        !sm &&
        <div className={classes.dialogBackground}>
          <form className={classes.form} onSubmit={(e) => { e.preventDefault(); onSubmitHandler(e); }}>
            <div className={classes.root}>
              {greet && text ? <h2 className={classes.congratsText}>
                Congratulations
              </h2> : ""}
              {
                review &&
                <>
                  <p className={classes.tradeText}>
                    Trade Successful
                  </p>
                  <div className={classes.leaveReviewContainer}>
                    <p className={classes.leaveReviewText}>
                      Leave a review
                    </p>
                    <StyledRating
                      className={classes.rating}
                      defaultValue={userReview.rating}
                      size="small"
                      onChange={(e) => setUserReview((prev) => ({ ...prev, rating: parseInt(e.target.value) }))}
                      name="rating"
                      icon={<FavoriteIcon fontSize="inherit" />}
                      emptyIcon={<FavoriteBorderIcon fontSize="inherit" style={{ color: '#199BDD' }} />}
                    />
                  </div>
                  <textarea
                    onChange={(e) => setUserReview((prev) => ({ ...prev, review: e.target.value }))}
                    required
                    className={classes.textArea} placeholder="Review">
                  </textarea>
                  <button className={"btn btn-capsule bg-blue "} disabled={loader} type="submit" >{loader ? <Loader margin="auto" /> : "Submit"} </button>
                </>
              }
              {
                !review &&
                <p className={classes.subText}>
                  {text}
                </p>
              }
            </div>
          </form>
        </div>
      }
      {
        sm &&
        <div className={classes.smallDialogBackground}>
          <div className={classes.root}>
            <p className={classes.subText}>
              {text}
            </p>
          </div>
        </div>
      }
    </Dialog >
  );
}

export default DialogComp;
