import React from "react";
import { Button } from "../../Component/Button";
import styles from "./ErrorPage.module.css";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {

    const navigate = useNavigate();
    const CustomButton = styled(Button)`
        width: 10em;
        font-size: 1.4em;
        margin-top: 20px;
        @media screen and (max-width: 740px) {
            margin-top: 0px;
        }
    `;

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <h2 className={styles.smiley}> :( </h2>
                <h2 className={styles.notFoundText}> 404 - Page Not Found </h2>
                <h5 className={styles.subText}>
                    The page you are looking for might have been removed had its name changed or is temporarily unavailable.
                </h5>
                <CustomButton onClick={() => navigate("/")}>
                    Home Page
                </CustomButton>
            </div>
        </div>
    )
}

export default ErrorPage;