import "../Offers.scss";
import { backArrow, backIcon, iconsSprite } from "../../../Assets/Images";
import React, { useEffect } from "react";
import { DividerX, DividerY } from '../../../Component/Divider';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { OtherUserProfile } from "../../../actions/profile";
import styled from "styled-components";
import { GridContainer, RoundBox, Section } from "../../../Component/Containers";
import { size } from "../../../Helpers/breakpoint";
import { EmptyMyOfferList, GetMyOffers } from "../../../actions/offers";
import OfferInfo from "../OfferInfo/Info";
import { Button } from "../../../Component/Button";
import { H1 } from "../../../Component/Heading";
import { P1 } from "../../../Component/Para";
import Loader from "../../../Component/Loader";
import routesName from "../../../Helpers/routesName";
import { GoBack } from "../../../Component/SquarePictureAnim";


// List Item
const Li = styled.li`
cursor:pointer;
margin-block:5px;
&:hover{
    color:var(--blue);
}
`

// Each Trade Container
const EachTrade = styled.div`
  padding-block:.5em;
  padding-inline:2em;

  & .currentSelection{
    margin-block:.5em;
  }
`
// Button Container Container
const ButtonContainer = styled.div`
  padding-top:1em;
  padding-inline:2em;
  display:flex;
  justify-content:flex-end;
  & button{
    padding-inline:1em !important;
  }
`

// Custom Side Round Box
const SideRoundBox = styled(RoundBox)`
    padding-inline:1.5em;

    & .filter {
      display:none;
    }

    @media only screen and (max-width: ${size.btwMLT}) {
      padding-inline:2.5em;
      min-height:1em;
      & .feed{
        display:none;
      }
      & .skills{
        display:none;
      }
      & .filter {
        & img{
          width:1.2em;
          transform:rotate(270deg);
        }
        & .reverse{
          transform:rotate(90deg);
        }
        width:100%;
        padding-block:1em;
        display:flex;
        justify-content:space-between;
        align-items:center;
      }
    }

`
// List
const List = styled.ul`
    font-family:PoppinsRegular;
    font-size:.8em;
    color:var(--grey);
    padding-block:1em;
    list-style-type:none;

    & .active{
        list-style-type:square;
        color:var(--blue);
    }
    & .activeFilter{
        color:var(--blue);
    }

`

// Custom Round Box
const CustomRoundBox = styled(RoundBox)`
    padding-bottom:1em;
    margin-bottom:2em;

    & .noData{
      text-align:center;
      padding:2em 1em;
    }
`
const CustomDiv = styled.div`

	@media only screen and (max-width: ${size.btwMLT}) {
		& .dividerY{
			width:100%;
			height:1px;
			background:var(--greyLigth);
		}
		width:100%;
		margin-inline:auto;
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
	}
`

const MyOffers = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.Auth.userDetails.userDetail);
  const pagination = useSelector((state) => state.Offer.pagination);
  const fetching = useSelector((state) => state.Offer.fetching);

  let myOffers = useSelector((state) => state.Offer.myOffers)

  const goto = useNavigate();
  const limit = 10


  useEffect(() => {
    dispatch(EmptyMyOfferList())

    if (user?.id) LoadMyOffers(user.id)
  }, [dispatch, user])

  function LoadMyOffers(id) {
    dispatch(GetMyOffers({ limit: limit, page: 1 }))

    // dispatch(GetMyAllOffers(filter))
  }
  function LoadMore() {
    dispatch(GetMyOffers({ limit: limit, page: pagination.currentPage + 1, docsCount: pagination.total }));

  }

  return (

    <section className="offers-section">
      <div className="container grid-container gc-center">
        <div><h2 lh='auto'>&nbsp;</h2></div>
        <div>
          <div className="header">
            <h2 lh='auto' className='currentSelection'>My Offers</h2>
            <button className='go-back-btn' title="Back to feed" onClick={() => goto(routesName.dashboard)}>
              <img src={backArrow} className="backArrow" alt="Back" width="" height="" />
              <img src={backIcon} className="back" alt="Back" width="" height="" />
            </button>
          </div>
          <div>
            {(myOffers.length) ? myOffers.map((val, ind) => {
              return (
                <CustomRoundBox className="custom_round_box">
                  <div key={ind} className="each-trade">
                    <OfferInfo  showAccept={false} showUserInfo={false} key={'OfferList_' + ind} data={val} alwaysShow={false} />
                  </div>
                </CustomRoundBox>
              )
            })
              : fetching ? <Loader width="80px" height="80px" /> : <CustomRoundBox className="custom_round_box"><P1 className="noData center" color="blue" fontSize="1.5em" lh="auto"> No Offers found! </P1></CustomRoundBox>

            }
            {(pagination.currentPage < pagination.lastPage) ? <div className="text-center mtb-3">
              <button className="btn btn-capsule bg-blue" onClick={(e) => LoadMore()}>Load More Trades</button>
            </div> : ""}
          </div>
        </div>
        <div>
          <h2 lh='auto'>&nbsp;</h2>
        </div>
      </div>
    </section>

  )
}

export default MyOffers

