import { RemoveOffer, AddOffer, TradeList, MyTrades, OpenTrade, UpdateAccepetdTrade, MarkComplete, AddTrade, EmptyMyTrades, EmptyTradeList, DeleteTrade, AcceptOffered, FetchingTrades, TradeUnsaved } from "../actionTypes/ActionTypes";

const initailState = {
  tradeList: [],
  myTrades: [],
  currentTrade: "",
  pagination: "",
  filledtrade: "",
  fetching: false
};

const Trade = (state = initailState, action) => {
  switch (action.type) {

    case TradeList:

      return {
        ...state,
        tradeList: state.tradeList.concat(action.payload.tradeData),
        pagination: action.payload.paginationInfo && action.payload.paginationInfo
      }
    case MarkComplete:

      let completedTrade = JSON.parse(JSON.stringify(state.currentTrade));
      completedTrade.trade[0].status = 'completed';
      return {
        ...state,
        currentTrade: completedTrade
      }

    case UpdateAccepetdTrade:

      let acceptedTrade = JSON.parse(JSON.stringify(state.currentTrade))
      acceptedTrade.trade[0] = action.payload
      acceptedTrade.offers = acceptedTrade.offers.map(x => {
        if (x.tradeId === action.payload._id) {
          x.offerStatus = 'accepted'
          return x
        }
      })

      return {
        ...state,
        currentTrade: acceptedTrade
      }

    case MyTrades:
      return {
        ...state,
        myTrades: state.myTrades.concat(action.payload.tradeData),
        pagination: action.payload.paginationInfo && action.payload.paginationInfo
      }

    case OpenTrade:
      return { ...state, currentTrade: action.payload };

    case AddTrade:
      let newArr = state.myTrades.splice(0, 0, action.payload)
      return { ...state, myTrades: newArr };

    case AddOffer:

      let AddedOffer = JSON.parse(JSON.stringify(state.currentTrade))
      AddedOffer.offers.push(action.payload)
      return { ...state, currentTrade: AddedOffer };

    case RemoveOffer:

      let removedOffer = JSON.parse(JSON.stringify(state.currentTrade))
      removedOffer.offers = removedOffer.offers.filter(x => x._id !== action.payload)
      return { ...state, currentTrade: removedOffer };

    case AcceptOffered:
      let acceptedOffer = JSON.parse(JSON.stringify(state.currentTrade))
      acceptedOffer.status = 'accepted'
      acceptedOffer.contractorId = action.payload.userId
      acceptedOffer.acceptedOffer = action.payload._ids
      acceptedOffer.offers = removedOffer.offers.map(x => {
        if (x._id === action.payload) {
          x.offerStatus = 'accepted'

        }
      })
      return { ...state, currentTrade: removedOffer };

    case EmptyMyTrades:
      return { ...state, myTrades: [] };

    case EmptyTradeList:
      return { ...state, tradeList: [] };

    case DeleteTrade:
      return {
        ...state,
        myTrades: state.myTrades.filter(x => (x._id !== action.payload))
      }
    case FetchingTrades:
      return {
        ...state,
        fetching: action.payload
      }

    case TradeUnsaved:
      return {
        ...state,
        filledtrade: action.payload
      }

    default:
      return state;
  }
};

export default Trade;
