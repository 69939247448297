import React, { useEffect } from 'react';
import { Route, Navigate ,Outlet} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ChangeAuthState, GetUserDetailByToken, LogOut, TokenVerification } from '../actions/auth';
import routesName from './routesName';
import styled from 'styled-components';
import { Container } from '../Component/Containers';
import Loader from '../Component/Loader';

const CustomContainer = styled(Container)`
    display: grid;
    place-items: center;
    height:90vh;
`

const ProtectedRoute = (props) => {
  const { path, element } = props;
  const dispatch = useDispatch();
  let auth = useSelector((state) => state.Auth.auth);
  const { login } = routesName;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(TokenVerification());
      if (auth === true) {
        dispatch(GetUserDetailByToken());
      } else if (auth === false) {
        dispatch(ChangeAuthState(false));
      }
    } else {
      dispatch(LogOut());
    }
  }, [auth]);

  return (
    <div>
      {auth !== "false" ?
        !auth ? <Navigate to={{ pathname: login }} /> :
          <Outlet />
        : <CustomContainer>
          <Loader width="50px" height="50px" />
        </CustomContainer>
      }
    </div>
  );
};

export default ProtectedRoute;