const size = {
  mobileS: '280px',
  mobileM: '375px',
  mobileL: '425px',
  btwMLT: '550px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1280px',
  desktop: '1440px',
  desktopWide: '1920px',
}

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopWide: `(min-width: ${size.desktopWide})`,
};

export default device
export {size}