import styled from "styled-components";

const HeadingLarge = styled.h1`
    font-family: PoppinsBold;
    font-size: 3.5em;
    line-height: 1em;
    color: var(--blackashgreen);
`;

const Heading2 = styled.h2`
    font-family: PoppinsBold;
    font-size: 3em;
    line-height: 1.35em;
    color: var(--greyashgreen);
`;
const Heading3 = styled.h3`
    font-family: PoppinsBold;
    font-size: 2em;
    line-height: 1.35em;
    color: var(--greyashgreen);
`;
const HeadingMedium = styled.h4`
    font-family: PoppinsLight;
    font-size: 1.5em;
    line-height: 1.35em;
    color: var(--greyashgreen);
`;
const Heading5 = styled.h5`
    font-family: PoppinsSemiBold;
    font-size: 1em;
    line-height: 1em;
    color: var(--greyashgreen);
`;
const Heading6 = styled.h6`
    font-family: PoppinsLight;
    font-size: 1em;
    line-height: 1em;
    color: var(--greyashgreen);
`;
const H1 = styled.h1`
    font-family: PoppinsRegular;
    font-size: ${({fontSize}) => fontSize ? `${fontSize}` : '2.5em'};
    line-height: ${({lh}) => lh ? lh : '1em'};
    color:${({color}) => color ? `var(--${color})` : 'var(--blackashgreen)'};
`;
const H2 = styled.h2`
    font-family: PoppinsRegular;
    font-size: 1.5em;
    font-weight:normal;
    line-height: ${({lh}) => lh ? lh : '1em'};
    color:${({color}) => color ? `var(--${color})` : 'var(--blackashgreen)'}
`;
const H6 = styled.h6`
    font-family: PoppinsRegular;
    font-size: .9em;
    line-height: ${({lh}) => lh ? lh : 'auto'};
    color:${({color}) => color ? `var(--${color})` : 'var(--blackashgreen)'}
`;
export {
    HeadingLarge,
    Heading2,
    Heading3,
    HeadingMedium,
    Heading5,
    Heading6,
    H1,
    H2,
    H6
}