import "./Footer.scss";
import React, { useState, useEffect } from 'react'
import {
  insta_circle,
  //  linkedin_circle,
  youtube_circle, twitter_circle, facebook_circle
} from '../Assets/Images'
import { useLocation } from 'react-router'
import { Link } from 'react-scroll'
import routesName from '../Helpers/routesName';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'

const Footer = () => {

  const [isLinksVisible, setIsLinksVisible] = useState(true)
  const { pathname } = useLocation()
  const goto = useNavigate();
  const { signUp, login } = routesName;
  const auth = useSelector((state) => state.Auth.auth);

  useEffect(() => {
    (pathname === '/login' || pathname === '/create-account' || JSON.parse(auth)) ? setIsLinksVisible(false) : setIsLinksVisible(true)
  }, [pathname, auth])

  const executeScroll = (id) => {

    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div id="Footer">
      <div className='container'>
        <div className='footerTop'>
          <ul className='pages'>
            {isLinksVisible && (
              <React.Fragment>
                <li className="page">
                  <p className='pageLink'>About</p>
                  <ul className='subLinks'>
                    <li className="subLink"><a to='HowThisItWork' onClick={(e) => { executeScroll("HowThisItWork") }}>How it Works</a></li>
                    <li className="subLink"><a to='PricingPlansHome' onClick={(e) => { executeScroll("PricingPlansHome") }}>Pricing {'&'} Plans</a></li>
                    <li className="subLink"><a to='Testimonials' onClick={(e) => { executeScroll("Testimonials") }}>Testimonials</a></li>
                  </ul>
                </li>
                <li className="page">
                  <p className='pageLink'>Become a Trader</p>
                  <ul className='subLinks'>
                    <li className="subLink">
                      <a href={login}>Login</a>
                    </li>
                    <li className="subLink">
                      <a href={signUp}>Sign Up</a>
                    </li>
                  </ul>
                </li>
                <li className="page">
                  <p className='pageLink'>Contact Us</p>
                  <ul className='subLinks'>
                    <li className="subLink"><a href="tel:+449378304162">+44 9378 304162</a></li>
                    <li className="subLink"><a href="mailTo:ayrika@barter-e.com">ayrika@barter-e.com</a></li>
                  </ul>
                </li>
              </React.Fragment>
            )}
            <li className="page">
              <p className='pageLink'>Follow us</p>
              <ul className='subLinks'>
                <li className="subLink">
                  <div className='social'>
                    <a href="https://www.facebook.com/Barter-E-101193858950609" rel="noreferrer" target='_blank'><img src={facebook_circle} alt="Facebook" width="" height="" /></a>
                    {/* <a href="https://linkedin.com" target='_blank' rel="noreferrer"><img src={linkedin_circle} alt="Linkedin" width="" height="" /></a> */}
                    <a href="https://twitter.com/BarterE2" target='_blank' rel="noreferrer"><img src={twitter_circle} alt="Twiiter" width="" height="" /></a>
                    <a href="https://www.youtube.com/channel/UCxL7TU8VL6IsCpuKp-p5LKA" rel="noreferrer" target='_blank'><img src={youtube_circle} alt="Youtube" width="" height="" /></a>
                    <a href="https://www.instagram.com/tradewithbartere/" target='_blank' rel="noreferrer"><img src={insta_circle} alt="Instagram" width="" height="" /></a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className='line'></div>
        <div className="forBottom">
          <p> <a href="https://www.koloraddikt.com" target='_blank' rel="noreferrer">Designed by Kolor Addikt</a></p>
          <div className='verticalLine'></div>
          <p><a href={`${process.env.REACT_APP_URL}/Terms`} target="_blank">Terms &amp; Conditions</a></p>
        </div>
      </div>
    </div>
  )
}

export default Footer