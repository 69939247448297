import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { Delete } from "../../../actions/trade"
import { Button } from "../../../Component/Button"
import Loader from "../../../Component/Loader"
import routesName from "../../../Helpers/routesName"


const DeleteTradeComp = ({ _id, setOpen, setText, setGreetings }) => {
  const [loader, setLoader] = useState(false)
  const authState = useSelector((state) => state.Auth)
  const { myTrades } = routesName
  const goto = useNavigate();
  const dispatch = useDispatch();

  const DeleteTrade = () => {
    setLoader(true)
    dispatch(Delete(_id))
  }

  useEffect(() => {
    if (loader) {
      if (authState.errorCall == 'deleteTrade' && authState.errorRespMsg) {
        setLoader(false);
      }
      else {
        setGreetings(false)
        setOpen(true)
        setText("Your Trade has been deleted")
        setLoader(false);
        setTimeout(() => {
          setOpen(false)
          setText("")
          setGreetings(true)
          goto(myTrades)
        }, 2000);
      }
    }
  }, [authState])
  return (
    <div>
      <button className="btn btn-capsule bg-blue" style={{"float" : "right"}} disabled={loader} onClick={(e) => DeleteTrade()} > {loader ? <Loader /> : "Delete"}</button>
    </div>
  )
}
export default DeleteTradeComp