import { IconButton } from '../../../Component/Button';
import styled from 'styled-components';
import { BarteringPartner,BateringPartner2 } from '../../../Assets/Images';
import { Container, Content, Section } from '../../../Component/Containers';
import { ParaSmall } from '../../../Component/Para'
import { HeadingLarge } from '../../../Component/Heading';
import routesName from '../../../Helpers/routesName';
import { useNavigate } from 'react-router-dom';

const marginTran = 'margin 0.5s ease'

// Custom Section
const CustomSection = styled(Section)`
    padding-block:1em;
`
// Button
const CustomButton = styled(IconButton)`
    display:flex;
    justify-content:center;
    align-items:center;
    height:20%;
    margin-top:auto;
    margin-bottom:0px;
    font-size: 2em !important;
    border-radius: 10px 10px 10px 55px;
    & .text, & .icon{
        transition:${marginTran};
        margin-inline:0.3em;
    }

    & .icon{
        margin-top:15px;
    }
    & .icon svg path{
        fill: var(--white);
    }

    &:hover{
        background-color: var(--white);
        color: var(--blue);
    }

    &:hover .icon svg path{
        fill: var(--blue);
    }

    &:hover .text{
        margin-inline:1.5em;
        transition:${marginTran};
    }

    &:hover .icon{
        margin-inline:1.5em;
        transition:${marginTran};
    }
`
// Heading Large
const Title = styled(HeadingLarge)`
    margin-top: 1.3em;
    line-height:1.2em;
    color:var(--white);
    `

// Para
const CustomParaSmall = styled(ParaSmall)`
    color:var(--white);
    font-size:1.1em;
    line-height:1.35em;
    margin-top:20px ;
`

// Div
const CustomContent = styled(Content)`
    width: 100%;
    height: 33.8em;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    background:url(${BateringPartner2});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px 55px;
    margin-top: 2em;
    color:var(--white);
    padding:1.5em;
`

const FindYourBartering = () => {
    const { login } = routesName;
    const goto = useNavigate();

    return (
        <CustomSection>
            <Container>
                <CustomContent>
                    <Title>Find Your <br /> Bartering Partner</Title>
                    <CustomParaSmall>Work with the largest network of <br /> independent professionals and get things <br /> done—from quick turnarounds to big transformations.</CustomParaSmall>
                    <CustomButton onClick={() => goto(login)}>
                        <span className="text">Post a Trade</span>
                        <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 38 38"><defs></defs><path className="rightArrow" d="M19,0,15.545,3.455,28.623,16.532H0v4.935H28.623L15.545,34.545,19,38,38,19Z" /></svg></span>
                    </CustomButton>
                </CustomContent>
            </Container>
        </CustomSection>
    )
}

export default FindYourBartering;