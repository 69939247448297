import "./Dashboard.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Section, GridContainer, RoundBox } from "../../Component/Containers";
import { AddTags } from '../../Component/SearchSelect';
import { H1, H2, H6 } from "../../Component/Heading";
import Modal from "../../Component/Modal";
import { size } from "../../Helpers/breakpoint";
import {
  // mySkills,
  filterBy,
  // tradeList
} from "./Content";
import { backIcon, searchIcon } from "../../Assets/Images";
import routesName from "../../Helpers/routesName";
import { DividerX } from '../../Component/Divider';
import EditIcon from '../../Component/EditIcon';
import { TradeUserInfoHeader } from "../../Component/UserInfoHeader";
import { Button } from "../../Component/Button";
import { DynamicTags } from "../../Component/Tags";
import { useDispatch, useSelector } from "react-redux";
import { EmptyList, FilterTrades, GetAllTrades } from "../../actions/trade";
import { P1 } from "../../Component/Para";
import Loader from "../../Component/Loader"


// Grid Item
const GridItem = styled.div`
  width:100%;
  min-height:100px;
	margin-top:0.5em;
	margin-inline:auto;
  @media only screen and (max-width: ${size.tablet}) {
    min-height:1em;
  }
`

const SearchInputDiv = styled.div``;

// Custom Round Box
const CustomRoundBox = styled(RoundBox)``;

// My Skills
const MySkills = styled.div`
`
// Each Trade Container
const EachTrade = styled.div`
  padding-block:.5em;
  padding-inline:2em;

  & .currentSelection{
    margin-block:.5em;
  }
`
// Button Container Container
const ButtonContainer = styled.div`
  padding-top:1em;
  padding-inline:2em;
  display:flex;
  justify-content:flex-end;
  & button{
    padding-inline:1em !important;
  }
`

const Dashboard = () => {
  const [active, setActive] = useState(0);

  const authState = useSelector((state) => state.Auth);
  const fetching = useSelector((state) => state.Trade.fetching);
  const [loader, setLoader] = useState(false);
  const [viewFilter, setViewFilter] = useState(false)
  const [editTopSkills, setEditTopSkills] = useState(false);
  const skills = useSelector(state => state.Profile.skillList);
  const [mySkillsFilter, setMySkillsFilter] = useState([]);
  const [skillTag, setSkilTag] = useState();
  const goto = useNavigate();
  const dispatch = useDispatch();
  const tradeList = useSelector((state) => state.Trade.tradeList);
  const pagination = useSelector((state) => state.Trade.pagination);
  const mySkills = useSelector(state => state.Profile.userProfile);
  const limit = 10


  useEffect(() => {
    if (authState.errorCall !== "updateProfile") {
      setEditTopSkills(false);
    }
    setLoader(false)
  }, [authState])


  useEffect(() => {
    dispatch(EmptyList())
    if (mySkillsFilter.length !== 0) {
      const filterArray = {
        interests: mySkillsFilter,
      }
      dispatch(FilterTrades(filterArray));
    } else {
      dispatch(GetAllTrades({ limit: limit, page: 1 }));
    }
  }, [dispatch, active, mySkillsFilter])

  const isActive = (id) => {
    setActive(id)
  }
  // Remove Item
  const removeItem = (id) => {
    const filter = mySkillsFilter.filter((item) => item !== id)
    setMySkillsFilter(filter)
  }

  // Remove Item
  const removeAll = () => {
    setMySkillsFilter([])
  }


  const LoadMore = () => {
    dispatch(GetAllTrades({ limit: limit, page: pagination.currentPage + 1, docsCount: pagination.total }));

  }
  return (
    <section className="dashboard-section">
      <div className="container grid-container">
        <div className="mb-0">
        </div>
        <div className="mb-0">
          <div className="search">
            <div class="search-area">
              <AddTags label="search" name="search" placeholder="Search for trades" on style={{ fontSize: '1em', background: 'var(--whiteF0)', border: 'none' }}
                value={skillTag} onChange={(e) => setSkilTag(e.target.value)} options={skills} />
              <button className="btn btn-round bg-blue search-btn" onClick={() => {
                let isExist = mySkillsFilter.includes(skillTag);
                // let isValid = skills.filter((v) => v.skillName === skillTag);
                // isValid[0] && (mySkillsFilter.length < 5) && --- This condition removed only for here
                !isExist && skillTag && setMySkillsFilter([...mySkillsFilter, skillTag])
                setSkilTag('')
              }}>
                <img src={searchIcon} alt="searchIcon" width="" height="" />
              </button>
            </div>
            <button className="btn btn-capsule bg-blue" onClick={() => goto(routesName.createTrade)}>Create Trade</button>
          </div>
          <div className="filters">
            {(mySkillsFilter?.length !== 0) && <DynamicTags list={mySkillsFilter} removeItem={removeItem} removeAll={removeAll} isClearTags={true} />}
          </div>
        </div>
        <div>
        </div>
      </div>
      <div className="container grid-container">
        <div>
          <div className="sidebar">
            <div className="filter" onClick={() => setViewFilter(!viewFilter)}>
              <H2>Filter</H2>
              <img className={viewFilter ? 'reverse' : ""} src={backIcon} alt="" width="" height="" />
            </div>
            <ul className={(viewFilter ? '' : "feed") + " feed-mg-b"}>
              {
                filterBy && filterBy.map((val, ind) => {
                  return (
                    <li key={val} className={active === ind ? 'active' : ''} id={ind} onClick={(e) => isActive(ind)}>
                      {val}
                    </li>
                  )
                })
              }
            </ul>
            <div style={{ "position": "relative" }} className={viewFilter ? '' : "skills"}>
              <H6>My Skills</H6>
              <ul>
                {(mySkills?.tags?.length !== 0) && mySkills?.tags?.map((skill, ind) => {
                  return (
                    <li className={mySkillsFilter.includes(skill) ? 'activeFilter' : ''}
                      id={skill}
                      key={skill}
                      onClick={(e) => {
                        let isExist = mySkillsFilter.includes(e.target.id);
                        !isExist && setMySkillsFilter([...mySkillsFilter, e.target.id])
                      }}>
                      {skill}
                    </li>
                  )
                })}
              </ul>
              <EditIcon whenClick={() => setEditTopSkills(true)} />
              {editTopSkills && <Modal className="modal" title='Edit Top Skills' setView={setEditTopSkills} skills={skills}
                loader={loader} setLoader={setLoader} />}
            </div>
          </div>
        </div>
        <div>
          <CustomRoundBox className="custom_round_box">
            <div className="each-trade">
              <H1 className='currentSelection' fontSize='2em' color='blue'>
                {filterBy[active]}
              </H1>
            </div>
            <DividerX />

            <div>{
              (tradeList.length) ? tradeList.map((val, ind) => {
                return (
                  <div key={ind}>
                    <div className="each-trade">
                      <TradeUserInfoHeader key={'tradeList_' + ind} data={val} />
                    </div>
                    <DividerX />
                  </div>
                )
              })
                : fetching ? <Loader width="80px" height="80px" /> : <P1 className="noData" color="blue" fontSize="1.5em" lh="auto"> No Trades found! </P1>
            }
              {(pagination.currentPage < pagination.lastPage) ? <div className="text-center mtb-3">
                <button className="btn btn-capsule bg-blue" onClick={(e) => LoadMore()}>Load More Trades</button>
              </div> : ""}
            </div>
          </CustomRoundBox>
        </div>
        <div></div>
      </div>
    </section>
  )
}

export default Dashboard;
