import axios from "axios";
import { EmptySearch, ErrorMessage, SetSearchedTrades, SetSearchedUsers } from "../../actionTypes/ActionTypes";
import { InitialApiRoute } from "../../config/ApiConfig";
import { EmptyErrorMsg } from "../auth";

const SearchGlobal = (data) => {
    return (dispatch) => {
        if (data) {
            const ProtectedApiConfig = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
            };
            axios
                .get(`${InitialApiRoute}/static/search?keyword=` + data,
                    ProtectedApiConfig)
                .then((resp) => {
                    dispatch(EmptyErrorMsg())
                    dispatch({
                        type: SetSearchedUsers,
                        payload: resp.data.results.users,
                    });
                    dispatch({
                        type: SetSearchedTrades,
                        payload: resp.data.results.trades,
                    });
                })
                .catch((err) => {
                    const error = {
                        message: err.response.data.message,
                        call: "globalSearch",
                    };
                    dispatch({
                        type: ErrorMessage,
                        payload: error,
                    });
                });
        }
        else {
            dispatch({
                type: EmptySearch,
                payload: ""

            })
        }
    };
};

export { SearchGlobal };