// import "./App.css";
import Header from "./Component/Header";
import DashboardHeader from "./Component/DashboardHeader";
import Footer from "./Component/Footer";
import Routers from "./Helpers/Routers";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import socketService from "./services/socketService"
import SnackBarComponent from "./Component/SnackBarComponent";
import { useLocation } from "react-router";
import { createBrowserHistory } from "history";
import { useRef } from 'react'



function App() {

  const auth = useSelector((state) => state.Auth.auth);
  const user = useSelector((state) => state.Auth.userDetails.userDetail?.id);
  const history = createBrowserHistory()
  const page = useLocation()

  const scrollRef = useRef(null);

  useEffect(() => {

    if (user) {
      socketService.Connect(user)
    }

  }, [socketService.socket, user]);

  let pageName = page.pathname.split('/')[1]

  const handleScroll = (e) => {
    if (e.target.scrollTop >= 100) {
      scrollRef.current.classList.add('is_sticky')
    } else {
      scrollRef.current.classList.remove('is_sticky')
    }

  }

  return (
    <div className={"App" + " " + (pageName ? pageName : "home")} onScroll={handleScroll}>

      <div ref={scrollRef}>
        {auth === "false" ? null : auth ?

          <DashboardHeader ref={scrollRef} /> : <Header />
        }
      </div>
      <div className="content">
        <Routers />
      </div>
      <SnackBarComponent />
      <Footer />
    </div>
  );
}

export default App;
