import { CreateOffer, DeleteMyOffer, EmptyMyOffers, FetchingOffers, SetMyOffers } from "../actionTypes/ActionTypes";

const initailState = {
    myOffers: [],
    pagination: "",
    fetching: []
};

const Offer = (state = initailState, action) => {
    switch (action.type) {

        case SetMyOffers:

            return {
                ...state,
                myOffers: state.myOffers.concat(action.payload.offers),
                pagination: action.payload.paginationInfo && action.payload.paginationInfo
            }

        case CreateOffer:
            return {
                ...state,
                myOffers: [action.payload, ...state.myOffers]
            }

        case EmptyMyOffers:
            return { ...state, myOffers: [] };

        case DeleteMyOffer:
            return {
                ...state,
                myOffers: state.myOffers.filter(x => (x._id !== action.payload))
            }

        case FetchingOffers:
            return {
                ...state,
                fetching: action.payload
            }
        default:
            return state;

    }
};

export default Offer;

// case "GET_ALL":
//     return state;

// case 'SET_ALL': {
//     return {
//         ...state,
//         citiesList: action.payload
//     }
// }
// case 'SET_ROWS_COUNT': {
//     return {
//         ...state,
//         totalRecords: action.payload
//     }
// }
// case "GET_ALL_FILTERED":
//     return state;

// case 'SET_ALL_FILTERED': {
//     return {
//         ...state,
//         filteredList: action.payload
//     }
// }
// case 'SET_SELECTED_CITY': {
//     return {
//         ...state,
//         selectedCity: action.payload
//     }
// }

// case 'GET_CITY_BY_KEY':
//     return { ...state, selectedCity: action.payload };

// case 'ADD_CITY':
//     return {
//         ...state,
//         citiesList: [action.payload, ...state.citiesList,]
//     }

// case 'UPDATE_CITY_BY_KEY':
//     let index = state.citiesList.findIndex(x => x["CITY-key"] === action.payload.key);
//     let newArray = [...state.citiesList];
//     newArray[index] = action.payload.CITY
//     return {
//         ...state,
//         citiesList: newArray,
//     }

// case 'DELETE_CITY_BY_KEY':
//     return {
//         ...state,
//         citiesList: state.citiesList.filter(x => (x["CITY-key"] !== action.payload))
//     }
