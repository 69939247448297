export const categories = [
    {
        catName:'Data Entry Specialists',
        link:'#'
    },
    {
        catName:'Video Editors',
        link:'#'
    },
    {
        catName:'Data Analyst',
        link:'#'
    },
    {
        catName:'Shopify Developer',
        link:'#'
    },
    {
        catName:'Accountant',
        link:'#'
    },
    {
        catName:'Copywriter',
        link:'#'
    },
    {
        catName:'Flyer Design',
        link:'#'
    },
    {
        catName:'Graphic Design',
        link:'#'
    },
    {
        catName:'CPA Website Designer',
        link:'#'
    },
    {
        catName:'Customer Service',
        link:'#'
    },
    {
        catName:'Virtual Assistant',
        link:'#'
    },
    {
        catName:'Contract Writer',
        link:'#'
    },
    {
        catName:'Legal',
        link:'#'
    },
    {
        catName:'SEO Expert',
        link:'#'
    },
    {
        catName:'',
        link:'#'
    },
]
