import { useState } from "react";
import styled from "styled-components";
import { H2 } from "../../Component/Heading";
import ImagePreview from "../../shared/ImagePreview";
import { LoadUploadedImage } from "../../services/utilService";

// import {
//   Button,
//   OnlyButton
// } from "../../Component/Button";
// import {
//   useState
// } from "react";
// import { useSelector } from "react-redux";


const CustomDiv = styled.div`
    width:100%;
    padding:2em 1em;
    & .viewMore{
        padding-left:8px;
        color:var(--blue);
        &:hover{
            color:var(--blue-dark);
        }
    }
`

const Galleries = styled.ul`
`

const Item = styled.li`
    flex-basis:30%;
    background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    // background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    background-size:cover;
    background-repeat:no-repeat;
    height:9.35em;
    border-radius:15px;
    margin:.5em;

    & div{
        border-radius:15px;
        width:inherit;
        height:inherit;
        background:var(--transparent);
        diplay:grid;
        place-items:center;
        opacity:0;
        transition:var(--trans_3s);

        & button{
            margin-inline:auto;
            color:var(--white);
            &:hover{
                color:var(--white);
            }
        }
        &:hover{
            transition:var(--trans_3s);
            opacity:1;
            background:var(--blackOp076);
        }
    }
`

const Gallery = ({ userDetail }) => {

  const [previewImage, setPreviewImage] = useState("");
  const [end, setEnd] = useState(3);
  const [viewMore, setViewMore] = useState(false);

  const HandleImageView = (viewMore) => {

    if (!viewMore) setEnd(3)
    else setEnd(userDetail.portfolioImages?.length)
  }

  return (
    <CustomDiv>
      <div>
        <H2>Gallery</H2>
      </div>
      <div className="profile-galleries">
        {
          userDetail.portfolioImages?.reverse().slice(0, end).map((val, ind) => {
            return (
              <Item key={'_' + ind} image={LoadUploadedImage(val)} onClick={(e) => { setPreviewImage(val) }}>
                <div>
                </div>
              </Item>
            )
          })
        }
        {previewImage ? <ImagePreview setOpen={setPreviewImage} gallery={[previewImage]}></ImagePreview> : ""}

      </div>
      {(userDetail.portfolioImages?.length > 2) ? (!viewMore ? <button className="btn btn-capsule btn-outline bg-blue" onClick={() => { setViewMore(true); HandleImageView(true) }}>View More</button> : <button className="btn btn-capsule btn-outline bg-blue" onClick={() => { setViewMore(false); HandleImageView(false) }}>View Less</button>) : ""}

    </CustomDiv>
  )
}

export default Gallery