import "../Profile.scss";
import { backArrow, backIcon, iconsSprite } from "../../Assets/Images";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Section, GridContainer, RoundBox } from "../../Component/Containers";
import { H1, H2 } from "../../Component/Heading"
import routesName from "../../Helpers/routesName";
import BoxHeader from "./BoxHeader";
import FieldInfo from "./FieldInfo";
import { useSelector, useDispatch } from "react-redux";
import { P1 } from "../../Component/Para";
import { DividerY } from "../../Component/Divider";
import { pricingPlans } from "../Home/PricingPlans/content";
import { size } from "../../Helpers/breakpoint";
import { ViewSubscription } from "../../actions/payment";
import { EmptyErrorMsg } from "../../actions/auth";
import ProfilePictureForm from "./forms/ProfilePictureForm";
import { GetProfile } from "../../actions/profile";

const CustomGridContainer = styled(GridContainer)``;

const List = styled.ul`
    font-family:PoppinsRegular;
    font-size:.8em;
    color:var(--grey);
    padding-block:1em;
    list-style-type:none;

    & .active{
        list-style-type:square;
        color:var(--blue);
    }
`
const Li = styled.li`
    cursor:pointer;
    margin-block:5px;
    &:hover{
        color:var(--blue);
    }
`
const CustomRoundBox = styled(RoundBox)``

const SideRoundBox = styled(RoundBox)`
    padding-inline:1.5em;

	& .filter {
		display:none;
	  }

	  @media only screen and (max-width: ${size.btwMLT}) {
		padding-inline:2.5em;
		min-height:1em;
		& .settings{
		  display:none;
		}
		& .filter {
		  & img{
			width:1.2em;
			transform:rotate(270deg);
		  }
		  & .reverse{
			transform:rotate(90deg);
		  }
		  width:100%;
		  padding-block:1em;
		  display:flex;
		  justify-content:space-between;
		  align-items:center;
		}
	  }
`
const CurrentPlans = styled.div`
	width:100%;
	max-width:750px;
	display:flex;
	justify-content:space-around;
	align-items:center;
	gap:2em;
	padding-inline:1em;
	min-height:50px;
	margin-inline:auto;

	& .list{
		padding-inline:.5em;
		list-style-type:square;
        color:var(--blue);
	}
`
const PriceTag = styled(H1)`
	sup{
		font-size:0.45em;
	}
`
const CustomLi = styled.li`
    & div{
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding-block:.2em;
    }

    & div p{
        color:var(--grey);
        margin-right:.3em;
    }
`

const Image = styled.a`
	position: relative;
	cursor:default;
	display:flex;
	justify-content:center;
	align-items:center;
	& #Ellipse_51{
		fill:none;
		stroke:#33b0f2;
	}
	& #Path_368{
		fill:var(--blue);
	}
	&:hover{
		& #Ellipse_51{
			fill:var(--blue);
		}
		& #Path_368{
			fill:var(--white);
		}
	}
	&:hover::after{
		position:absolute;
		display: flex;
		justify-content: center;
		align-item:center;
		background: var(--blue);
		border-radius: 10px;
		color: #fff;
		content: attr(aria-label);
		font-size: .7em;
		padding: .75em;
		min-width: 10.7em;
		left: 36px;
	}
	&:hover::before{
		border: solid;
		border-color: transparent var(--blue);
		border-width: .5em .8em .5em 0em;
		content: "";
		left: 22px;
		position: absolute;
	}
`;

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.Profile.userProfile);
  const authState = useSelector((state) => state.Auth);
  const [planDetails] = useSelector((state) => state.Payment.subscription);
  const [viewSetting, setViewSetting] = useState(false)
  const [plan, setPlan] = useState('');

  const [edit, setEdit] = useState({
    accounts: false,
    details: false,
    password: false,
    image: false
  });

  const [active, setActive] = useState('2');
  const [loader, setLoader] = useState(false);
  const goto = useNavigate()

  useEffect(() => {
    if (authState.errorCall === "updateProfile" || authState.errorCall === "changePassword") {
      setLoader(false);
    } else {

      setLoader(false);
      setEdit({
        accounts: false,
        details: false,
        password: false,
        image: false
      });
    }
  }, [authState])
  useEffect(() => {
    if (profile._id) dispatch(GetProfile(profile._id))
  }, [dispatch, profile._id])

  useEffect(() => {
    dispatch(ViewSubscription());
    (planDetails?.status === "active") && setPlan(pricingPlans[1])
  }, [planDetails?.status])

  useEffect(() => {
    (profile?.alowedTrade > 0) && setPlan(pricingPlans[0])
  }, [profile])

  const isActive = (id) => {
    setActive(id);
    setEdit({
      accounts: false,
      details: false,
      password: false,
      image: false
    })
  }

  useEffect(() => {
    dispatch(EmptyErrorMsg());
  }, [active])

  return (
    <section className="profile-section">
      <div className="container grid-container">
        <div>
          <div className="header">
            <h2 lh='auto' className='currentSelection'>&nbsp;</h2>
          </div>
          <div className="sidebar without-skill">
            <div className="filter" onClick={() => setViewSetting(!viewSetting)}>
              <h2>Settings</h2>
              <img className={viewSetting ? 'reverse' : ""} src={backIcon} alt="" width="" height="" />
            </div>
            <ul className={viewSetting ? '' : 'settings'}>
              <li className={active === '1' ? 'active' : ''} id='1' onClick={(e) => goto(routesName.profile.root)}>
                My Profile
              </li>
              <li className={active === '2' ? 'active' : ''} id='2' onClick={(e) => isActive(e.target.id)}>
                Profile Settings
              </li>
              <li className={active === '3' ? 'active' : ''} id='3' onClick={(e) => isActive(e.target.id)}>
                Password & Security
              </li>
              <li className={active === '4' ? 'active' : ''} id='4' onClick={(e) => isActive(e.target.id)}>
                Pricing Plans
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div class="header">
            <h2 lh='auto' className="currentSelection">
              {
                active === '2' ? 'Profile Settings' : active === '3' ? 'Password & Security' : active === '4' && 'My Plans'
              }
            </h2>
            <button className='go-back-btn' title="Back to settings" onClick={() => goto(routesName.profile.root)}>
              <img src={backArrow} className="backArrow" alt="Back" width="" height="" />
              <img src={backIcon} className="back" alt="Back" width="" height="" />
            </button>
          </div>
          {
            active === '2' ? <React.Fragment>
              <CustomRoundBox className="custom_round_box">
                <BoxHeader edit={edit} setEdit={setEdit} BoxTitle='Account' divider={true} />
                {
                  edit?.accounts ?
                    <FieldInfo profile={profile} edit={edit} loader={loader} setLoader={setLoader} />
                    : <React.Fragment>
                      {
                        profile &&
                        <>
                          <FieldInfo fieldName='Username' fieldValue={profile.userName} />
                          <FieldInfo fieldName='Name' fieldValue={profile.firstName + " " + profile.lastName} />
                          <FieldInfo fieldName='Email' fieldValue={profile.email} />
                        </>
                      }
                    </React.Fragment>
                }
              </CustomRoundBox>
              <CustomRoundBox className="custom_round_box">
                <BoxHeader edit={edit} setEdit={setEdit} BoxTitle='Details' divider={true} />
                {
                  edit?.details ?
                    <FieldInfo profile={profile} edit={edit} loader={loader} setLoader={setLoader} />
                    : <React.Fragment>
                      {
                        profile &&
                        <>
                          <FieldInfo fieldName='Phone' fieldValue={profile.phoneNo} />
                          <FieldInfo fieldName='Address' fieldValue={profile.address || ""} />
                          <FieldInfo fieldName='Location' fieldValue={(profile.state ? profile.state : "") + (profile.country ? (", " + profile.country) : "")} />
                        </>
                      }
                    </React.Fragment>
                }
              </CustomRoundBox>
              <CustomRoundBox className="custom_round_box">
                <ProfilePictureForm profile={profile} galleryEdit={edit} setGalleryEdit={setEdit} loader={loader} setLoader={setLoader} />
              </CustomRoundBox>
            </React.Fragment> : active === '3' ? <React.Fragment>
              <CustomRoundBox className="custom_round_box">
                <BoxHeader edit={edit} setEdit={setEdit} BoxTitle='Password' divider={true} />
                {
                  edit?.password ?
                    <FieldInfo edit={edit} loader={loader} setLoader={setLoader}
                    />
                    : <FieldInfo fieldName='Password has been set' fieldValue='' edit={edit} />
                }
              </CustomRoundBox>
            </React.Fragment> : active === '4' && <React.Fragment>
              {
                ((profile?.alowedTrade === 0) || !profile.CurrentPlan) &&
                <CustomRoundBox className="custom_round_box">
                  <BoxHeader BoxTitle={'No Active Plan'} button={true} />
                </CustomRoundBox>
              }
              {
                (profile?.alowedTrade > 0 || profile?.alowedTrade === -1) &&
                <CustomRoundBox className="custom_round_box">
                  <CurrentPlans className="current-plan">
                    <P1>{plan.title}</P1>
                    <PriceTag color="blue" fontSize="5.5em"><sup>$</sup>{plan.price}</PriceTag>
                    <DividerY minHeight="5em" color="whiteF0" />
                    <ul className='list'>
                      {plan.list && plan.list.map(({ info, infoText, text, src }, ind) => {
                        return <CustomLi key={'_' + ind} >
                          <div>
                            <p>{text}</p>
                            {info && <Image aria-label={infoText}>
                              {/* <img src={InfoIcon} className="icon" alt="" width="15" height="15" /> */}

                              <svg id="Component_224_3" data-name="Component 224 – 3" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Ellipse_51" data-name="Ellipse 51" stroke-width="1.5">
                                  <circle cx="10" cy="10" r="10" stroke="none" />
                                  <circle cx="10" cy="10" r="9.25" fill="none" />
                                </g>
                                <path id="Path_368" data-name="Path 368" d="M35.382,8.831l-.138.565q-.622.246-.993.374a2.62,2.62,0,0,1-.861.129,1.719,1.719,0,0,1-1.172-.367A1.189,1.189,0,0,1,31.8,8.6a3.3,3.3,0,0,1,.031-.449q.032-.23.1-.519l.519-1.835q.069-.264.117-.5a2.172,2.172,0,0,0,.047-.43.665.665,0,0,0-.145-.491.82.82,0,0,0-.555-.139A1.453,1.453,0,0,0,31.5,4.3c-.141.042-.262.082-.363.119l.139-.566q.51-.207.975-.355a2.916,2.916,0,0,1,.882-.148,1.677,1.677,0,0,1,1.154.362,1.2,1.2,0,0,1,.405.94c0,.08-.009.22-.028.421a2.817,2.817,0,0,1-.1.554l-.516,1.828a4.856,4.856,0,0,0-.113.5,2.592,2.592,0,0,0-.05.427.616.616,0,0,0,.163.5.918.918,0,0,0,.565.132,1.631,1.631,0,0,0,.427-.066A2.388,2.388,0,0,0,35.382,8.831Zm.131-7.675a1.07,1.07,0,0,1-.361.814,1.23,1.23,0,0,1-.87.337,1.243,1.243,0,0,1-.874-.337,1.067,1.067,0,0,1-.365-.814,1.078,1.078,0,0,1,.365-.817,1.29,1.29,0,0,1,1.744,0A1.08,1.08,0,0,1,35.513,1.156Z" transform="translate(-23.663 5)" />
                              </svg>

                            </Image>}
                          </div>
                        </CustomLi>
                      })}
                    </ul>
                  </CurrentPlans>
                </CustomRoundBox>
              }
            </React.Fragment>
          }
        </div>
        <div>
          <h2 lh='auto'>&nbsp;</h2>
        </div>
      </div>
    </section>
  )
}

export default ProfileSetting;
