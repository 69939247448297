import styled from "styled-components";
import "./SignUp.scss";
import { FieldInput } from '../../Component/FieldInput'
import { userIcon, googleIcon, SelectArrow, keyIcon, emailIcon, EyeClose, EyeOpen } from '../../Assets/Images'
import { Button } from '../../Component/Button';
import { Formik, Form } from 'formik';
import { HeadingLarge } from '../../Component/Heading';
import { signupFormValidate } from '../../Validations';
import TextSeparator from '../../Component/TextSeparator';
import SearchSelect from '../../Component/SearchSelect'
import countries from '../../Helpers/countries'
import { useEffect, useState } from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { PostSignUp, EmptyErrorMsg } from '../../actions/auth';
import { useNavigate } from "react-router-dom";
import Loader from '../../Component/Loader';
import { InitialApiRoute } from '../../config/ApiConfig';
import { Section, Container, Content } from '../../Component/Containers';
// import routesName from '../../Helpers/routesName';
// import { ErrorMessage } from "../../Component/Error";
import { Error } from '../../Component/Inputs';

// Custom Section
const CustomContent = styled(Content)``;

// Form Div
const FormDiv = styled.div``;

// Form Title
let Title = styled(HeadingLarge)``;

// Form Row
const FormRow = styled.div``;

// Form Row
const FormColumn = styled.div``;

// ContinueWithEmail
const ContinueWithEmail = styled(Button)``;

// ButtonGoogle
const ButtonGoogle = styled(Button)``;

const SignUp = () => {

  const authState = useSelector((state) => state.Auth)
  const dispatch = useDispatch();
  const goto = useNavigate();
  const [loader, setLoader] = useState(false);
  const [passState, setPassState] = useState(false);
  // const { dashboard } = routesName;

  useEffect(() => {
    authState.auth && goto("/dashboard");
    authState.errorCall === "signUp" && setLoader(false);
  }, [authState, goto])

  return (
    <Section>
      <div className="container">
        <div id="signup-form">
          <h1>Get your free account</h1>
          <div>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                userName: '',
                email: '',
                password: '',
                phoneNo: '',
                country: '',
                state: ''
              }}
              validationSchema={signupFormValidate}
              onSubmit={values => {
                setLoader(true);
                dispatch(EmptyErrorMsg());
                dispatch(PostSignUp(values));
              }}
            >
              {formik => (
                <div>
                  <Form autoComplete="off">
                    <FormColumn className="form-column">
                      <FieldInput className="has-icon" label="firstName" name="firstName" type="text" placeholder="First Name" src={userIcon} />
                      <FieldInput className="has-icon" label="lastName" name="lastName" type="text" placeholder="Last Name" src={userIcon} />
                    </FormColumn>
                    <FormRow className="form-row">
                      <FieldInput className="has-icon" label="email" name="email" type="email" placeholder="Email" src={emailIcon} />
                    </FormRow>
                    <FormRow className="form-row">
                      <FieldInput className="has-icon" label="userName" name="userName" type="text" placeholder="User Name" src={userIcon} />
                    </FormRow>
                    <FormRow className="form-row">
                      <FieldInput className="has-icon" id="targetTogglePassword" label="password" name="password" type={passState ? "text" : "password"} placeholder="Create a password" src={keyIcon} />
                      <button type="button" id="togglePassword" onClick={(e) => { e.preventDefault();setPassState(!passState) }}>
												<img src={passState ? EyeOpen : EyeClose} alt="" />
											</button>
                    </FormRow>
                    <FormRow className="form-row">
                      <FieldInput label="phoneNo" name="phoneNo" type="text" placeholder="Phone Number" />
                    </FormRow>
                    <FormRow className="form-row">
                      <SearchSelect label="country" name="country" placeholder="Select a country" src={SelectArrow} options={countries} />
                    </FormRow>
                    <FormRow className="form-row">
                      <FieldInput label="state" name="state" type="text" placeholder="State" />
                    </FormRow>
                    <FormRow className="form-row mb-0">
                      <button type="submit" className="btn btn-capsule bg-blue btn-block" disabled={loader}>{loader ? <Loader /> : "Continue with Email"}</button>
                    </FormRow>
                  </Form>
                </div>
              )}
            </Formik>
            {
              authState.errorCall === "signUp" && <Error><p className='error'>{authState.errorRespMsg}</p></Error>
            }
          </div>
          <div>
            <TextSeparator text="or" />
          </div>
          <div>
            <button className="btn btn-capsule btn-block google-btn"
              onClick={() => window.location.href = `${InitialApiRoute}/auth/google`}>
              <img src={googleIcon} alt="" />
              <span>Continue with Google</span>
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SignUp;