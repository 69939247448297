import { Dialog } from '@mui/material';
import React, { useRef, useState } from 'react'
import useStyles from '../Dialog/DialogStyle';
import { LoadUploadedImage } from '../services/utilService';

const ImagePreview = ({ gallery = [], setOpen }) => {

    const classes = useStyles();
    const navigation = (e) => {
    }
    const onClose = (e) => {
        setOpen("")
    }
    return (

        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            className={"gallery-popup " + classes.dialog}>

            <div className="zoom-image">
                <img src={LoadUploadedImage(gallery[0])} onError={(e) => { e.target.src = LoadUploadedImage("") }}></img>
            </div >
        </Dialog>
    )
}

export default ImagePreview;


