import React, { useState } from "react";
import styled from "styled-components";
import EditIcon from "../../Component/EditIcon";
import { H2 } from "../../Component/Heading";
import { P1, P2 } from "../../Component/Para";
import { Button, OnlyButton } from "../../Component/Button";
import { useSelector, useDispatch } from "react-redux";
import { TextAreaInput, FieldInput } from "../../Component/FieldInput";
import { UpdateProfile } from "../../actions/profile";
import Loader from "../../Component/Loader";
import { Formik, Form } from 'formik';
import { BioDetailFormValidate } from "../../Validations";
import { Error, FormButtonsDiv } from "../../Component/Inputs";

const CustomDiv = styled.div`
    width:100%;
    padding:2em 1em;

    & h2{
        margin-bottom:1em;
    }
    & .aboutSelf{
        display:flex;
        justify-content:space-between;
        flex-wrap:wrap;
		margin: 2em 0;
}
        & p{
            max-width:36em;
            line-height:1.4em;
            font-size
        }
    }
`
// Form Row
const FormRow = styled.div`
	margin-bottom:15px;
	width: 90%;
`

// submit button
const SubmitButton = styled(Button)`
	height:2.2em;
	min-height:35px;
	display: grid;
	place-items: center;
`

// Field Label
const FieldLabel = styled(P1)`
	margin-bottom:10px;
`

const AboutMe = ({ loader, setLoader, edit, setEdit }) => {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.Profile.userProfile);
	const authState = useSelector((state) => state.Auth);
	const [viewMore, setViewMore] = useState(false);

	return (
		<CustomDiv>
			{
				!edit &&
				<React.Fragment>
					{profile.profileTitle ? <H2>{profile.profileTitle}</H2> : <H2>Add Title</H2>}
					<div className="aboutSelf">
						{!viewMore ? profile.selfDescription ?
							(profile.selfDescription.length > 300) ? <P2>{profile.selfDescription.slice(0, 300) + "......."}</P2> : <P2>{profile.selfDescription}</P2>
							: <P2>Add Description</P2> : <P2>{profile.selfDescription}</P2>}
						<EditIcon isEdit={edit} whenClick={() => setEdit(!edit)} />
					</div>
					{profile.selfDescription && (profile.selfDescription.length > 300) &&
						<button className="btn btn-capsule btn-outline bg-blue" onClick={() => setViewMore(!viewMore)}> {viewMore ? "View less" : "View More"} </button>}
				</React.Fragment>
			}
			{
				edit &&
				<Formik
					initialValues={{
						profileTitle: profile.profileTitle,
						selfDescription: profile.selfDescription,
					}}
					validationSchema={BioDetailFormValidate}
					onSubmit={values => {
						setLoader(true);
						dispatch(UpdateProfile(values));
					}}
				>
					{formik => (
						<div>
							<Form style={{ width: '100%' }} autoComplete="off"> {/*Formik Form Import from Formik*/}
								<FormRow>
									<FieldLabel> Title </FieldLabel>
									<FieldInput label="profileTitle" name="profileTitle" placeholder="Title" />
								</FormRow>
								<div className="aboutSelf">
									<FormRow>
										<FieldLabel> Description </FieldLabel>
										<TextAreaInput label="selfDescription" name="selfDescription" placeholder="Description" />
									</FormRow>
									<EditIcon isEdit={edit} whenClick={() => setEdit(!edit)} />
								</div>
								<FormButtonsDiv style={{ width: '90%' }}>
									{
										authState.errorCall === "updateProfile" && <Error> <p>{authState.errorRespMsg}</p> </Error>
									}
									<button className="btn btn-capsule bg-blue" type="submit" disabled={loader}> {loader ? <Loader /> : "Save"} </button>
								</FormButtonsDiv>
							</Form>
						</div>
					)}
				</Formik>
			}
		</CustomDiv>
	)
}

export default AboutMe