const routesName = {
  root: "/",
  login: "/login",
  signUp: "/create-account",
  dashboard: "/dashboard",
  createTrade: "/create-trade",
  viewTrade: "/view-trade/:id",
  myTrades: "/my-trades",
  myOffers: "/my-offers",
  messages: "/messages",
  profile: {
    root: "/user-profile",
    setting: "/profile-setting",
  },
  viewProfile: "/view-profile/:id",
  googleCallback: "/callback",
  pricing: "/pricing-plans",
  payment: "/payment/:id",
  notFound: "/*",
};

export default routesName;
