export const mySkills = ['Accounting', 'Video Editing', 'Art & Illustration', 'Branding', 'Website Development']

export const filterBy = ['My Feed', 'Recommended']


export const tradeList = [
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'London, United Kingdom',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'Barcelona',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'Pakistan',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'Nepal',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'Italy',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'United State',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'Bangladesh',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
    {
        profileSrc:'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png',
        userTitle:'Expert Designer - Mobile & Web',
        location:'SriLanka',
        tradeTitle:'Looking for help with accounting.',
        postedTime:'Posted 1 hour ago',
        tradeDescription:'I’m looking for someone that can provide direction financially to build a better structure for my business. Currently, I work with about 5-7 clients each month and track everything in excel. Eventually, I would like to move everything into one place and set recurring invoices.',
        lookingFor:['Accountant', 'Finance', 'Accounting', 'Budgeting', 'Hosting'],
        inExchangeFor:['Web', 'Hosting', 'UX', 'Wordpress', 'Design']
    },
]