import "./TextSeparator.scss";

const TextSeparator = ({text}) => {
    return (
        <div className="text-separator">
            <div></div>
            <p>{text}</p>
            <div></div>
        </div>
    )
}

export default TextSeparator
