import axios from "axios";
import { TradeList, ErrorMessage, MyTrades, OpenTrade, AddTrade, RateTrade, EmptyMyTrades, EmptyTradeList, DeleteTrade, FetchingTrades, TradeUnsaved } from "../../actionTypes/ActionTypes";
import { InitialApiRoute } from "../../config/ApiConfig";
import { EmptyErrorMsg } from "../auth";

const GetAllTrades = (filter = {}) => {
  return (dispatch) => {


    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    let queryParams = ""
    Object.keys(filter).map((key, i) => {
      queryParams += (i == 0) ? "?" : "&";
      queryParams += filter[key] ? (key + "=" + filter[key]) : ""
    })
    dispatch({
      type: FetchingTrades,
      payload: true,
    });

    axios
      .get(`${InitialApiRoute}/user/trade/get_all_trades` + queryParams, ProtectedApiConfig)
      .then((resp) => {

        dispatch({
          type: FetchingTrades,
          payload: false,
        });

        dispatch({
          type: TradeList,
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FetchingTrades,
          payload: false,
        });

        const error = {
          message: err.response.data.message,
          call: "tradeList",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};


const GetMyAllTrades = (filter = {}) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    let queryParams = ""
    Object.keys(filter).map((key, i) => {
      queryParams += (i == 0) ? "?" : "&";
      queryParams += filter[key] ? (key + "=" + filter[key]) : ""
    })
    dispatch({
      type: FetchingTrades,
      payload: true,
    });


    axios
      .get(`${InitialApiRoute}/user/trade/get_user_trades` + queryParams, ProtectedApiConfig)
      .then((resp) => {
        dispatch({
          type: MyTrades,
          payload: resp.data,
        });
        dispatch({
          type: FetchingTrades,
          payload: false,
        });
      })
      .catch((err) => {

        dispatch({
          type: FetchingTrades,
          payload: false,
        });
        const error = {
          message: err.response.data.message,
          call: "tradeList",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const GetCurrentTrade = (_id) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: FetchingTrades,
      payload: true,
    });
    axios
      .get(`${InitialApiRoute}/user/trade/get_trade/` + _id, ProtectedApiConfig)
      .then((resp) => {
        dispatch({
          type: OpenTrade,
          payload: resp.data,
        });
        dispatch({
          type: FetchingTrades,
          payload: false,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "currentTrade",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
        dispatch({
          type: FetchingTrades,
          payload: false,
        });
      });
  };
};

const FilterTrades = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: FetchingTrades,
      payload: true,
    });
    axios
      .post(`${InitialApiRoute}/user/trade/interest_trades`,
        data, ProtectedApiConfig)
      .then((resp) => {
        dispatch({
          type: TradeList,
          payload: resp.data,
        });
        dispatch({
          type: FetchingTrades,
          payload: false,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "tradeList",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
        dispatch({
          type: FetchingTrades,
          payload: false,
        });
      });
  };
};

const CreateATrade = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${InitialApiRoute}/user/trade/create_trade`, data, ProtectedApiConfig)
      .then((resp) => {
        dispatch(EmptyErrorMsg())

        dispatch({
          type: TradeUnsaved,
          payload: ""
        })
        dispatch({

          type: AddTrade,
          payload: resp.data.tradeCreated,
        });

      })
      .catch((err) => {

        if (err.response.data.message === 'Please purchase pricing plan for posting trade') {
          dispatch({
            type: TradeUnsaved,
            payload: data
          })
        }

        const error = {
          message: err.response.data.message,
          call: "createTrade",
        };

        console.log(error)
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const InviteToTrade = (data) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${InitialApiRoute}/user/trade/create_trade`, data, ProtectedApiConfig)
      .then((resp) => {
        dispatch(EmptyErrorMsg())

        dispatch({
          type: AddTrade,
          payload: resp.data.tradeCreated,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "createTrade",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};


const Delete = (_id) => {
  return (dispatch) => {
    const ProtectedApiConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .delete(`${InitialApiRoute}/user/trade/delete_trade/` + _id, ProtectedApiConfig)
      .then((resp) => {
        dispatch(EmptyErrorMsg())
        dispatch({
          type: DeleteTrade,
          payload: _id,
        });
      })
      .catch((err) => {
        const error = {
          message: err.response.data.message,
          call: "deleteTrade",
        };
        dispatch({
          type: ErrorMessage,
          payload: error,
        });
      });
  };
};

const EmptyMyTradeList = (data) => ({ type: EmptyMyTrades, payload: data });
const EmptyList = (data) => ({ type: EmptyTradeList, payload: data });
// const CurrentTrade = (data) => ({ type: OpenTrade, payload: data });

export { GetAllTrades, CreateATrade, FilterTrades, GetCurrentTrade, GetMyAllTrades, EmptyMyTradeList, EmptyList, Delete };
