import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, useField } from 'formik';
import { Input, Error } from './Inputs';

const SearchSelect = ({ src, options, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <div>
        <Input
          list="countries"
          isIcon={src}
          err={meta.touched && meta.error}
          {...props}
          {...field}

        />
      </div>
      <div>
        <datalist id="countries">
          {options.map(val => {
            return <option key={val.code} value={val.label}>{`${val.label} ${val.code}`}</option>
          })}
        </datalist>
        <ErrorMessage component={Error} name={field.name} className="error" />
      </div>
    </div>
  );
}

export const AddTags = ({ src, options, ...props }) => {
  return (
    <div>
      <div>
        <Input
          list="skills"
          {...props}
        />
      </div>
      <div>
        <datalist id="skills">
          {options.map(val => {
            return <option key={val._id} value={val.skillName}>{val.skillName}</option>
          })}
        </datalist>
      </div>
    </div>
  );
}


export default SearchSelect;