import styled from 'styled-components'
import "./MeetTheTraders.scss";
import { Container, Content, Section } from '../../../Component/Containers'
import { HeadingLarge } from '../../../Component/Heading'
import { Trader1, Trader2, Trader3 } from '../../../Assets/Images'
import { ParaMedium } from '../../../Component/Para'


const CustomSection = styled(Section)``;

const Title = styled(HeadingLarge)``;

const TraderName = styled(HeadingLarge)``;

const TraderIntro = styled(ParaMedium)``;

const TraderSkill = styled(TraderIntro)`
    margin-block:0.1em .5em;
    font-size:1.5em;
    color:var(--blue);
    `;

const Traders = styled.div``;

const Trader = styled.div``;


const MeetTheTraders = () => {
  return (
    <section id='meet-the-traders'>
      <div class='container'>
        <h2 class='title'>Meet the Traders</h2>
        <ul class="traders-list">
          <li>
            <div class='image'>
              <img src={Trader1} alt="Traders" width="" height="auto" />
            </div>
            <h3>Jeff Martin</h3>
            <TraderSkill>Web Developer</TraderSkill>
            <div class="intro">Hey! I am a web designer and am looking for a UI/UX designer to barter my web development skills with!</div>
          </li>
          <li class='active'>
            <div class='image'>
              <img src={Trader2} alt="Traders" width="" height="auto" />
            </div>
            <h3>Rini Mayer</h3>
            <TraderSkill>Accountant</TraderSkill>
            <div class="intro">Crunching numbers is my passion. I have been an accountant since 2010 and have a strong grip on accountancy and taxation.</div>
          </li>
          <li>
            <div class='image'>
              <img src={Trader3} alt="Traders" width="" height="auto" />
            </div>
            <h3>Sara Lobo</h3>
            <TraderSkill>Logo Designer</TraderSkill>
            <div class="intro">I am an expert logo designer, who has 4 years of experience in the design field! I am looking for someone that could help me with digital sales.</div>
          </li>
        </ul>
      </div>
    </section>

  )
}

export default MeetTheTraders;