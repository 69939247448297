import React from "react";
import PricingPlans from "../Home/PricingPlans";

const PricingAndPlan = () => {

  return (
    <div>
      <PricingPlans page={true} />
    </div>
  );
};

export default PricingAndPlan;
