import { Section, GridContainer, RoundBox } from "../../../Component/Containers";
import { createOfferFormValidate } from "../../../Validations";
import styled from "styled-components";
import { H1 } from "../../../Component/Heading";
import { DividerX } from '../../../Component/Divider'
import { Form, Formik } from "formik";
import { FieldInput, TextAreaInput, CheckBox } from "../../../Component/FieldInput";
import { AddTags } from '../../../Component/SearchSelect';
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, ButtonOutline } from '../../../Component/Button'
import { DynamicTags } from "../../../Component/Tags";
import { P1 } from "../../../Component/Para";
import { CreateAnOffer } from "../../../actions/offers";
import { EmptyErrorMsg } from '../../../actions/auth';
import Loader from '../../../Component/Loader';
import { useNavigate } from "react-router-dom";
import routesName from "../../../Helpers/routesName";
import { Error } from "../../../Component/Inputs";
import { size } from "../../../Helpers/breakpoint";


// Custom Section
const CustomSection = styled(Section)`
    background: var(--whiteF0);
    min-height:90vh;
`
// Custom Grid Container
const CustomGridContainer = styled(GridContainer)`
    grid-template-columns:auto 100% auto;
    margin-block:2em;

	@media only screen and (max-width: ${size.tablet}) {
		grid-template-columns:1em auto 1em;
	  }
	@media only screen and (max-width: ${size.btwMLT}) {
		width:95%;
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;

	}
`
// Grid Item
const GridItem = styled.div`
    width:100%;
    min-height:10px;
	margin-top:0.5em;
	margin-inline:auto;
`
// Custom Round Box
const CustomRoundBox = styled(RoundBox)``;

// Custom Round Box
const CustomDiv = styled.div`
		width:94%;
		margin-inline:auto;
		padding-block:1.5em;
		& .header{
			display:flex;
			justify-content:space-between;
			align-items:center;
		}
		& .titleHeading{
		}

`

// FormRow
const FormRow = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
  max-width : 26.55em;
  font-family: PoppinsRegular;


	& .description{
		margin-block:1.3em;
		& p{
			margin-block:.3em;
		}
	}
`
// FormRowButton
const FormRowButton = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	gap:1em;

`
// CustomButton
const CustomButtonOutline = styled(ButtonOutline)`
	width:9.8em;
	background-color: var(--whiteF0);
	color: var(--grey);
	border: 1px solid var(--grey);
	&:hover{
		color: var(--white);
		background-color: var(--grey);
	}
`

const CreateOffer = ({ tradeId, userId, userOfferedId, setOpen, setText }) => {
  const goto = useNavigate();
  const { myOffers } = routesName;

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [validationMsg, setValidationMsg] = useState(false);

  const [selectedLookingFor, setSelectedLookingFor] = useState([])
  const [lookingForTag, setLookingForTag] = useState();

  const [selectedExchangeFor, setSelectedExchangeFor] = useState([])
  const [exchangeForTag, setExchangeForTag] = useState();

  const skillList = useSelector(state => state.Profile.skillList);
  const authState = useSelector((state) => state.Auth)

  useEffect(() => {
    if (loader) {
      if (authState.errorCall == 'createOffer' && authState.errorRespMsg) {
        setLoader(false);
      }
      else {
        setText("You've made an Offer")
        setOpen(true);
        setTimeout(() => {
          setOpen(false)
          setText("")
          setLoader(false);
          // goto(myOffers)
        }, 2000);
      }
    }


  }, [authState, tradeId])

  // Remove Item
  const removeLookingForItem = (id) => {
    const filter = selectedLookingFor.filter((item) => item !== id)
    setSelectedLookingFor(filter)
  }
  // Remove Item
  const removeExchangeForItem = (id) => {
    const filter = selectedExchangeFor.filter((item) => item !== id)
    setSelectedExchangeFor(filter)
  }

  return (
    <div>
      <Formik
        initialValues={{
          offerTitle: '',
          description: '',
        }}
        validationSchema={createOfferFormValidate}
        onSubmit={values => {
          if (selectedExchangeFor.length !== 0) {
            const offer = {
              offerTitle: values.offerTitle,
              offerTradeTags: selectedExchangeFor,
              offerCreatedDate: new Date().toISOString(),
              offerDescription: values.description,
              tradeId: tradeId,
              userId: userId,
              userOfferedId: userOfferedId
            }
            setLoader(true);
            dispatch(CreateAnOffer(offer));



          } else {
            setValidationMsg(true);
          }
        }}
      >
        {formik => (
          <Form autoComplete="off"> {/*Formik Form Import from Formik*/}
            <CustomRoundBox className="custom_round_box">
              {/* Form Header */}
              <CustomDiv>
                <div className="titleHeading">
                  <H1 lh='auto' fontSize='1.4em'>Offer</H1>
                </div>
              </CustomDiv>
              <DividerX />
              {/* Form Fields */}
              <CustomDiv>
                <FormRow width="auto">
                  <FieldInput label="offerTitle" name="offerTitle" type="text" placeholder="Offer Name" style={{ fontSize: '1em' }} />
                </FormRow>

                {/* Exchange For --start-- */}
                <FormRow width="auto">
                  <div className="addTags">
                    <AddTags label="exchangeFor" name="exchangeFor" placeholder="In Exchange For" style={{ fontSize: '1em' }}
                      value={exchangeForTag} onChange={(e) => setExchangeForTag(e.target.value)} options={skillList} />
                    <button className="btn btn-capsule bg-blue" onClick={(e) => {
                      e.preventDefault()
                      let isExist = selectedExchangeFor.includes(exchangeForTag);
                      let isValid = skillList.filter((v) => v.skillName === exchangeForTag);
                      !isExist && isValid[0] &&
                        setSelectedExchangeFor([...selectedExchangeFor, exchangeForTag])
                      setExchangeForTag('')
                    }}>Add</button>
                    {/* <P1 fontSize='.9em' >List your top 5 skills and services that you would like to offer.</P1> */}
                    {selectedExchangeFor?.length > 0 && (
                      <DynamicTags tagColor="whiteF0" list={selectedExchangeFor} removeItem={removeExchangeForItem} />
                    )}
                  </div>
                </FormRow>

                <FormRow>
                  <div className="description">
                    <p fontSize='1em'><b>Description</b></p>
                    <p fontSize='.8em'>Explain what you are offering</p>
                  </div>
                </FormRow>
                  <TextAreaInput label="description" name="description" placeholder="Add description" style={{ fontSize: '1em' }} />
                <FormRow>
                  {
                    (authState.errorCall === "createOffer") && <Error> <p className="error">{authState.errorRespMsg}</p> </Error>
                  }
                  {
                    (authState.errorCall === "createOffer") ? "" : (validationMsg && <Error> <p className="error"> "Please Add tags" </p> </Error>)
                  }
                </FormRow>
              </CustomDiv>
            </CustomRoundBox>

            <FormRowButton>
              <button className="btn btn-outline btn-capsule bg-charcoal-300" type="button" onClick={() => goto(-1)}>Go Back</button>
              <button className="btn btn-capsule bg-blue" type="submit" disabled={loader} > {loader ? <Loader /> : "Apply for Trade"}</button>
            </FormRowButton>
          </Form>
        )}
      </Formik>
    </div>
  )

}

export default CreateOffer
