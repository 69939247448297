import {
  firstTooltip,
  secondTooltip,
  thirdTooltip,
  fourthTooltip,
} from "../../../Assets/Images";

export const pricingPlans = [
  {
    id: "1",
    title: "Pay Per Trade",
    price: "12",
    perText: "per Trade Request",
    list: [
      {
        info: true,
        text: "Post 1 Trade Request",
        infoText:
          "Get started by telling us your business needs by posting a trade request!",
        src: firstTooltip,
      },
      {
        info: true,
        text: "Receive up to 3 Offers",
        infoText:
          "Offers are proposals from other entrepreneurs applying to barter with you!",
        src: secondTooltip,
      },
      {
        info: true,
        text: "Make Unlimited Offers",
        infoText: "Make unlimited offers to other entrepreneurs!",
        src: thirdTooltip,
      },
    ],
  },
  {
    id: "2",
    title: "Advance Trader",
    price: "67",
    perText: "per Month",
    list: [
      {
        info: false,
        text: "Post Unlimited Trade Requests",
        infoText: "",
      },
      {
        info: false,
        text: "Receive Unlimited Offers",
        infoText: "",
      },
      {
        info: false,
        text: "Make Unlimited Offers",
        infoText: "",
      },
      {
        info: true,
        text: "Invite Unlimited People to Make Offers to Your Trade Request",
        infoText:
          "See someone you would like to work with? Share your personal link with a fellow entrepreneur, to make an offer!",
        src: fourthTooltip,
      },
    ],
  },
];
