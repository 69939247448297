import { RoundBox } from "../../Component/Containers";
import { Input } from "../../Component/Inputs";
import styled from "styled-components";
import { Button } from "../../Component/Button";
import { size } from "../../Helpers/breakpoint";
import { crossSvg_grey } from "../../Assets/Images";

export const CustomRoundBox = styled(RoundBox)``;

export const GridItem = styled.div`


`
export const SearchInput = styled(Input)``;

export const ChatWith = styled.div`
`
export const CustomInput = styled(Input)`
height:3em;
min-height:35px;
background:var(--whiteF0);
border-radius: 30px;
padding-inline: .6em;
border: 0px;
&:hover{
	border-color:${props => props.err ? 'var(--red)' : 'transparent'};
}

`
export const CustomButton = styled(Button)`
	border-radius: 30px;
	min-height:35px;
`
export const Conversation = styled.ul`
	list-style-type:none;
	display:flex;
	flex-direction:column;
	gap:.2em;
	padding:1em;
`
export const Li = styled.li`
	width:auto;
	padding:.5em 1em;
	border-radius:15px;
	max-width:25em;
	min-width:4em;
	cursor:default;
	display:flex;
	flex-direction:column;
	gap:.8em;

`
export const Sender = styled(Li)`
	background:var(--whiteF0);
	align-self:flex-end;
`
export const Reciever = styled(Li)`
	background:var(--blue);
	align-self:flex-start;
`