import { SkillsList, UserProfile, ViewProfileDetails, ProfilePicture, SetUserRating, Notifications, GotNotification, UpdateNotifcation, SetCanMessage } from "../actionTypes/ActionTypes";

const initailState = {
  userProfile: "",
  skillList: [],
  viewProfile: "",
  ratings: "",
  notifications: [],
  canMessage: ""
};

const Profile = (state = initailState, action) => {
  switch (action.type) {
    case UserProfile:
      return { ...state, userProfile: action.payload };
    case SetCanMessage:
      return { ...state, canMessage: action.payload };
    case SkillsList:
      return { ...state, skillList: action.payload };
    case ViewProfileDetails:
      return { ...state, viewProfile: action.payload };
    case ProfilePicture:

      state.userProfile.image = action.payload
      return { ...state, userProfile: state.userProfile };

    case SetUserRating:
      return { ...state, ratings: action.payload };
    case Notifications:
      let list = action.payload.offers.concat(action.payload.invites.map(x => { x.type = 'invite'; return x }))
      list = list.sort((a, b) => {
        return new Date(b.offerCreatedDate || b.createdDate) - new Date(a.offerCreatedDate || a.createdDate);
      })
      return { ...state, notifications: list };
    case GotNotification:
      action.payload.unseen = true
      return {
        ...state,
        notifications: [action.payload, ...state.notifications]
      }
    case UpdateNotifcation:

      let index = state.notifications.findIndex(x => x["_id"] === action.payload._id);
      let newArray = [...state.notifications];
      newArray[index] = action.payload.data
      return {
        ...state,
        notifications: newArray,
      }

    default:
      return state;
  }
};

export default Profile;
