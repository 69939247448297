import React, { useState } from "react";
import styled from "styled-components";
import { H2 } from "../../Component/Heading";
import { P2 } from "../../Component/Para";
import { Button, OnlyButton } from "../../Component/Button";

const CustomDiv = styled.div`
    width:100%;
    padding:2em 1em;

    & h2{
        margin-bottom:1em;
    }
    & div{
        display:flex;
        justify-content:space-between;
        flex-wrap:wrap;
        & p{
            max-width:36em;
            line-height:1.4em;
            font-size
        }
    }
`
const AboutMe = ({ userDetail }) => {
  const [viewMore, setViewMore] = useState(false);

  return (
    <CustomDiv>
      {userDetail?.profileTitle ? <H2>{userDetail?.profileTitle}</H2> : <H2> </H2>}
      {/* <H2>Motion Graphics Animator</H2> */}
      <div className="mb-1">
        {!viewMore ? userDetail.selfDescription ?
          (userDetail.selfDescription.length > 300) ? <P2>{userDetail.selfDescription.slice(0, 300) + "......."}</P2> : <P2>{userDetail.selfDescription}</P2>
          : <P2> </P2> : <P2>{userDetail.selfDescription}</P2>}
        <P2></P2>
      </div>
      {userDetail.selfDescription && (userDetail.selfDescription.length > 300) &&
        <button className="btn btn-capsule bg-blue" onClick={() => setViewMore(!viewMore)}> {viewMore ? "View less" : "View More"} </button>}

    </CustomDiv>
  )
}

export default AboutMe