const { ProfileImage } = require("../Assets/Images");

module.exports = {

    LoadUploadedImage: (url) => {
        return url ? (process.env.REACT_APP_URL + '/' + url) : ProfileImage
    },
    Debounce: (func, delay) => {
        let debounceTimer
        return function () {
            const context = this
            const args = arguments
            clearTimeout(debounceTimer)
            debounceTimer
                = setTimeout(() => func.apply(context, args), delay)
        }
    },
    // Debounce(func, wait) {

    //     let timeout;
    //     return (...args) => {
    //         clearTimeout(timeout);
    //         timeout = setTimeout(() => func(...args), wait);
    //     };

    // },

    UploadFile(file) {

        return new Promise((res, rej) => {
            try {

                let xhr = new XMLHttpRequest()
                xhr.open("POST", process.env.REACT_APP_URL + '/static/upload');
                xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`)

                let formData = new FormData()
                formData.append('upload', file);

                xhr.onreadystatechange = (e) => {

                    if (xhr.readyState == 4) {
                        if (xhr.status == 200) res(JSON.parse(xhr.responseText))
                        else rej("unable to uppload file")
                    }
                }
                xhr.send(formData)

            } catch (error) {
                rej(error, "unable to upload file")
            }

        });


    }


}