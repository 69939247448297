import { H2 } from "../../Component/Heading";
import EditIcon from "../../Component/EditIcon";
import { StaticTags } from "../../Component/Tags";
import React from "react";
import Modal from "../../Component/Modal";
import { useSelector } from "react-redux";


const TopSkills = ({ loader, setLoader, view, setView }) => {
  const skillList = useSelector(state => state.Profile.skillList);
  const profile = useSelector(state => state.Profile.userProfile);

  return (
      <div className="topSkillsContent">
        
        <H2>Top Skills</H2>
        <EditIcon whenClick={() => setView(true)} />
        <StaticTags list={profile?.tags} />
        {view && < Modal title='Edit Top Skills' setView={setView} skills={skillList}
          loader={loader} setLoader={setLoader} />}
      </div>
  )
}

export default TopSkills