import styled from "styled-components"
import { Button } from "../../Component/Button"
import routesName from "../../Helpers/routesName"
import { useNavigate } from "react-router-dom"
import UserWithInfo from "./UserWithInfo"

const CustomButton = styled(Button)`
    width:auto;
    padding-inline:1.6em;
    font-size:.8em;
    min-height:35px;
    align-self:flex-start;
    &:hover{
        background-color: var(--blue-dark);
    }
`


const ProfileHeader = () => {
  const { profile } = routesName
  const goto = useNavigate()

  return (
    <div className="profile-header">
      <UserWithInfo />
      <button className="btn btn-capsule bg-blue" id="profile-settings-btn" onClick={() => goto(profile.setting)} >Profile Settings</button>
    </div>
  )
}

export default ProfileHeader