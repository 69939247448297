
import "./GlobalSearchView.scss";

import { Container, Content, GridContainer, RoundBox, Section } from './Containers'
import { size } from '../Helpers/breakpoint'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import routesName from '../Helpers/routesName'
import styled from 'styled-components'
import { DividerX } from './Divider';
import { H1, H2 } from './Heading';
import { P1 } from './Para'
import { Debounce } from "../services/utilService";
import { useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../shared/BlurHandler";
import { SearchGlobal } from "../actions/common";
import { searchIcon } from "../Assets/Images";
import Loader from "./Loader";

// Custom Section
const CustomSection = styled(Section)`

`
// Custom Grid Container
const CustomGridContainer = styled(GridContainer)`
`
// Custom Grid Item
const CustomGridItem = styled.div`
  width:98%;
  margin-inline:auto;
  & .search{
    display:flex;
    justify-content:space-between;
    align-items:center;
    gap:1em;
    margin-block:2.5em;
    & button{
      min-width:9.8em;
    }
  }
  & .filters{
    margin-bottom:2em;
  }
`
// Grid Item
const GridItem = styled.div`
  width:100%;
  min-height:100px;
	margin-top:0.5em;
	margin-inline:auto;
  @media only screen and (max-width: ${size.tablet}) {
    min-height:1em;
  }
`

const SearchInputDiv = styled.div`
  width:100%;
  position:relative;
  font-size:.8em;

  & input{
    color: var(--blackashgreen) !important;
    background-color: var(--white) !important;
    border: none !important;
    padding-right:0em;
    padding-left:1.95em;
    min-height:2.8em;
    font-size:1em;
    border-radius:27px;
    &::-webkit-input-placeholder {
        color: var(--grey);
    }
    &::-webkit-search-cancel-button {
          -webkit-appearance: none;
    }
  }
`

// List
const List = styled.ul`
    font-family:PoppinsRegular;
    font-size:.8em;
    color:var(--grey);
    padding-block:1em;
    list-style-type:none;

    & .active{
        list-style-type:square;
        color:var(--blue);
    }
    & .activeFilter{
        color:var(--blue);
    }

`

// List Item
const Li = styled.li`
    cursor:pointer;
    margin-block:5px;
    &:hover{
        color:var(--blue);
    }
`

// Custom Side Round Box
const SideRoundBox = styled(RoundBox)`
    padding-inline:1.5em;

    & .filter {
      display:none;
    }

    @media only screen and (max-width: ${size.btwMLT}) {
      padding-inline:2.5em;
      min-height:1em;
      & .feed{
        display:none;
      }
      & .skills{
        display:none;
      }
      & .filter {
        & img{
          width:1.2em;
          transform:rotate(270deg);
        }
        & .reverse{
          transform:rotate(90deg);
        }
        width:100%;
        padding-block:1em;
        display:flex;
        justify-content:space-between;
        align-items:center;
      }
    }

`
// My Skills
const MySkills = styled.div`
`
// Each Trade Container
const EachTrade = styled.div`
  padding-block:.5em;
  padding-inline:2em;

  & .currentSelection{
    margin-block:.5em;
  }
`
// Button Container Container
const ButtonContainer = styled.div`
  padding-top:1em;
  padding-inline:2em;
  display:flex;
  justify-content:flex-end;
  & button{
    padding-inline:1em !important;
  }
`



const GlobalSearchView = ({toggle=undefined}) => {
  const dispatch = useDispatch();
  const goto = useNavigate()
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState("");

  let searchedTrades = useSelector((state) => state.Common.searchTrades);
  let searchedUsers = useSelector((state) => state.Common.searchUsers);

  const searchInput = useRef(null);
  const globalSearchRef = useRef(null);
  const { viewTrade, viewProfile } = routesName
  useOutsideAlerter(globalSearchRef, setFilter, false);

  const SearchGloballyWithDelay = Debounce(SearchGlobally, 1500)
  function SearchGlobally() {

    if (searchInput.current?.value) {
      setLoader(true)
      setFilter(true)
      dispatch(SearchGlobal(searchInput.current.value));
    }
    else {
      setLoader(false)
      setFilter(false)
      if(searchInput?.current?.props?.value)searchInput.current.props.value = ""
    }
  }
  useEffect(() => {
    setLoader(false)
  }, [searchedUsers, searchedTrades])

  function ViewUserProfile(_id) {
    setFilter("")
    goto(`/view-profile/${_id}`)
    if(toggle)toggle()
  }

  function ViewTrade(_id) {
    setFilter("")
    goto(`/view-trade/${_id}`)
    if(toggle)toggle()
  }

  function handleChange(e) {
    SearchGloballyWithDelay.apply(this)
  }

  return (
    <div id="global-search" ref={globalSearchRef}>
      <input ref={searchInput} className='input-capsule' onChange={handleChange} label="search" name="search" type="search" placeholder="Search users and trades"/>
      {filter ? <section className='dropdown'>
        <div className="container-fluid f-column">
          <div>
            <h3>Trades</h3>

            <DividerX />
            {searchedTrades.length ?

              <List>

                {searchedTrades.map((trade, ind) => {
                  return (
                    <Li style={{"font-size" : "12px"}} key={'_' + ind} onClick={() => ViewTrade(trade._id)}>{trade.tradeName} </Li>
                  )
                })
                }
              </List> : loader ? <Loader width="20px" height="20px" /> :
              <P1 className="noData center" color="blue" fontSize="1.5em" lh="auto"> No Trades found! </P1>            }
          </div>
          <div>
            <h3>Users</h3>

            <DividerX />
            {searchedUsers.length ?
              <List >
                {
                  searchedUsers.map((user, ind) => {
                    return (

                      <Li style={{"font-size" : "12px"}} key={'_' + ind} onClick={() => ViewUserProfile(user._id)}>{user.userName}</Li>

                    )
                  })
                }
              </List> : loader ? <Loader width="20px" height="20px" /> :
              <P1 className="noData center" color="blue" fontSize="1.5em" lh="auto"> No Users found! </P1>            }

          </div>
        </div>
      </section> : ""}
    </div>
  )
}

export default GlobalSearchView

