import React, { useRef, useState } from 'react'
import {
  Button,
} from "./../Component/Button";
import styled from "styled-components";
import Loader from "./../Component/Loader";

const Item = styled.li`
    flex-basis:30%;
    background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    // background:url(${({ image }) => image ? image : 'var(--whiteF0)'});
    background-size:cover;
    background-repeat:no-repeat;
    height:9.35em;
    border-radius:15px;
    margin:.5em;

    & .btn{
        border-radius:15px;
        width:inherit;
        height:inherit;
        background:var(--transparent);
        display:flex;
        justify-content:flex-end;
        transition:var(--trans_3s);
        padding:.5em;



        & img{
          visibility:hidden;
          cursor:pointer;
          &:hover{
            transform:scale(1.1);

          }
        }
        & div:hover{
          cursor:pointer;
          transition:var(--trans_3s);
          & svg #Ellipse_41{
            fill:var(--blue);
          }
          & svg #_x36_ path{
            fill:var(--white);
          }
        }
        &:hover{
          transition:var(--trans_3s);
          & img{
            visibility:visible;
          }
          background:var(--blackOp076);


        }
    }
`

const FileUploader = ({ accept, onFileSelectSuccess, onFileSelectError, type, icon }) => {
  const fileInput = useRef(null)

  const [selectedImage, setSelectedImage] = useState();


  const handleFileInput = (e) => {
    // handle validations
    let file = e.target.files[0]
    if (file.size > parseInt(process.env.REACT_APP_FILE_SIZE))
      onFileSelectError("File size cannot exceed more than 1MB");
    else
    onFileSelectSuccess(file, type);
  }

  return (

    <div className="file-uploader">
      <button onClick={e => { if (fileInput.current) fileInput.current.click() }} className="btn btn-capsule bg-blue">
        <img src={icon} alt="select file" />
      </button>
      <input accept={accept} type="file" ref={fileInput} hidden={true} onChange={handleFileInput} />
    </div >
  )
}

export default FileUploader;


