import "./HowDoesItWork.scss";
import SquarePictureAnim from '../../../Component/SquarePictureAnim'
import { TutorWomanHavingMeeting,HowDoesitWork2 } from '../../../Assets/Images'


const HowDoesItWork = () => {
  return (
    <section id="HowThisItWork">
      <div className="container">
        <h2>How Does it Work?</h2>
        <div className="hiw-content">
          <SquarePictureAnim picSrc={HowDoesitWork2} picName="tutor" />
          <div className="hiw-text">
            <p className="small">Barter-E is an online platform for entrepreneurs to connect with great talent, build your business, and take your company to the next level all by trading your skills, services, and products with professionals! <br /><br /> You can find your bartering partner in three simple steps:</p>
            <ul className='list'>
              <li>
                <span>1</span>
                <p className="small">Create a trade request explaining the type of services you need help with.</p>
              </li>
              <li>
                <span>2</span>
                <p className="small">Review your offers and select a professional to work with.</p>
              </li>
              <li>
                <span>3</span>
                <p className="small">Now it’s time to get the job done and write a review once you are complete.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowDoesItWork;