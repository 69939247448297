import { H1 } from "../../Component/Heading";
import { P1 } from "../../Component/Para";
import { RoundImage } from "../../Component/Images";
import { pinIcon, ProfileImage } from "../../Assets/Images";
import { useSelector } from "react-redux";
import { LoadUploadedImage } from "../../services/utilService";

const UserWithInfo = () => {
  const profile = useSelector(state => state.Profile.userProfile)

  return (
    <div className='user-info'>
      <div className='proImage'>
        <RoundImage alt="" src={LoadUploadedImage(profile.image)} onError={(e) => { e.target.src = LoadUploadedImage("") }} width='8.5em' height='8.5em' />
      </div>
      <div className="info">
        <H1 color="blue">{profile.firstName} {profile.lastName}</H1>
        <span>
          <img src={pinIcon} alt="Pin" width="" height="" />
          <P1>{(profile.state ? profile.state : "") + (profile.country ? (", " + profile.country) : "")}</P1>
        </span>
      </div>
    </div>
  )
}

export default UserWithInfo