import styled from "styled-components";
import { Section, Container, Content } from '../../Component/Containers';
import StripeFormComp from './StripeFormComp';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { size } from "../../Helpers/breakpoint"
import { useNavigate } from 'react-router-dom'
import routesName from "../../Helpers/routesName";
import { useParams } from "react-router-dom";


// Custom Section
const CustomContent = styled(Content)`
	min-height: 200px;
	max-width: 752px;
	border: 1px solid var(--grey);
	margin-block: 2.5em;
	margin-inline: auto;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media only screen and (max-width: ${size.btwMLT}) {
		margin-inline:.2em;
	}
`

const StripeForm = () => {
	const [loader, setLoader] = useState(false);
	const { profile, createTrade } = routesName;
	const goto = useNavigate()
	const [open, setOpen] = useState(false);
	const authState = useSelector((state) => state.Auth);
	const payment = useSelector((state) => state.Payment.paymentDetails);
	const { trade } = useParams();

	useEffect(() => {
		if (authState.errorCall === "payment") {
			setLoader(false);
		} else if (payment?.error === false && loader == true) {
			setOpen(true);
			setLoader(false);
			setTimeout(() => { setOpen(false); goto(trade ? createTrade : profile.root) }, 3000)
		}
	}, [authState, payment])

	return (
		<Section>
			<Container>
				<CustomContent>
					<StripeFormComp loader={loader} setLoader={setLoader} open={open} setOpen={setOpen} />
				</CustomContent>
			</Container>
		</Section>
	)
}

export default StripeForm;
