import Logo from './Logo'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { dashboradLogo, notificationIcon, searchIcon, crossSvg } from '../Assets/Images'
import { useDispatch, useSelector } from 'react-redux'
import { LogOut } from '../actions/auth'
import routesName from '../Helpers/routesName'
import HamurgerComp from './Hamburger'
import { GetAllSkills, GetNotifications, GetProfile, ReceivedNotification, UpdateNotificationStatus } from "../actions/profile";
import { LoadUploadedImage } from '../services/utilService'
import GlobalSearchView from './GlobalSearchView'
import socketService from './../services/socketService'

import React from "react";
import { useRef } from 'react'
import useOutsideAlerter from '../shared/BlurHandler'
import { DividerX } from './Divider';


const DashboardHeader = () => {
  const dispatch = useDispatch();

  const goto = useNavigate()
  const { dashboard, profile, root, messages, myTrades, myOffers } = routesName
  let user = useSelector((state) => state.Auth.userDetails.userDetail?.id);
  let userProfile = useSelector(state => state.Profile.userProfile);
  let notifications = useSelector(state => state.Profile.notifications);
  let [tradeToggleMenu, setTradeToggle] = useState(false);
  let [notificationToggle, setToggleNotification] = useState(false);


  const tradeMenuRef = useRef(null);
  useOutsideAlerter(tradeMenuRef, setTradeToggle, false);
  const notifRef = useRef(null);
  useOutsideAlerter(notifRef, setToggleNotification, false);

  useEffect(() => {
    if (socketService.socket) {
      socketService.socket.on("trade-invite", (data) => {
        data.type = "invite"
        dispatch(ReceivedNotification(data))
      })
      socketService.socket.on("trade-offer", (data) => {
        data.type = "offer"

        dispatch(ReceivedNotification(data))
      })
    }
  }, [socketService.socket]);


  useEffect(() => {
  }, [userProfile]);

  useEffect(() => {
    user && dispatch(GetProfile(user))
    user && dispatch(GetNotifications())

    dispatch(GetAllSkills())


  }, [dispatch, user])

  function UpdateNotificationsSeenStatus(notif) {
    notif.unseen = false
    setToggleNotification(false)
    dispatch(UpdateNotificationStatus(notif._id, notif))
    ViewTrade(notif.tradeId._id)

  }

  function ViewTrade(_id) {
    goto(`/view-trade/${_id}`)
  }



  return (
    <section id='Header' className='backend-header' >
      <div className="container" >
        <div className='logo'>
          <Logo w='100%' h='auto' logoSrc={dashboradLogo} />
        </div>
        <ul className='links' >
          <li className="search-bar">
            <GlobalSearchView></GlobalSearchView>
          </li>
          <li className='menu-item' ref={tradeMenuRef}>
            <button className="btn btn-capsule bg-blue" onClick={() => setTradeToggle(!tradeToggleMenu)}>My Trades</button>

            {tradeToggleMenu ?
              (
                <ul className='sub-menu trade-dropdown' >
                  <li id="sub-menu-item-1" onClick={() => { setTradeToggle(false); goto(myTrades) }}>
                    <button  >
                      <span>My Trades</span>
                    </button>
                  </li>
                  <li id="sub-menu-item-1" onClick={() => { setTradeToggle(false); goto(myOffers) }}>
                    <button >
                      <span>My Offers</span>
                    </button>
                  </li>
                </ul>) : ""
            }

          </li>
          <li className='menu-item'>
            <button onClick={() => goto(messages)}>Messages</button>
          </li>
          <li className="bell-item notif" ref={notifRef}>
            {notifications.filter(x => x.unseen).length ? <span className="notif-count no-count"></span> : ''}
            <button className="bell-icon btn-round" onClick={() => setToggleNotification(!notificationToggle)} > <img src={notificationIcon} alt="" width="100%" height="auto" /></button>
            {

              notificationToggle ? <div className='dropdown center'>
                <h3>Notifications</h3>
                <ul>
                  {notifications.length ? notifications.map((notif, ind) => {
                    return (
                      <div>
                        <li className='' onClick={(e) => { UpdateNotificationsSeenStatus(notif) }}>
                          <p key={'_' + ind} >{notif.type == 'invite' ? ("You have been invited for a trade by " + (notif.invitedBy ? notif.invitedBy.userName : 'user')) : "You have a new offer for your Trade " + notif.tradeId.tradeName}
                            {/* <p>{notif.tradeId.tradeDescription.substring(0, 50)}</p> */}
                            {/* <button className='btn btn-link text-blue' }>See now</button> */}
                          </p>
                        </li>
                        <DividerX />
                      </div>

                    )

                  }) : "Not offered or invited yet"}
                </ul>
              </div>
                : ""}
            {/* <button onClick={() => goto('/')}><img src={notificationIcon} alt="" width="100%" height="auto" /></button> */}
          </li>

          <li className='profile-item'>
            <button className="btn btn-round profile-btn" onClick={() => goto(profile.root)}>
              <img src={LoadUploadedImage(userProfile.image)} onError={(e) => { e.target.src = LoadUploadedImage("") }}/>
            </button>
          </li>
          <li className='logout-item'>
            <button className='btn btn-capsule logout-btn' onClick={() => {
              dispatch(LogOut());
              goto(root);
            }}>
              <span className='sadFace'>Don't go {':('}</span>
              <span className='logout'>Logout</span>
            </button>
          </li>
        </ul >
        <HamurgerComp />
      </div >
    </section >
  )
}

export default DashboardHeader

