import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function SnackBarComponent() {
    const [open, setOpen] = React.useState(false);
    const authState = useSelector((state) => state.Auth)
    const dispatch = useDispatch()
    useEffect(() => {

        if (authState.errorCall && authState.errorRespMsg) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
            }, 3000);
        }
    }, [authState.errorCall])

    const handleToClose = (event, reason) => {
        if ("clickaway" == reason) return;
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
            }}
            open={open}
            autoHideDuration={5000}
            message={authState.errorRespMsg}
            onClose={handleToClose}
            action={
                <React.Fragment>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleToClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
}
