import { Section, GridContainer, RoundBox } from "../../../Component/Containers";
import { createTradeFormValidate } from "../../../Validations";
import styled from "styled-components";
import { H1 } from "../../../Component/Heading";
import { DividerX } from '../../../Component/Divider'
import { Form, Formik } from "formik";
import { FieldInput, TextAreaInput, CheckBox } from "../../../Component/FieldInput";
import { AddTags } from '../../../Component/SearchSelect';
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, ButtonOutline } from '../../../Component/Button'
import { DynamicTags } from "../../../Component/Tags";
import { P1 } from "../../../Component/Para";
import { CreateATrade } from "../../../actions/trade";
import { EmptyErrorMsg } from '../../../actions/auth';
import Loader from '../../../Component/Loader';
import { useNavigate } from "react-router-dom";
import routesName from "../../../Helpers/routesName";
import { GoBack } from "../../../Component/SquarePictureAnim";
import { backArrow, backIcon } from "../../../Assets/Images";
import { Error } from "../../../Component/Inputs";
import { size } from "../../../Helpers/breakpoint";
import DialogComp from "../../../Dialog/DialogComp";
import PricingPlans from "../../Home/PricingPlans";

// Custom Round Box
const CustomRoundBox = styled(RoundBox)``;

// Custom Round Box
const CustomDiv = styled.div`
		width:94%;
		margin-inline:auto;
		padding-block:1.5em;
		& .header{
			display:flex;
			justify-content:space-between;
			align-items:center;
		}
		& .titleHeading{
		}

`;

// FormRow
const FormRow = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
	max-width : 26.55em;
	& .description{
		margin-block:1.3em;
		& p{
			margin-block:.3em;
		}
	}
`;

// FormRowButton
const FormRowButton = styled.div`
	width:${({ width }) => width ? width : '100%'};
	margin-block:.8em;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	gap:1em;

`;

const CreateTrade = () => {
	const goto = useNavigate();
	const { dashboard, myTrades } = routesName;


	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [validationMsg, setValidationMsg] = useState(false);



	

	const skillList = useSelector(state => state.Profile.skillList);
	const authState = useSelector((state) => state.Auth)
	const trade = useSelector((state) => state.Trade.myTrades)
	const filledData = useSelector((state) => state.Trade.filledtrade)

	const [open, setOpen] = useState(false);
	const [unpaid, setUnpaid] = useState(false);

	const [selectedLookingFor, setSelectedLookingFor] = useState([])
	const [lookingForTag, setLookingForTag] = useState();
	const [selectedExchangeFor, setSelectedExchangeFor] = useState([])
	const [exchangeForTag, setExchangeForTag] = useState();

	useEffect(() => {

		if (loader) {
			if (authState.errorCall == 'createTrade' && authState.errorRespMsg) {
				setLoader(false);
				if (authState.errorRespMsg == 'Please purchase pricing plan for posting trade') setUnpaid(true);
			}
			else {
				setUnpaid(false)
				setOpen(true)
				setTimeout(() => {
					setLoader(false);
					setOpen(false)
					goto(myTrades)
				}, 2000);
			}
		}


	}, [authState], trade)

	useEffect(() => {
		if (filledData) {
			setSelectedExchangeFor(filledData ? filledData.offerTags : []);
			setSelectedLookingFor(filledData ? filledData.wantTags : [])
		}
	}, [filledData])

	// Remove Item
	const removeLookingForItem = (id) => {
		const filter = selectedLookingFor.filter((item) => item !== id)
		setSelectedLookingFor(filter)
	}
	// Remove Item
	const removeExchangeForItem = (id) => {
		const filter = selectedExchangeFor.filter((item) => item !== id)
		setSelectedExchangeFor(filter)
	}

	return (
		<section className="mytrade-section">
			<div className="container grid-container gc-center">
				<div>&nbsp;</div>
				{!unpaid ?
					<div>
						<div className="header">
							<h2 lh='auto' className="currentSelection">Create Trade</h2>
							<button className='go-back-btn' title="Back to feed"  onClick={() => goto(dashboard)}>
								<img src={backArrow} className="backArrow" alt="Back" width="" height="" />
								<img src={backIcon} className="back" alt="Back" width="" height="" />
							</button>
						</div>
						<Formik
							initialValues={{
								tradeName: filledData ? filledData.name : '',
								localOnly: filledData ? filledData.localOnly : false,
								description: filledData ? filledData.tradeDescription : "",
							}}
							validationSchema={createTradeFormValidate}
							onSubmit={values => {
								if ((selectedExchangeFor.length !== 0) && (selectedLookingFor.length !== 0)) {
									const tradeDetails = {
										name: values.tradeName,
										localOnly: values.localOnly,
										trade_created_Date: new Date(),
										wantTags: selectedLookingFor,
										offerTags: selectedExchangeFor,
										tradeDescription: values.description
									}
									// dispatch(EmptyErrorMsg());
									setLoader(true);
									dispatch(CreateATrade(tradeDetails));
									// setTimeout(() => {
									// 	setLoader(false)
									// 	goto(dashboard)

									// }, 1000);

								} else {
									setValidationMsg(true);
								}
							}}
						>
							{formik => (
								<Form autoComplete="off"> {/*Formik Form Import from Formik*/}
									<CustomRoundBox className="custom_round_box">
										{/* Form Header */}
										<CustomDiv>
											<div className="titleHeading">
												<H1 lh='auto' fontSize='1.4em'>{formik.values.tradeName !== '' ? formik.values.tradeName : 'Trade Title'}</H1>
											</div>
										</CustomDiv>
										<DividerX />
										{/* Form Fields */}
										<CustomDiv>
											<FormRow width="auto">
												<FieldInput className="input-lg" label="tradeName" name="tradeName" type="text" placeholder="Trade Name" />
											</FormRow>
											{/* Looking For --start-- */}
											<FormRow width="auto" className="addTags">
												<div className="addTags">
													<AddTags className="input" label="lookingFor" name="lookingFor" placeholder="Looking For" style={{ fontSize: '1em' }}
														value={lookingForTag} onChange={(e) => setLookingForTag(e.target.value)} options={skillList} />
													<button className="btn btn-capsule bg-blue" onClick={(e) => {
														e.preventDefault()
														let isExist = selectedLookingFor.includes(lookingForTag);
														let isValid = skillList.filter((v) => v.skillName === lookingForTag);
														!isExist && isValid[0] &&
															setSelectedLookingFor([...selectedLookingFor, lookingForTag])
														setLookingForTag('')
													}}>Add</button>
													{/* <P1 fontSize='.9em' >List your top 5 skills and services that you would like to offer.</P1> */}
													{selectedLookingFor?.length > 0 && (
														<DynamicTags tagColor="whiteF0" list={selectedLookingFor} removeItem={removeLookingForItem} />
													)}
												</div>
											</FormRow>
											{/* Looking For --end-- */}

											{/* Exchange For --start-- */}
											<FormRow width="auto">
												<div className="addTags">
													<AddTags label="exchangeFor" name="exchangeFor" placeholder="In Exchange For" style={{ fontSize: '1em' }}
														value={exchangeForTag} onChange={(e) => setExchangeForTag(e.target.value)} options={skillList} />
													<button className="btn btn-capsule bg-blue" onClick={(e) => {
														e.preventDefault()
														let isExist = selectedExchangeFor.includes(exchangeForTag);
														let isValid = skillList.filter((v) => v.skillName === exchangeForTag);
														!isExist && isValid[0] &&
															setSelectedExchangeFor([...selectedExchangeFor, exchangeForTag])
														setExchangeForTag('')
													}}>Add</button>
													{/* <P1 fontSize='.9em' >List your top 5 skills and services that you would like to offer.</P1> */}
													{selectedExchangeFor?.length > 0 && (
														<DynamicTags tagColor="whiteF0" list={selectedExchangeFor} removeItem={removeExchangeForItem} />
													)}
												</div>
											</FormRow>
											{/* Exchange For --end--*/}
											<FormRow width="auto">
												<CheckBox label='Local only' name="localOnly" id="localOnly" type="checkbox" style={{ fontSize: '1em' }} />
											</FormRow>
											<FormRow>
												<div className="description">
													<P1 style={{ "font-weight": "bold" }} fontSize='1em' >Description</P1>
													<P1 fontSize='.9em' >Describe what you need help with in your business as much as possible.</P1>
												</div>
											</FormRow>
											<TextAreaInput label="description" name="description" placeholder="Add Description" style={{ fontSize: '1em' }} />
											<FormRow>
												{
													(authState.errorCall === "createTrade") && <Error> <p className="error">{authState.errorRespMsg}</p> </Error>
												}
												{
													(authState.errorCall === "createTrade") ? "" : (validationMsg && <Error> <p className="error"> "Please Add tags" </p> </Error>)
												}
											</FormRow>
										</CustomDiv>
									</CustomRoundBox>

									<FormRowButton>
										<button className="btn btn-outline btn-capsule bg-charcoal-300" type="button" onClick={() => goto(dashboard)}>Go Back</button>
										<button className="btn btn-capsule bg-blue" type="submit" disabled={loader} > {loader ? <Loader /> : "Create Trade"}</button>
									</FormRowButton>
								</Form>
							)}
						</Formik>

					</div>
					: ""}
				<div>&nbsp;</div>
			</div>
			{open ? <DialogComp
				open={open}
				onClose={() => setOpen(false)}
				text="You've created a trade"
				sm={false}
				review={false}
			/> : ""}
			{unpaid ? <PricingPlans page={true} trade={true} /> : ""}

		</section>
	)
}

export default CreateTrade
