import "../Offers.scss";
import { iconsSprite } from "../../../Assets/Images";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { Delete } from "../../../actions/offers"
import { Button } from "../../../Component/Button"
import Loader from "../../../Component/Loader"
import routesName from "../../../Helpers/routesName"


const DeleteOfferComp = ({ _id, setOpen, setText, setGreetings }) => {
  const [loader, setLoader] = useState(false)
  const authState = useSelector((state) => state.Auth)
  const { myOffers } = routesName
  const goto = useNavigate();
  const dispatch = useDispatch();

  const DeleteOffer = () => {

    setLoader(true)
    dispatch(Delete(_id))
  }

  useEffect(() => {
    if (loader) {
      if (authState.errorCall == 'deleteOffer' && authState.errorRespMsg) {
        setLoader(false);
      }
      else {
        setGreetings(false)
        setText("Your Offer has been deleted")
        setOpen(true)
        setLoader(false);
        setTimeout(() => {
          setOpen(false)
          setText("")
          setGreetings(true)
          // goto(myOffers)
        }, 2000);
      }
    }
  }, [authState])
  return (
    <div>
      <button style={{"float" : "right"}} className="btn btn-capsule bg-blue" disabled={loader} onClick={(e) => DeleteOffer()} > {loader ? <Loader /> : "Delete"}</button>
    </div>
  )
}
export default DeleteOfferComp