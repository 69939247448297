import styles from './Login.scss'
import { FieldInput } from '../../Component/FieldInput'
import { userIcon, googleIcon, SelectArrow, keyIcon, emailIcon, EyeClose, EyeOpen } from '../../Assets/Images';
import { Button } from '../../Component/Button';
import styled from "styled-components";
import { Formik, Form } from 'formik';
import { HeadingLarge } from '../../Component/Heading';
import { loginFormValidate } from '../../Validations';
import TextSeparator from '../../Component/TextSeparator';
import { Container, Content, Section } from '../../Component/Containers';
import { useEffect, useState } from 'react';
import {
	useSelector,
	useDispatch
} from 'react-redux';
import { PostLogin, EmptyErrorMsg } from '../../actions/auth';
import { useNavigate } from "react-router-dom";
import Loader from '../../Component/Loader';
import { InitialApiRoute } from '../../config/ApiConfig';
import routesName from '../../Helpers/routesName';
import { Error } from '../../Component/Inputs';

const CustomContent = styled(Content)``;

// Form Row
const FormRow = styled.div``;

const Login = () => {
	const { sep } = styles

	const authState = useSelector((state) => state.Auth)
	const dispatch = useDispatch();
	//Navigations
	const goto = useNavigate();
	const [loader, setLoader] = useState(false);
	const [passState, setPassState] = useState(false);
	const { signUp } = routesName;

	useEffect(() => {
		authState.auth && goto("/dashboard");
		authState.errorCall === "login" && setLoader(false);
	}, [authState, goto]);

	return (
		<Section>
			<div className="container">
				<div id="login-form">
					<h1>Log in to Barter E</h1>
					<div>
						<Formik
							initialValues={{
								email: '',
								password: '',
							}}
							validationSchema={loginFormValidate}
							onSubmit={values => {
								setLoader(true);
								dispatch(EmptyErrorMsg());
								dispatch(PostLogin(values));
							}}
						>
							{formik => (
								<div>
									<Form autoComplete="off">
										<FormRow className="form-row">
											<FieldInput className="has-icon" label="Email" autofill name="email" type="email" placeholder="Username or Email" src={userIcon} />
										</FormRow>
										<FormRow className="form-row">
											<FieldInput className="has-icon" id="targetTogglePassword" label="password" name="password" type={passState ? "text" : "password"} placeholder="Password" src={keyIcon} />
											<button type="button" id="togglePassword" onClick={(e) => { e.preventDefault();console.log('pass'); setPassState(!passState) }}>
												<img src={passState ? EyeOpen : EyeClose} alt="" />
											</button>
										</FormRow>
										<FormRow className="form-row mb-0">
											<button type="submit" className="btn btn-capsule bg-blue btn-block" disabled={loader}>{loader ? <Loader /> : "Continue with Email"}</button>
										</FormRow>
									</Form>
								</div>
							)}
						</Formik>
						{
							authState.errorCall === "login" && <Error> <p className="error">{authState.errorRespMsg}</p> </Error>
						}
						<TextSeparator text="or" />
						<button className="btn btn-capsule btn-block google-btn"
							onClick={() => window.location.href = `${InitialApiRoute}/auth/google`}>
							<img src={googleIcon} alt="" />
							<span>Continue with Google</span>
						</button>
					</div>
					<div className="sep"></div>
					<div>
						<TextSeparator text="Don't have a Barter E account?" />
					</div>
					<div className='text-center'>
						<button type="submit" className="btn btn-capsule btn-outline bg-blue signup-btn" onClick={() => goto(signUp)}>Sign Up</button>
					</div>
				</div>
			</div>
		</Section>
	)
}

export default Login