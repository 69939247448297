import { ErrorMessage, useField } from 'formik';
import { Input, Error, InputTextArea, FieldCheckBox } from './Inputs';
import { P1 } from './Para';
import styled from 'styled-components';

const Ipt = styled.div`
    width: 100%;
    position: relative;
    & img{
        position: absolute;
        top: 50%;
        transform:translateY(-50%);
        left: 1em;
    }
`
const IptCheckBox = styled.div`
	cursor:pointer;
	display:flex;
	align-items:center;
	gap:5px;
`
const FieldInput = ({ src, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<Ipt>
			{/* {src && <img src={src} alt="" width="15px" height="15px" />} */}
			<Input
				err={meta.touched && meta.error}
				isIcon={src}
				{...field}
				{...props}
			/>
			<ErrorMessage component={Error} name={field.name} className="error" />
		</Ipt>
	)
}

const CheckBox = ({ label, ...props }) => {

	const [field, meta] = useField(props);

	return (
		<IptCheckBox>
			<FieldCheckBox
				err={meta.touched && meta.error}
				{...field}
				{...props}
			/>
			<label htmlFor={field.name}>
				<P1 color='grey' fontSize="0.8em">{label}</P1>
			</label>
		</IptCheckBox>
	)
}


const TextAreaInput = ({ ...props }) => {
	const [field, meta] = useField(props);

	return (
		<Ipt>
			<InputTextArea
				err={meta.touched && meta.error}
				{...field}
				{...props}
			/>
			<ErrorMessage component={Error} name={field.name} className="error" />
		</Ipt>
	)
}

export { FieldInput, TextAreaInput, CheckBox }
