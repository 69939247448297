import React, { useEffect } from "react";
import styled from "styled-components";
import { Section, GridContainer, RoundBox } from "../../Component/Containers";
import ProfileHeader from './ProfileHeader'
import AboutMe from "./AboutMe";
import TopSkills from "./TopSkills";
import Gallery from "./Gallery";
import { DividerX, DividerY } from '../../Component/Divider';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { OtherUserProfile } from "../../actions/profile";
import { size } from "../../Helpers/breakpoint";
import Ratings from "./../UserRatings";


const CustomGridContainer = styled(GridContainer)``;

const CustomRoundBox1 = styled(RoundBox)``;

const CustomDiv = styled.div`
	display:grid;
	grid-template-columns:30% 1px auto;

	@media only screen and (max-width: ${size.btwMLT}) {
		& .dividerY{
			width:100%;
			height:1px;
			background:var(--greyLight);
		}
		width:100%;
		margin-inline:auto;
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;

	}
`

const ViewProfile = () => {
	const dispatch = useDispatch();
	let user = useSelector((state) => state.Auth.userDetails.userDetail?.id);
	const conversation = useSelector((state) => state.Chat.newConversation);
	const userDetail = useSelector((state) => state.Profile.viewProfile);
	const canMessage = useSelector((state) => state.Profile.canMessage);
	const { id } = useParams();
	const goto = useNavigate();


	useEffect(() => {
		dispatch(OtherUserProfile(id));
		(conversation.length !== 0) && goto("/messages");
	}, [conversation, goto])

	return (
		<section className="profile-section profile-view-mg">
			<container class="container grid-container gc-center">
				<div></div>
				<div>
					<CustomRoundBox1 className="custom_round_box">
						<ProfileHeader userId={user} friendId={id} canMessage={canMessage} userDetail={userDetail} showInvite={true} showMessage={true} />
						<DividerX />
						<CustomDiv>
							<TopSkills userDetail={userDetail} />
							<DividerY className="dividerY" />
							<div className="aboutGallery">
								<AboutMe userDetail={userDetail} />
								<Gallery userDetail={userDetail} />
							</div>
						</CustomDiv>
					</CustomRoundBox1>
					<CustomRoundBox1 className="custom_round_box">
						<Ratings id={id}></Ratings>
					</CustomRoundBox1>
				</div>
				<div></div>
			</container>
		</section>
	)
}

export default ViewProfile;
